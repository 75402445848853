// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ***************************************************************************************

/**
 * Float Styles
 */

.left {
  float: left;
  clear: none;
}

.right {
  float: right;
  clear: none;
}

/**
 * Align Styles
 */

.align_l {
  text-align: left;
}

.align_r {
  text-align: right;
}

.align_c {
  text-align: center;
}

.align_j {
  text-align: justify;
}

/**
 * Display as Table Styles
 */

.display-table {
  display: table;
  width: 100%;
}

.display-row {
  display: table-row;
  height: 100%;
}

.display-cell {
  display: table-cell;
  height: 100%;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.vertical-align-top {
  vertical-align: top;
}
