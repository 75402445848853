// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  .square(14px);

  line-height: 14px;
  margin-top: 0;
  vertical-align: text-top;
  background-repeat: no-repeat;
  background-image: url("/img/glyphicons-halflings.png");
}

.icon-white {
  background-image: url("/img/glyphicons-halflings-white.png");
}

.icon-grey {
  opacity: 0.6;
}

#promote-dropdown-menu {
  a {
    .icon-white { display: none; }
    .icon-grey { display: inline-block; }

    &:hover {
      .icon-white { display: inline-block; }
      .icon-grey { display: none; }
    }

    .icon-white,
    .icon-grey {
      opacity: 1;
      margin-left: 2px;
      width: 16px;
    }

    .halflings,
    .glyphicons {
      opacity: 1;
      width: 18px;
      font-size: 12px;
      text-align: center;
    }
    .glyphicons:before { padding: 5px 8px; }
  }
}

a:not(#menu-team):not(.select2-choice) {
  .icon-white { display: none; }
  .icon-grey { display: inline-block; }

  &:hover {
    .icon-white { display: inline-block; }
    .icon-grey { display: none; }
  }
}

.issue-options {
  a:not(#menu-team):not(.select2-choice):hover {
    .icon-grey { display: inline-block; }
  }
}

.icon-team {
  background-position: 0px -168px;
  width: 16px;
  margin-top: 0;
}
.icon-activity { background-position: -24px -168px; }
.icon-embed { background-position: -48px -168px; }
.icon-share-prong { background-position: -72px -168px; }
.icon-link { background-position: -96px -168px; }
.icon-facebook { background-position: -120px -168px; }
.icon-twitter { background-position: -144px -168px; }
.icon-key { background-position: -168px -168px; }
.icon-scissors { background-position: -192px -168px; }
.icon-new-window { background-position: -216px -168px; }
.icon-tab { background-position: -240px -168px; }
.icon-pin { background-position: -264px -168px; }
.icon-unlock { background-position: -287px -167px; }

.icon-hdd { background-position: 0px -144px; }
.icon-bullhorn { background-position: -24px -144px; }
.icon-bell { background-position: -48px -144px; }
.icon-certificate { background-position: -72px -144px; }
.icon-thumbs-up { background-position: -96px -144px; }
.icon-thumbs-down { background-position: -120px -144px; }
.icon-hand-right { background-position: -144px -144px; }
.icon-hand-left { background-position: -168px -144px; }
.icon-hand-up { background-position: -192px -144px; }
.icon-hand-down { background-position: -216px -144px; }
.icon-circle-arrow-right { background-position: -240px -144px; }
.icon-circle-arrow-left { background-position: -264px -144px; }
.icon-circle-arrow-up { background-position: -288px -144px; }
.icon-circle-arrow-down { background-position: -312px -144px; }
.icon-globe { background-position: -336px -144px; }
.icon-wrench { background-position: -360px -144px; }
.icon-tasks { background-position: -384px -144px; }
.icon-filter { background-position: -408px -144px; }
.icon-briefcase { background-position: -432px -144px; }
.icon-fullscreen { background-position: -456px -144px; }

.icon-folder-open { width: 16px; }

// ****************************************************************************

i[class^="icon-sp-"] {
  display: inline-block;
  .square(14px);

  line-height: 14px;
  vertical-align: text-top;
  background-repeat: no-repeat;
  background-image: url("/img/hubs/backend/spritesheet.png");
}
.icon-sp-rss { background-position: -14px -573px; }
.icon-sp-alt { background-position: -93px -574px; }
.icon-sp-cta { background-position: -172px -573px; }

.icon-sp-align-justify {
  background-position: -251px -574px;
  width: 20px;
}
.icon-sp-ok-sign { background-position: -334px -573px; }

// ****************************************************************************
// Device Icons

.device_wrapper {
  margin-left: 8px;
  padding: 0;
  cursor: help;

  .device {
    margin-left: 2px;
    line-height: 16px;
    overflow: hidden;
    background: transparent url("/img/device_sprite.png") no-repeat top left;

    &.phone {
      padding: 0px 5px;
      background-position: 0px   0;
    }

    &.tablet {
      padding: 0px 7px;
      background-position: -20px 0;
    }

    &.desktop {
      padding: 0px 8px;
      background-position: -40px 0;
    }
  }
}

// ****************************************************************************
// preview option icons

#preview_options {
  float: left;
  clear: none;
  margin-left: 20px;

  div {
    float: left;
    clear: none;
    margin: 4px;
    background: transparent url("/img/black_20.png") repeat;
    background: rgba(0, 0, 0, 0.1);
    .border-radius(4px);

    div {
      &.preview_icon {
        margin: 0;
        width: 36px;
        height: 34px;
        float: none;
        opacity: 0.3;
        background: transparent url("/img/icon_sprites_banner.png") no-repeat;
        cursor: pointer;

        &.on {
          opacity: 1;
          background-color: rgba(0, 0, 0, 0.2);
        }

        &#preview_icon_crop { background-position: -70px -70px; }
        &#preview_icon_download { background-position: 0px -70px; }
        &#preview_icon_download_pages { background-position: -35px -70px; }
        &#preview_icon_print { background-position: -175px -70px; }
        &#preview_icon_links { background-position: -245px -70px; }
        &#preview_icon_email { background-position: -140px 0; }
        &#preview_icon_bookmark { background-position: -35px 0; }
        &#preview_icon_archives { background-position: 0px 0; }
        &#preview_icon_fullscreen { background-position: -210px -70px; }
        &#preview_icon_help { background-position: -313px 0; }
        &#preview_icon_text { background-position: -245px -35px; }
        &#preview_icon_toc { background-position: -105px 0; }
      }
    }
  }
}

a,
img {
  &.img {
    padding-left: 24px;
    padding-right: 0;
    border: 0;
    background: transparent no-repeat 4px 50%;
  }

  &.return {
    background-image: url("/img/return.png");
  }

  &.link {
    background-image: url("/img/link.png");
  }

  &.plus {
    background-image: url("/img/plus_16.png");
  }

  &.upload {
    background-image: url("/img/up_16.png");
  }

  &.manage {
    background-image: url("/img/manage.png");
  }

  &.delete {
    background-image: url("/img/delete.png");
  }

  &.edit {
    background-image: url("/img/edit.png");
  }

  &.configure {
    background-image: url("/img/widget.png");
  }

  &.list {
    background-image: url("/img/list.png");
  }

  &.alert {
    background-image: url("/img/deny.png");
  }

  &.globe {
    background-image: url("/img/globe.png");
  }

  &.read {
    background-image: url("/img/read.png");
  }

  &.embed {
    background-image: url("/img/code.png");
  }

  &.report {
    background-image: url("/img/page_text.png");
  }

  &.money {
    background-image: url("/img/money.png");
  }

  &.cancel {
    background-image: url("/img/cancel.png");
  }

  &.bulb {
    background-image: url("/img/light_bulb.png");
  }

  &.hourglass {
    background-image: url("/img/light_bulb.png");
  }

  &.promote {
    background-image: url("/img/ad_icon.png");
  }

  &.color {
    background-image: url("/img/color.png");
  }

  &.lock {
    background-image: url("/img/locked.png");
  }

  &.feature_lock {
    background-image: url("/img/feature_lock.png");
  }

  &.stats {
    background-image: url("/img/stats.png");
  }

  &.brush {
    background-image: url("/img/brush.png");
  }

  &.folder {
    background-image: url("/img/folder.png");
  }

  &.notes {
    background-image: url("/img/fb_overlays/note.png");
  }

  &.facebook {
    background-image: url("/img/social_bookmarks/facebook.png");
  }

  &.twitter {
    background-image: url("/img/social_bookmarks/twitter.png");
  }
}

img {
  &.img {
    width: 16px;
    height: 16px;
    padding: 0;
    margin-right: 8px;
    background-position: top left;
    vertical-align: middle;
  }
}

.dropdown {
  &.open {
    .dropdown-toggle {
      &.img {
        &.edit {
          background-image: url("/img/edit.png");
        }

        &.embed {
          background-image: url("/img/code.png");
        }

        &.promote {
          background-image: url("/img/ad_icon.png");
        }
      }
    }
  }
}

// ****************************************************************************
// Account Icon

.layout-bs3-uberflip {
  .myg_account,
  .uber_account {
    padding: 4px 4px 4px 24px;
    font-weight: bold;
    background: transparent url("/img/uberflip_icon_14.png") no-repeat 3px 50%;
  }
}

// ****************************************************************************
// PDF icon for fetch consumer list

.layout-bs3-uberflip {
  ul.nav-files {
    li.files-file {
      a {
        background-image: url("/img/icon_32/uberflip_icon_pdf_32.png") !important;
      }
    }
  }
}

// ****************************************************************************

.layout-bs3 {
  .halflings-lg {
    font-size: 24px;
    line-height: 40px;
  }
}

.icon-large {
  display: inline-block;
  .square(28px);

  line-height: 28px;
  vertical-align: text-bottom;
  background: transparent url("/img/glyphicons.png") no-repeat 24px 24px;
}

.icon-large.icon-glass { background-position: 0px 0px; }
.icon-large.icon-leaf { background-position: 0px -34px; }
.icon-large.icon-dog { background-position: 0px -69px; }
.icon-large.icon-user { background-position: 0px -104px; }
.icon-large.icon-girl { background-position: 0px -136px; }
.icon-large.icon-car { background-position: 0px -168px; }
.icon-large.icon-user-add { background-position: 0px -200px; }
.icon-large.icon-user-remove { background-position: 0px -232px; }
.icon-large.icon-film { background-position: 0px -264px; }
.icon-large.icon-magic { background-position: 0px -300px; }
.icon-large.icon-envelope { background-position: 0px -330px; }
.icon-large.icon-camera { background-position: 0px -360px; }
.icon-large.icon-heart { background-position: 0px -387px; }
.icon-large.icon-beach-umbrella { background-position: 0px -422px; }
.icon-large.icon-train { background-position: 0px -457px; }
.icon-large.icon-print { background-position: 0px -494px; }
.icon-large.icon-bin { background-position: 0px -528px; }
.icon-large.icon-trash { background-position: 0px -528px; }
.icon-large.icon-music { background-position: 0px -566px; }
.icon-large.icon-note { background-position: 0px -601px; }
.icon-large.icon-cogwheel { background-position: 0px -636px; }
.icon-large.icon-cog { background-position: 0px -636px; }
.icon-large.icon-home { background-position: 0px -670px; }
.icon-large.icon-snowflake { background-position: 0px -706px; }
.icon-large.icon-fire { background-position: 0px -744px; }
.icon-large.icon-cogwheels { background-position: 0px -780px; }
.icon-large.icon-parents { background-position: 0px -816px; }
.icon-large.icon-binoculars { background-position: 0px -848px; }
.icon-large.icon-road { background-position: 0px -882px; }
.icon-large.icon-search { background-position: 0px -916px; }
.icon-large.icon-cars { background-position: 0px -950px; }
.icon-large.icon-pencil { background-position: 0px -985px; }
.icon-large.icon-bus { background-position: 0px -1020px; }
.icon-large.icon-wifi-alt { background-position: 0px -1055px; }
.icon-large.icon-luggage { background-position: 0px -1091px; }
.icon-large.icon-old-man { background-position: 0px -1128px; }
.icon-large.icon-woman { background-position: 0px -1162px; }
.icon-large.icon-file { background-position: 0px -1194px; }
.icon-large.icon-credit { background-position: 0px -1228px; }

.icon-large.icon-airplane,
.icon-large.icon-plane { background-position: 0px -1262px; }
.icon-large.icon-notes { background-position: 0px -1297px; }
.icon-large.icon-stats { background-position: 0px -1332px; }
.icon-large.icon-charts { background-position: 0px -1367px; }
.icon-large.icon-pie-chart { background-position: 0px -1401px; }
.icon-large.icon-group { background-position: 0px -1436px; }
.icon-large.icon-keys { background-position: 0px -1468px; }
.icon-large.icon-calendar { background-position: 0px -1504px; }
.icon-large.icon-router { background-position: 0px -1539px; }
.icon-large.icon-camera-small { background-position: 0px -1575px; }
.icon-large.icon-dislikes { background-position: 0px -1609px; }
.icon-large.icon-star-empty { background-position: 0px -1609px; }
.icon-large.icon-star { background-position: 0px -1643px; }
.icon-large.icon-link { background-position: 0px -1677px; }
.icon-large.icon-eye-open { background-position: -1px -1704px; }
.icon-large.icon-eye-close { background-position: -1px -1737px; }
.icon-large.icon-alarm { background-position: 0px -1771px; }
.icon-large.icon-clock { background-position: 0px -1807px; }
.icon-large.icon-time { background-position: 0px -1807px; }
.icon-large.icon-stopwatch { background-position: 0px -1841px; }
.icon-large.icon-projector { background-position: 0px -1878px; }
.icon-large.icon-history { background-position: 0px -1913px; }
.icon-large.icon-truck { background-position: 0px -1949px; }
.icon-large.icon-cargo { background-position: 0px -1986px; }
.icon-large.icon-compass { background-position: -46px 0px; }
.icon-large.icon-keynote { background-position: -46px -34px; }
.icon-large.icon-attach { background-position: -46px -74px; }
.icon-large.icon-power { background-position: -46px -108px; }
.icon-large.icon-off { background-position: -46px -108px; }
.icon-large.icon-lightbulb { background-position: -46px -142px; }
.icon-large.icon-tag { background-position: -46px -178px; }
.icon-large.icon-tags { background-position: -46px -212px; }
.icon-large.icon-cleaning { background-position: -46px -246px; }
.icon-large.icon-ruller { background-position: -46px -281px; }
.icon-large.icon-gift { background-position: -46px -305px; }
.icon-large.icon-umbrella { background-position: -46px -340px; }
.icon-large.icon-book { background-position: -46px -378px; }
.icon-large.icon-bookmark { background-position: -44px -412px; }
.icon-large.icon-signal { background-position: -46px -446px; }
.icon-large.icon-cup { background-position: -46px -479px; }
.icon-large.icon-stroller { background-position: -46px -513px; }
.icon-large.icon-headphones { background-position: -46px -549px; }
.icon-large.icon-headset { background-position: -46px -583px; }
.icon-large.icon-warning-sign { background-position: -46px -621px; }
.icon-large.icon-signal { background-position: -46px -655px; }
.icon-large.icon-retweet { background-position: -47px -680px; }
.icon-large.icon-refresh { background-position: -46px -714px; }
.icon-large.icon-roundabout { background-position: -46px -750px; }
.icon-large.icon-random { background-position: -46px -787px; }
.icon-large.icon-heat { background-position: -46px -817px; }
.icon-large.icon-repeat { background-position: -46px -852px; }
.icon-large.icon-display { background-position: -46px -888px; }
.icon-large.icon-log-book { background-position: -46px -922px; }
.icon-large.icon-adress-book { background-position: -46px -956px; }
.icon-large.icon-magnet { background-position: -46px -990px; }
.icon-large.icon-table { background-position: -46px -1023px; }
.icon-large.icon-adjust { background-position: -46px -1057px; }
.icon-large.icon-tint { background-position: -46px -1093px; }
.icon-large.icon-crop { background-position: -46px -1129px; }
.icon-large.icon-vector-path-square { background-position: -46px -1165px; }
.icon-large.icon-vector-path-circle { background-position: -46px -1199px; }
.icon-large.icon-vector-path-polygon { background-position: -46px -1233px; }
.icon-large.icon-vector-path-line { background-position: -46px -1268px; }
.icon-large.icon-vector-path-curve { background-position: -46px -1302px; }
.icon-large.icon-vector-path-all { background-position: -46px -1336px; }
.icon-large.icon-font { background-position: -46px -1370px; }
.icon-large.icon-italic { background-position: -46px -1403px; }
.icon-large.icon-bold { background-position: -46px -1437px; }
.icon-large.icon-text-underline { background-position: -46px -1471px; }
.icon-large.icon-text-strike { background-position: -46px -1505px; }
.icon-large.icon-text-height { background-position: -46px -1537px; }
.icon-large.icon-text-width { background-position: -46px -1571px; }
.icon-large.icon-text-resize { background-position: -46px -1605px; }

.icon-large.icon-left-indent,
.icon-large.icon-indent-left { background-position: -46px -1641px; }

.icon-large.icon-right-indent,
.icon-large.icon-indent-right { background-position: -46px -1673px; }
.icon-large.icon-align-left { background-position: -46px -1705px; }
.icon-large.icon-align-center { background-position: -46px -1736px; }
.icon-large.icon-align-right { background-position: -46px -1767px; }
.icon-large.icon-justify { background-position: -46px -1798px; }
.icon-large.icon-align-justify { background-position: -46px -1798px; }
.icon-large.icon-list { background-position: -45px -1826px; }
.icon-large.icon-text-smaller { background-position: -46px -1860px; }
.icon-large.icon-text-bigger { background-position: -46px -1886px; }
.icon-large.icon-embed { background-position: -47px -1910px; }
.icon-large.icon-embed-close { background-position: -47px -1940px; }
.icon-large.icon-adjust { background-position: -46px -1976px; }
.icon-large.icon-message-full { background-position: -92px 0px; }
.icon-large.icon-message-empty { background-position: -92px -38px; }
.icon-large.icon-message-in { background-position: -92px -76px; }
.icon-large.icon-message-out { background-position: -92px -114px; }
.icon-large.icon-message-plus { background-position: -92px -152px; }
.icon-large.icon-message-minus { background-position: -92px -185px; }
.icon-large.icon-message-ban { background-position: -92px -218px; }
.icon-large.icon-message-flag { background-position: -92px -251px; }
.icon-large.icon-message-lock { background-position: -92px -284px; }
.icon-large.icon-message-new { background-position: -92px -318px; }
.icon-large.icon-inbox { background-position: -92px -350px; }
.icon-large.icon-inbox-plus { background-position: -92px -383px; }
.icon-large.icon-inbox-minus { background-position: -92px -420px; }
.icon-large.icon-inbox-lock { background-position: -92px -457px; }
.icon-large.icon-inbox-in { background-position: -92px -495px; }
.icon-large.icon-inbox-out { background-position: -92px -531px; }
.icon-large.icon-computer-locked { background-position: -92px -567px; }
.icon-large.icon-computer-service { background-position: -92px -601px; }
.icon-large.icon-computer-proces { background-position: -92px -635px; }
.icon-large.icon-phone { background-position: -92px -669px; }
.icon-large.icon-database-lock { background-position: -92px -704px; }
.icon-large.icon-database-plus { background-position: -92px -742px; }
.icon-large.icon-database-minus { background-position: -92px -779px; }
.icon-large.icon-database-ban { background-position: -92px -816px; }
.icon-large.icon-folder-open { background-position: -92px -853px; }
.icon-large.icon-folder-plus { background-position: -92px -885px; }
.icon-large.icon-folder-minus { background-position: -92px -920px; }
.icon-large.icon-folder-lock { background-position: -92px -955px; }
.icon-large.icon-folder-flag { background-position: -92px -991px; }
.icon-large.icon-folder-new { background-position: -92px -1026px; }
.icon-large.icon-check { background-position: -92px -1060px; }
.icon-large.icon-edit { background-position: -92px -1088px; }
.icon-large.icon-new-window { background-position: -92px -1119px; }
.icon-large.icon-more-windows { background-position: -92px -1151px; }
.icon-large.icon-show-big-thumbnails { background-position: -92px -1184px; }
.icon-large.icon-th-large { background-position: -92px -1184px; }
.icon-large.icon-show-thumbnails { background-position: -92px -1216px; }
.icon-large.icon-th { background-position: -92px -1216px; }
.icon-large.icon-show-thumbnails-with-lines { background-position: -92px -1248px; }
.icon-large.icon-th-list { background-position: -92px -1248px; }
.icon-large.icon-show-lines { background-position: -92px -1273px; }
.icon-large.icon-playlist { background-position: -92px -1298px; }
.icon-large.icon-picture { background-position: -92px -1332px; }
.icon-large.icon-imac { background-position: -92px -1362px; }
.icon-large.icon-macbook { background-position: -92px -1394px; }
.icon-large.icon-ipad { background-position: -92px -1419px; }
.icon-large.icon-iphone { background-position: -92px -1456px; }
.icon-large.icon-iphone-transfer { background-position: -92px -1490px; }
.icon-large.icon-iphone-exchange { background-position: -92px -1524px; }
.icon-large.icon-ipod { background-position: -92px -1558px; }
.icon-large.icon-ipod-shuffle { background-position: -92px -1590px; }
.icon-large.icon-ear-plugs { background-position: -92px -1613px; }
.icon-large.icon-albums { background-position: -92px -1647px; }
.icon-large.icon-step-backward { background-position: -92px -1675px; }
.icon-large.icon-fast-backward { background-position: -92px -1703px; }

.icon-large.icon-rewind,
.icon-large.icon-backwards { background-position: -92px -1731px; }
.icon-large.icon-play { background-position: -92px -1759px; }
.icon-large.icon-pause { background-position: -92px -1787px; }
.icon-large.icon-stop { background-position: -92px -1813px; }
.icon-large.icon-forward { background-position: -92px -1837px; }
.icon-large.icon-fast-forward { background-position: -92px -1865px; }
.icon-large.icon-step-forward { background-position: -92px -1893px; }
.icon-large.icon-eject { background-position: -92px -1921px; }
.icon-large.icon-facetime-video { background-position: -92px -1948px; }
.icon-large.icon-download-alt { background-position: -92px -1974px; }

.icon-large.icon-mute,
.icon-large.icon-volume-off { background-position: -138px 4px; }
.icon-large.icon-volume-down { background-position: -134px -22px; }
.icon-large.icon-volume-up { background-position: -138px -52px; }
.icon-large.icon-screenshot { background-position: -138px -88px; }
.icon-large.icon-move { background-position: -138px -125px; }
.icon-large.icon-more { background-position: -138px -159px; }
.icon-large.icon-brightness-reduce { background-position: -138px -176px; }
.icon-large.icon-brightness-increase { background-position: -138px -206px; }

.icon-large.icon-circle-plus,
.icon-large.icon-plus-sign { background-position: -138px -240px; }

.icon-large.icon-circle-minus,
.icon-large.icon-minus-sign { background-position: -138px -276px; }

.icon-large.icon-circle-remove,
.icon-large.icon-remove-sign { background-position: -138px -312px; }

.icon-large.icon-circle-ok,
.icon-large.icon-ok-sign { background-position: -138px -348px; }

.icon-large.icon-circle-question-mark,
.icon-large.icon-question-sign { background-position: -138px -384px; }

.icon-large.icon-circle-info,
.icon-large.icon-info-sign { background-position: -138px -420px; }

.icon-large.icon-circle-exclamation-mark,
.icon-large.icon-exclamation-sign { background-position: -138px -456px; }
.icon-large.icon-remove { background-position: -138px -492px; }
.icon-large.icon-ok { background-position: -138px -528px; }
.icon-large.icon-ban { background-position: -138px -564px; }
.icon-large.icon-download { background-position: -138px -600px; }
.icon-large.icon-upload { background-position: -138px -636px; }
.icon-large.icon-shopping-cart { background-position: -138px -672px; }
.icon-large.icon-lock { background-position: -138px -705px; }
.icon-large.icon-unlock { background-position: -138px -741px; }
.icon-large.icon-electricity { background-position: -138px -777px; }
.icon-large.icon-cart-out { background-position: -138px -811px; }
.icon-large.icon-cart-in { background-position: -138px -846px; }
.icon-large.icon-left-arrow { background-position: -138px -880px; }
.icon-large.icon-right-arrow { background-position: -138px -908px; }
.icon-large.icon-down-arrow { background-position: -138px -936px; }
.icon-large.icon-up-arrow { background-position: -138px -966px; }
.icon-large.icon-resize-small { background-position: -138px -996px; }
.icon-large.icon-resize-full { background-position: -138px -1030px; }
.icon-large.icon-circle-arrow-left { background-position: -138px -1064px; }
.icon-large.icon-circle-arrow-right { background-position: -138px -1100px; }

.icon-large.icon-circle-arrow-top,
.icon-large.icon-circle-arrow-up { background-position: -138px -1136px; }
.icon-large.icon-circle-arrow-down { background-position: -138px -1172px; }
.icon-large.icon-play-button { background-position: -138px -1208px; }
.icon-large.icon-play-circle { background-position: -138px -1208px; }
.icon-large.icon-unshare { background-position: -138px -1244px; }
.icon-large.icon-share { background-position: -138px -1272px; }

.icon-large.icon-thin-right-arrow,
.icon-large.icon-chevron-right { background-position: -138px -1300px; }

.icon-large.icon-thin-arrow-left,
.icon-large.icon-chevron-left { background-position: -138px -1332px; }
.icon-large.icon-bluetooth { background-position: -138px -1364px; }
.icon-large.icon-euro { background-position: -138px -1398px; }
.icon-large.icon-usd { background-position: -138px -1431px; }
.icon-large.icon-bp { background-position: -138px -1467px; }
.icon-large.icon-moon { background-position: -138px -1501px; }
.icon-large.icon-sun { background-position: -138px -1536px; }
.icon-large.icon-cloud { background-position: -138px -1570px; }
.icon-large.icon-direction { background-position: -138px -1597px; }
.icon-large.icon-brush { background-position: -138px -1633px; }
.icon-large.icon-pen { background-position: -138px -1666px; }
.icon-large.icon-zoom-in { background-position: -138px -1700px; }
.icon-large.icon-zoom-out { background-position: -138px -1735px; }
.icon-large.icon-pin { background-position: -138px -1770px; }
.icon-large.icon-riflescope { background-position: -138px -1805px; }
.icon-large.icon-rotation-lock { background-position: -138px -1840px; }
.icon-large.icon-flash { background-position: -138px -1874px; }

.icon-large.icon-google-maps,
.icon-large.icon-map-marker { background-position: -138px -1909px; }
.icon-large.icon-anchor { background-position: -138px -1943px; }
.icon-large.icon-conversation { background-position: -138px -1978px; }
.icon-large.icon-chat { background-position: -184px 0px; }
.icon-large.icon-male { background-position: -184px -29px; }
.icon-large.icon-female { background-position: -184px -61px; }
.icon-large.icon-asterisk { background-position: -184px -98px; }
.icon-large.icon-divide { background-position: -184px -128px; }
.icon-large.icon-snorkel-diving { background-position: -184px -154px; }
.icon-large.icon-scuba-diving { background-position: -184px -189px; }
.icon-large.icon-oxygen-bottle { background-position: -184px -223px; }
.icon-large.icon-fins { background-position: -184px -260px; }
.icon-large.icon-fishes { background-position: -184px -297px; }
.icon-large.icon-boat { background-position: -184px -337px; }
.icon-large.icon-delete-point { background-position: -184px -371px; }
.icon-large.icon-qrcode { background-position: -184px -398px; }
.icon-large.icon-barcode { background-position: -184px -432px; }
.icon-large.icon-pool { background-position: -184px -466px; }
.icon-large.icon-buoy { background-position: -184px -500px; }
.icon-large.icon-spade { background-position: -184px -534px; }
.icon-large.icon-bank { background-position: -184px -568px; }
.icon-large.icon-vcard { background-position: -184px -602px; }
.icon-large.icon-electrical-plug { background-position: -184px -636px; }
.icon-large.icon-flag { background-position: -184px -671px; }
.icon-large.icon-credit-card { background-position: -184px -707px; }
.icon-large.icon-keyboard-wireless { background-position: -184px -736px; }
.icon-large.icon-keyboard-wired { background-position: -184px -765px; }
.icon-large.icon-shield { background-position: -184px -800px; }
.icon-large.icon-ring { background-position: -184px -834px; }
.icon-large.icon-cake { background-position: -184px -868px; }
.icon-large.icon-drink { background-position: -184px -902px; }
.icon-large.icon-beer { background-position: -184px -936px; }
.icon-large.icon-fast-food { background-position: -184px -970px; }
.icon-large.icon-cutlery { background-position: -184px -1004px; }
.icon-large.icon-pizza { background-position: -184px -1038px; }
.icon-large.icon-birthday-cake { background-position: -184px -1077px; }
.icon-large.icon-tablet { background-position: -184px -1116px; }
.icon-large.icon-settings { background-position: -184px -1151px; }
.icon-large.icon-bullets { background-position: -184px -1185px; }
.icon-large.icon-cardio { background-position: -184px -1218px; }
.icon-large.icon-pants { background-position: -184px -1254px; }
.icon-large.icon-sweater { background-position: -184px -1288px; }
.icon-large.icon-fabric { background-position: -184px -1322px; }
.icon-large.icon-leather { background-position: -184px -1354px; }
.icon-large.icon-scissors { background-position: -184px -1388px; }
.icon-large.icon-podium { background-position: -184px -1425px; }
.icon-large.icon-skull { background-position: -184px -1456px; }
.icon-large.icon-celebration { background-position: -184px -1490px; }
.icon-large.icon-tea-kettle { background-position: -184px -1525px; }
.icon-large.icon-french-press { background-position: -184px -1558px; }
.icon-large.icon-coffe-cup { background-position: -184px -1593px; }
.icon-large.icon-pot { background-position: -184px -1622px; }
.icon-large.icon-grater { background-position: -184px -1654px; }
.icon-large.icon-kettle { background-position: -184px -1688px; }
.icon-large.icon-hospital { background-position: -184px -1722px; }
.icon-large.icon-hospital-h { background-position: -184px -1756px; }
.icon-large.icon-microphone { background-position: -184px -1790px; }
.icon-large.icon-webcam { background-position: -184px -1824px; }
.icon-large.icon-temple-christianity-church { background-position: -184px -1858px; }
.icon-large.icon-temple-islam { background-position: -184px -1893px; }
.icon-large.icon-temple-hindu { background-position: -184px -1927px; }
.icon-large.icon-temple-buddhist { background-position: -184px -1961px; }
.icon-large.icon-electrical-socket-eu { background-position: -230px 0px; }
.icon-large.icon-electrical-socket-us { background-position: -230px -33px; }
.icon-large.icon-bomb { background-position: -230px -66px; }

.icon-large.icon-comments,
.icon-large.icon-comment { background-position: -230px -102px; }
.icon-large.icon-flower { background-position: -230px -135px; }
.icon-large.icon-baseball { background-position: -230px -170px; }
.icon-large.icon-rugby { background-position: -230px -206px; }
.icon-large.icon-ax { background-position: -230px -240px; }
.icon-large.icon-table-tennis { background-position: -230px -275px; }
.icon-large.icon-bowling { background-position: -230px -309px; }
.icon-large.icon-tree-conifer { background-position: -230px -343px; }
.icon-large.icon-tree-deciduous { background-position: -230px -377px; }
.icon-large.icon-sort { background-position: -230px -412px; }
.icon-large.icon-filter { background-position: -230px -447px; }
.icon-large.icon-gamepad { background-position: -230px -481px; }
.icon-large.icon-playing-dices { background-position: -230px -510px; }
.icon-large.icon-calculator { background-position: -230px -543px; }
.icon-large.icon-tie { background-position: -230px -577px; }
.icon-large.icon-wallet { background-position: -230px -613px; }
.icon-large.icon-share { background-position: -230px -643px; }
.icon-large.icon-sampler { background-position: -230px -675px; }
.icon-large.icon-piano { background-position: -230px -707px; }
.icon-large.icon-web-browser { background-position: -230px -741px; }
.icon-large.icon-blog { background-position: -230px -773px; }
.icon-large.icon-dashboard { background-position: -230px -806px; }
.icon-large.icon-certificate { background-position: -230px -840px; }
.icon-large.icon-bell { background-position: -230px -875px; }
.icon-large.icon-candle { background-position: -230px -909px; }
.icon-large.icon-pin-classic { background-position: -230px -944px; }
.icon-large.icon-iphone-shake { background-position: -230px -978px; }
.icon-large.icon-pin-flag { background-position: -230px -1012px; }
.icon-large.icon-turtle { background-position: -230px -1044px; }
.icon-large.icon-rabbit { background-position: -230px -1070px; }
.icon-large.icon-globe { background-position: -230px -1102px; }
.icon-large.icon-briefcase { background-position: -230px -1136px; }
.icon-large.icon-hdd { background-position: -230px -1167px; }
.icon-large.icon-thumbs-up { background-position: -230px -1198px; }
.icon-large.icon-thumbs-down { background-position: -230px -1229px; }
.icon-large.icon-hand-right { background-position: -230px -1260px; }
.icon-large.icon-hand-left { background-position: -230px -1289px; }
.icon-large.icon-hand-up { background-position: -230px -1318px; }
.icon-large.icon-hand-down { background-position: -230px -1354px; }
.icon-large.icon-fullscreen { background-position: -230px -1391px; }
.icon-large.icon-shopping-bag { background-position: -230px -1425px; }
.icon-large.icon-book-open { background-position: -230px -1461px; }
.icon-large.icon-nameplate { background-position: -230px -1494px; }
.icon-large.icon-nameplate-alt { background-position: -230px -1525px; }
.icon-large.icon-vases { background-position: -230px -1557px; }

.icon-large.icon-announcement,
.icon-large.icon-bullhorn { background-position: -230px -1591px; }
.icon-large.icon-dumbbell { background-position: -230px -1621px; }
.icon-large.icon-suitcase { background-position: -230px -1647px; }
.icon-large.icon-file-import { background-position: -230px -1679px; }
.icon-large.icon-file-export { background-position: -230px -1713px; }
.icon-large.icon-pinterest { background-position: -230px -1747px; }
.icon-large.icon-dropbox { background-position: -230px -1781px; }
.icon-large.icon-jolicloud { background-position: -230px -1815px; }
.icon-large.icon-yahoo { background-position: -230px -1849px; }
.icon-large.icon-blogger { background-position: -230px -1883px; }
.icon-large.icon-picasa { background-position: -230px -1917px; }
.icon-large.icon-amazon { background-position: -230px -1951px; }
.icon-large.icon-tumblr { background-position: -230px -1985px; }
.icon-large.icon-wordpress { background-position: -276px 0px; }
.icon-large.icon-instapaper { background-position: -276px -34px; }
.icon-large.icon-evernote { background-position: -276px -68px; }
.icon-large.icon-xing { background-position: -276px -102px; }
.icon-large.icon-zootool { background-position: -276px -136px; }
.icon-large.icon-dribbble { background-position: -276px -170px; }
.icon-large.icon-deviantart { background-position: -276px -204px; }
.icon-large.icon-read-it-later { background-position: -276px -238px; }
.icon-large.icon-linked-in { background-position: -276px -272px; }
.icon-large.icon-forrst { background-position: -276px -306px; }
.icon-large.icon-pinboard { background-position: -276px -340px; }
.icon-large.icon-behance { background-position: -276px -374px; }
.icon-large.icon-github { background-position: -276px -408px; }
.icon-large.icon-youtube { background-position: -276px -442px; }
.icon-large.icon-skitch { background-position: -276px -476px; }
.icon-large.icon-quora { background-position: -276px -510px; }
.icon-large.icon-google-plus { background-position: -276px -544px; }
.icon-large.icon-spootify { background-position: -276px -578px; }
.icon-large.icon-stumbleupon { background-position: -276px -612px; }
.icon-large.icon-readability { background-position: -276px -646px; }
.icon-large.icon-facebook { background-position: -276px -680px; }
.icon-large.icon-twitter-t { background-position: -276px -714px; }

.icon-large.icon-twitter {
  background-position: center;
  background-image: url("/img/integrations/twitter_x_logo.svg");
}
.icon-large.icon-buzz { background-position: -276px -782px; }
.icon-large.icon-vimeo { background-position: -276px -816px; }
.icon-large.icon-flickr { background-position: -276px -850px; }
.icon-large.icon-last-fm { background-position: -276px -884px; }
.icon-large.icon-rss { background-position: -276px -918px; }
.icon-large.icon-skype { background-position: -276px -952px; }

/* custom */
.icon-large.icon-box { background-position: -276px -986px; }
.icon-large.icon-google-drive { background-position: -276px -1018px; }
.icon-large.icon-instagram { background-position: -276px -1052px; }
.icon-large.icon-mailchimp { background-position: -276px -1084px; }
.icon-large.icon-hubspot { background-position: -276px -1152px; }
.icon-large.icon-blogpost { background-position: -276px -918px; } /*copied .icon-rss*/
.icon-large.icon-uberflip { background-position: -46px -378px; } /*copied .icon-book*/
.icon-large.icon-custom { background-position: -276px -1188px; }
.icon-large.icon-targeted { background-position: -276px -1781px; }
.icon-large.icon-bombora { background-position: -275px -1812px; }
.icon-large.icon-google-analytics { background-position: -276px -1217px; }
.icon-large.icon-google-tag-manager { background-position: -276px -1714px; }
.icon-large.icon-slideshare { background-position: -276px -1252px; }
.icon-large.icon-eloqua { background-position: -276px -1280px; }
.icon-large.icon-marketo { background-position: -276px -1313px; }
.icon-large.icon-pardot { background-position: -276px -1522px; }

.icon-large.icon-salesforce { background-position: -274px -1742px; }
.icon-large.icon-wistiar { background-position: -276px -1340px; }
.icon-large.icon-disqus { background-position: -276px -1413px; }
.icon-large.icon-bizo { background-position: -276px -1448px; }
.icon-large.icon-google-wmt { background-position: -276px -1118px; }
.icon-large.icon-vidyard { background-position: -276px -1482px; }
.icon-large.icon-brightcove { background-position: -276px -1588px; }
.icon-large.icon-brightinfo { background-position: -276px -1618px; }
.icon-large.icon-optimizely { background-position: -274px -1649px; }
.icon-large.icon-form_cta_type { background-position: -273px -1683px; }

.sprite {
  background-image: url(/img/hubs/add-collection_sprite.png);
  margin: 0 auto;
  width: 64px;
  height: 64px;
}

.sprite.flipbooks { background-position: -546px 0; }
.sprite.flipbooks-grey { background-position: -546px -74px; }
.sprite.instagram { background-position: -472px 0; }
.sprite.instagram-grey { background-position: -472px -74px; }
.sprite.pinterest { background-position: -398px 0; }
.sprite.pinterest-grey { background-position: -398px -74px; }
.sprite.twitter { background-position: -620px 0; }
.sprite.twitter-grey { background-position: -620px -74px; }
.sprite.blog { background-position: -694px 0; }
.sprite.blog-grey { background-position: -694px -74px; }
.sprite.vimeo { background-position: -768px 0; }
.sprite.vimeo-grey { background-position: -768px -74px; }
.sprite.youtube { background-position: -842px 0; }
.sprite.youtube-grey { background-position: -842px -74px; }
.sprite.marketing { background-position: -915px 0; }
.sprite.marketing-grey { background-position: -915px -74px; }
.sprite.sales { background-position: -1493px 0; }
.sprite.sales-grey { background-position: -1493px -73px; }
.sprite.slideshare { background-position: -990px 0; }
.sprite.slideshare-grey { background-position: -990px -74px; }
.sprite.facebook { background-position: -1064px 0; }
.sprite.facebook-grey { background-position: -1064px -74px; }
.sprite.wistiar { background-position: -1137px 0; }
.sprite.wistiar-grey { background-position: -1137px -74px; }
.sprite.wistia { background-position: -1137px 0; }
.sprite.wistia-grey { background-position: -1137px -74px; }
.sprite.vidyard { background-position: -1207px 0; }
.sprite.vidyard-grey { background-position: -1207px -74px; }
.sprite.brightcove { background-position: -1348px 0; }
.sprite.brightcove-grey { background-position: -1347px -74px; }
.sprite.salesforce { background-position: -1564px 0; }
.sprite.salesforce-grey { background-position: -1564px -74px; }

// *** New Logos for Streams 1.5 ***
.stream-logo {
  width: 45px;
  height: 45px;
  object-fit: contain;
  display: inline-block;

  &.blog { background-image: url(/img/hubs/logos/blog.svg); }
  &.brightcove { background-image: url(/img/hubs/logos/brightcove.svg); }
  &.flipbooks { background-image: url(/img/hubs/logos/flipbooks.svg); }
  &.marketing { background-image: url(/img/hubs/logos/marketing.svg); }
  &.sales { background-image: url(/img/hubs/logos/sales.svg); }
  &.slideshare { background-image: url(/img/hubs/logos/slideshare.svg); }
  &.twitter { background-image: url(/img/hubs/logos/twitter.svg); }
  &.vidyard { background-image: url(/img/hubs/logos/vidyard.svg); }
  &.vimeo { background-image: url(/img/hubs/logos/vimeo.svg); }
  &.wistia { background-image: url(/img/hubs/logos/wistia.svg); }
  &.wistiar { background-image: url(/img/hubs/logos/wistia.svg); }
  &.youtube { background-image: url(/img/hubs/logos/youtube.svg); }
}
