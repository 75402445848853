// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

// --------------------------------------------------------------------
// Primary Top Navbar - User Menu

.layout-bs3 {
  .navbar-user-menu {
    .navbar-header {
      width: 235px;
    }

    @media (max-width: 1310px) {
      .navbar-header {
        width: 45px;
      }

      .uberflip-small-logo {
        .navbar-brand:before {
          content: "";
          display: block;
          position: relative;
          top: 8px;
          width: 27px;
          height: 25px;
          background-image: url("/img/logo-without-words.png");
          background-size: contain;
        }
      }

      .navbar-brand img {
        display: none;
      }
    }
  }

  &.is-managing .navbar-user-menu .navbar-header {
    visibility: hidden !important;
  }
}

.layout-bs3 {

  // **************************************************************
  // Default Style Hover State for Navbars and Dropdown Menus

  .default-hover-mixin {
    color: @gray-lighter;
    background-color: @gray;
    background-repeat: no-repeat;
    background-image: none;
  }

  // **************************************************************
  // Uberflip Style Hover State for Navbars and Dropdown Menus

  .uber-hover-mixin {
    color: @white;
    background-color: @uberPinkDark;
    background-image: none;
    filter: none;
    .box-shadow(none);
  }

  // **************************************************************
  // Standard Hover/Active Sets for Navbar Links

  .navbar-hover-mixin {
    color: darken(@gray-lighter, 6.7%);    // #ddd
    background-color: darken(@gray, 7%);    // #444
  }

  .navbar-active-mixin {
    .navbar-hover-mixin;
    .box-shadow(inset 0 5px 10px rgba(0, 0, 0, 0.5));
  }

  // **************************************************************
  // Uberflip Style Hover/Active Sets for Navbar Links

  .uber-navbar-hover-mixin {
    background-color: @uberPinkDark;
    filter: none;
  }

  .uber-navbar-active-mixin {
    .uber-navbar-hover-mixin;
    .box-shadow(inset 0 0 6px rgba(0, 0, 0, 0.2));
  }

  // **************************************************************
  // Uberflip Style Hover/Active Sets for Sub-Navbar Links

  .uber-subbar-hover-mixin {
    background-color: darken(@gray, 7%);  // #434343
  }

  .uber-subbar-active-mixin {
    .uber-subbar-hover-mixin;

    background-color: darken(@gray, 6.5%);  // #444
    background-image: none;
    filter: none;
  }

  // **************************************************************
  // Standard Hover/Active Sets for Navbar Dropdown Menu Links

  .menu-hover-mixin {
    color: @white;
    background-color: @uberBlueSharp;
    background-image: none;

    .metadata { color: #e5e5e5; }
    .glyphicons { opacity: 1; }
  }

  .menu-active-mixin {
    color: @gray-dark;
    background-color: darken(@gray-lighter, 6.7%);   // #ddd
    background-image: none;
    .glyphicons { opacity: 1; }
  }

  // **************************************************************
  // Default Styles for Carets

  .caret-mixin {
    .caret {
      border-top-color: @gray;
      border-bottom-color: @gray;
      opacity: 0.6;
    }

    &.open,
    &.active,
    &:hover {
      .caret {
        border-top-color: @white;
        border-bottom-color: @white;
        opacity: 0.9;
      }
    }
  }

  .uber-caret-mixin {
    .caret {
      border-top-color: @white;
      border-bottom-color: @white;
      opacity: 0.6;
    }

    &.open,
    &.active,
    &:hover {
      .caret {
        opacity: 0.9;
      }
    }
  }

  // **************************************************************
  // Icons Sets for Profile & Warning Menus

  .default-icon-mixin {
    float: left;
    display: block;
    .size(60px, 40px);

    padding: 0;

    &:hover,
    &:focus { .default-hover-mixin; }
  }

  .profile-icon-mixin {
    .default-icon-mixin;

    width: 130px;
    background: transparent url("/img/profile_icon.png") no-repeat 83px center;

    &:hover,
    &:focus { background-position: -40px center; }
  }

  .warning-icon-mixin {
    .default-icon-mixin;

    background: transparent url("/img/warning_icon.png") no-repeat 83px center;

    &:hover,
    &:focus { background-position: -67px center; }
  }

  // ************************************************************************
  // Drop-Down Menus

  .dropdown-menu {
    max-width: 300px;
    margin-top: 1px;
    padding: 4px 0;

    li {
      a {
        img {
          margin-right: 4px;
          vertical-align: middle;
        }

        small {
          text-transform: lowercase;
        }

        .fullname {
          display: block;
          color: darken(@gray, 6.5%);   // #444
        }

        .metadata {
          font-size: (@font-size-base - 1);  // ~11px
          color: lighten(@gray-light, 13.2%);
        }
      }

      &.dropdown-header {
        padding: 7px 9px;
        white-space: normal;
        font-size: @font-size-base;

        .label {
          line-height: @line-height-computed - 6;   // ~12px
        }
      }

      .glyphicons {
        opacity: 0.7;
        line-height: 0.5;
        font-size: 13px;
        width: 20px;
        left: -5px;
        position: relative;
      }
    }

    p {
      padding: 4px 8px;
      margin: 0;
      color: lighten(@gray, 6.5%);   // #666
      font-weight: normal;
      font-size: @font-size-base;               // ~12px
      font-family: inherit;
      .clearfix;

      .label {
        color: @white;
        font-size: (@font-size-base - 1);     // ~11px
      }
    }

    label.std-label {
      display: block;
      .size(100%, auto);

      padding: 2px 2.5%;

      &:hover {
        background-color: @gray-lighter;
      }

      input[type="checkbox"],
      input[type="radio"] {
        margin: 0 5px 0 0;
      }
    }
  }

  .dropdown {
    &.open {
      .dropdown-toggle {
        display: inline-block;

        &.img {
          background-color: darken(@gray-lighter, 13.5%);    // #ccc
          background-repeat: no-repeat;
          background-position: 4px 50%;
          border: 0 none;
        }
      }
    }
  }

  // ************************************************************************
  // Top Navbars

  .navbar.navbar-fixed-top {
    margin-bottom: 0;

    .container-fluid {
      width: 100%;
    }

    .navbar-brand {
      line-height: @navbar-height;
    }

    .navbar-header {
      .navbar-brand {
        float: left;
        padding: 0px 15px;
        min-width: 125px;
        max-width: 220px;
        font-size: @font-size-base;

        a {
          display: block;
        }

        img {
          height: 25px;
          margin: 8px 20px 0 0;
        }
      }
    }

    .navbar-nav {
      a {
        &:hover,
        &:focus { .navbar-hover-mixin; }
      }

      .header-text {
        display: inline-block;
      }

      @media only screen and (max-width: 627px) {
        .header-text {
          display: none;
        }
      }

      > li {
        margin: 0;

        > a {
          padding: 10px 10px 11px;
          line-height: (@line-height-computed + 1);     // ~19px

          &:hover,
          &:focus { .navbar-hover-mixin; }

          &.profile {
            span {
              padding-left: 10px;
              line-height: 40px;
            }
          }

          &.warning {
            span {
              display: block;
              float: left;
              .size(50px, 40px);

              line-height: 44px;
              margin: 0;
              color: @uberPink;
              font-size: (@font-size-base - 1.5);  // ~10.5px
              font-weight: bold;
              text-align: center;
              vertical-align: middle;
              text-shadow: none;
            }
          }

          .glyphicons {
            font-size: 13px;

            &:before {
              padding: 1px 0 0;
            }
          }

          .glyphicons-group {
            margin-right: 2px;
          }
        }
      }

      .active {
        > a {
          .navbar-active-mixin;

          &:hover,
          &:focus { .navbar-active-mixin; }
        }
      }

      .dropdown {
        .dropdown-toggle {
          .caret-mixin;

          &.profile,
          &.warning {
            &:after {
              content: "";
              border: 0 none;
            }
          }

          &.profile {
            .profile-icon-mixin;
          }

          &.warning {
            .warning-icon-mixin;
          }
        }

        .dropdown-menu {
          li {
            margin-bottom: 0;

            a {
              color: @gray;
              padding: 7px 9px;

              .glyphicons {
                opacity: 0.7;
              }

              &:hover,
              &:focus { .menu-hover-mixin; }

              &.soften {
                &:hover,
                &:focus {
                  .menu-active-mixin;
                }
              }
            }

            &.active {
              > a {
                .menu-active-mixin;

                &.soften {
                  color: @white;
                  background: lighten(@gray-light, 20%);    // #aaa
                }
              }
            }
          }
        }

        &.open {
          .dropdown-toggle {
            color: @gray-lighter;
            .default-hover-mixin;
            .box-shadow(inset 0 5px 10px rgba(0, 0, 0, 0.5));

            a {
              color: @gray;
            }

            .uber-caret-mixin;

            .caret {
              opacity: 0.9;
            }

            &.profile {
              .profile-icon-mixin;
            }

            &.warning {
              .warning-icon-mixin;
            }
          }
        }

        .add-uploads-label {
          margin-left: 4px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }

    // Customer Info on right side of Sub-Navbar

    .managed-customer-info {
      padding-right: 10px;
      margin: 9px 0 0 0;

      a {
        color: @gray-light;    // #777
        font-size: 1em;
      }
    }
  }
}

.layout-bs3-uberflip {
  // ************************************************************************
  // Top Navbars

  .navbar.navbar-fixed-top {
    // Customer Info on right side of Sub-Navbar

    .managed-customer-info {
      a {
        color: darken(@gray-lighter, 13.5%);    // #ccc
      }
    }
  }
}

.layout-bs3 {

  // ************************************************************************
  // Top Navbars

  .navbar.navbar-fixed-top {
    &.navbar-inverse {
      .navbar-nav {
        > li {
          > a {
            color: lighten(@gray-light, 20%);    // #aaa

            &:hover {
              color: darken(@gray-lighter, 6.7%);    // #ddd
            }
          }

          &.active {
            > a {
              color: @gray-lighter;    // #eee
            }
          }
        }
      }
    }
  }
}

.layout-bs3-uberflip {

  // **************************************************************
  // Default Style Hover State for Navbars and Dropdown Menus

  .default-hover-mixin {
    color: @gray-lighter;
    background-color: @gray;
    background-repeat: no-repeat;
    background-image: none;
  }

  // **************************************************************
  // Uberflip Style Hover State for Navbars and Dropdown Menus

  .uber-hover-mixin {
    color: @white;
    background-color: @uberPinkDark;
    background-image: none;
    filter: none;
    .box-shadow(none);
  }

  // **************************************************************
  // Standard Hover/Active Sets for Navbar Links

  .navbar-hover-mixin {
    color: darken(@gray-lighter, 6.7%);    // #ddd
    background-color: darken(@gray, 7%);    // #444
  }

  .navbar-active-mixin {
    .navbar-hover-mixin;
    .box-shadow(inset 0 5px 10px rgba(0, 0, 0, 0.5));
  }

  // **************************************************************
  // Uberflip Style Hover/Active Sets for Navbar Links

  .uber-navbar-hover-mixin {
    background-color: @uberPinkDark;
    filter: none;
  }

  .uber-navbar-active-mixin {
    .uber-navbar-hover-mixin;
    .box-shadow(inset 0 0 6px rgba(0, 0, 0, 0.2));
  }

  // **************************************************************
  // Uberflip Style Hover/Active Sets for Sub-Navbar Links

  .uber-subbar-hover-mixin {
    background-color: darken(@gray, 7%);  // #434343
  }

  .uber-subbar-active-mixin {
    .uber-subbar-hover-mixin;

    background-color: darken(@gray, 6.5%);  // #444
    background-image: none;
    filter: none;
  }

  // **************************************************************
  // Standard Hover/Active Sets for Navbar Dropdown Menu Links

  .menu-hover-mixin {
    color: @white;
    background-color: @uberBlueSharp;
    background-image: none;

    .metadata { color: #e5e5e5; }
    .glyphicons { opacity: 1; }
  }

  .menu-active-mixin {
    color: @gray-dark;
    background-color: darken(@gray-lighter, 6.7%);   // #ddd
    background-image: none;
    .glyphicons { opacity: 1; }
  }

  // **************************************************************
  // Default Styles for Carets

  .uber-caret-mixin {
    .caret {
      border-top-color: @white;
      border-bottom-color: @white;
      opacity: 0.6;
    }

    &.open,
    &.active,
    &:hover {
      .caret {
        opacity: 0.9;
      }
    }
  }

  // --------------------------------------------------------------------
  // Primary Top Navbar - User Menu

  .navbar.navbar-user-menu {
    margin: 0;
    background: @uberPink;
    border: 0 none;

    .navbar-nav {
      li {
        margin: 0;

        a {
          &:hover,
          &:focus { .uber-navbar-hover-mixin; }
        }

        &.divider-vertical {
          border-color: @uberPinkDark;        // was: #ab024b
          background-color: @uberPinkDark;    // was: #ab024b
        }
      }

      > li {
        > a {
          color: @gray-lighter;
        }
      }

      .dropdown {
        .dropdown-menu {
          li {
            a {
              &:hover,
              &:focus {
                background: @uberPink;
                filter: none;
              }
            }

            &.active {
              > a {
                &:hover,
                &:focus {
                  .default-hover-mixin;
                }
              }
            }

            .support-phone {
              text-align: center;
              font-weight: bold;
            }
          }
        }

        .dropdown-toggle {
          .uber-caret-mixin;

          .profile,
          .warning {
            &:hover,
            &:focus {
              .default-hover-mixin;
            }
          }
        }

        &.open {
          .uber-navbar-active-mixin;

          .dropdown-toggle {
            background-color: @uberPinkDark;

            .profile {
              .default-hover-mixin;
            }
          }
        }

        .add-uploads-label {
          background-color: @uberPink;

          &:hover,
          &:focus { .uber-navbar-hover-mixin; }
        }

        .user-avatar-wrapper {
          > span {
            margin-left: 25px;
          }
        }
      }

      .active {
        .uber-navbar-active-mixin;

        > a {
          .uber-navbar-active-mixin;

          &:hover,
          &:focus { .uber-navbar-active-mixin; }
        }
      }
    }
  }

  .dropdown-menu {
    li {
      a {
        &:hover {
          .uber-hover-mixin;
        }
      }
    }
  }

  .navbar-nav.navbar-right {
    > li {
      &.upgrade-bubble {
        .pos-fix(0, 385px);     // T, R, B, L

        width: 358px;
        max-height: 52px;
        background: @white;
        .box-shadow(1px 0px 4px darken(@grayLight, 6.5%));    // #888
        .border-bottom-radius(8px);

        > div.pull-left { padding: 5px 15px; }
        > div.pull-right { padding: 7px 9px; }

        h3 {
          margin: 0;
          line-height: @baseLineHeight + 2;   // ~22px
          color: #6a6a6a;
          font-size: @baseFontSize + 3;  // ~15px
          font-weight: normal;
          text-align: center;

          strong {
            font-size: @baseFontSize + 6;  // ~18px
            font-weight: bold;
          }
        }

        p {
          margin-bottom: 0;
          color: #6a6a6a;
          font-size: @baseFontSize;
          text-align: center;

          a {
            color: @uberBlueLight;
            font-weight: bold;
            text-decoration: underline;

            &:hover {
              background: none;
            }
          }
        }

        a.btn-info {
          &:hover {
            background-color: @uberBlueLight;
          }
        }
      }
    }
  }

  // --------------------------------------------------------------------
  // Primary Top Navbar - Admin Menu

  .navbar.navbar-admin-menu {
    .navbar-nav {
      .dropdown {
        .dropdown-menu {
          li {
            a {
              &:hover,
              &:focus {
                background: @uberPink;
              }
            }
          }
        }
      }
    }
  }
}

// ************************************************************************
// Navigation Tabs

.layout-bs3 .nav-tabs {
  a {
    color: @uberPink;

    &:hover,
    &:active,
    &:focus {
      color: @uberBlue;
    }
  }

  .active {
    a:link,
    a:visited,
    a:hover,
    a:active,
    a:focus {
      color: @gray-darker;
    }
  }

  > li.pull-right {
    float: right;
  }

  > .disabled {
    > a,
    > a:hover {
      color: darken(@gray-lighter, 13.5%);   // #ccc
      background-color: @white;
      border: 0 none;
      cursor: help;
    }
  }

  > .hidden {
    > a,
    > a:hover {
      display: none;
    }
  }

  &.nav-stacked {
    > li {
      &.active {
        > a {
          background: @uberPink;
          color: @white;
        }
      }
    }
  }
}

// ************************************************************************
// Navigation Pills

.layout-bs3 .nav-pills > li > a.img {
  padding-left: 24px;
}

// ************************************************************************
// Navigation Tab Content

.layout-bs3 {
  .tab-content {
    display: table;
    width: 100%;
    padding: 40px 0;
  }

  .upload-tab {
    padding: 0;
  }
}

// ************************************************************************
// Tabbables - Left Tabs

.layout-bs3 {
  .tabbable {
    .clearfix();
  }

  .tabs-right > .nav-tabs,
  .tabs-left > .nav-tabs {
    border-bottom: 0;
  }

  .tabs-left > .nav-tabs > li,
  .tabs-right > .nav-tabs > li {
    float: none;
  }

  .tabs-left > .nav-tabs > li > a,
  .tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
  }

  .tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd;
  }

  .tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    .border-radius(4px 0 0 4px);
  }

  .tabs-left > .nav-tabs > li > a:hover,
  .tabs-left > .nav-tabs > li > a:focus {
    border-color: @gray-lighter #ddd @gray-lighter @gray-lighter;
  }

  .tabs-left > .nav-tabs .active > a,
  .tabs-left > .nav-tabs .active > a:hover,
  .tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: @white;
  }

  .tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd;
  }

  .tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    .border-radius(0 4px 4px 0);
  }

  .tabs-right > .nav-tabs > li > a:hover,
  .tabs-right > .nav-tabs > li > a:focus {
    border-color: @gray-lighter @gray-lighter @gray-lighter #ddd;
  }

  .tabs-right > .nav-tabs .active > a,
  .tabs-right > .nav-tabs .active > a:hover,
  .tabs-right > .nav-tabs .active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    *border-left-color: @white;
  }
}

// ************************************************************************
// Basic Hubs Management Container

.mgmt-container {
  .content-container {

    // **************************************************************
    // Uberflip Style Hover State for Links within Page-Level Navbars

    .link-hover-mixin {
      color: @uberGrayDarker;
      background-color: lighten(@gray-light, 20%);   // #aaa
      background-image: none;
      filter: none;
    }

    // ********************************************************************
    // Navbar

    .navbar {
      height: 38px;
      min-height: 38px;
      margin-bottom: 0;

      &.navbar-inverse {
        background-color: transparent;
        border: 0 none;
      }

      .navbar-nav {
        float: none;
        display: inline-block;
        left: auto;
        margin: 0 auto;
        text-align: left;

        @media only screen and (max-width: 900px) {
          > li {
            width: 55px;
            height: 39px;

            > a {
              height: 39px;
            }
          }
        }

        > li {
          margin-bottom: 0;
          background-color: @gray-dark;

          &:first-child {
            .border-radius(5px 0 0 5px);

            > a {
              .border-radius(5px 0 0 5px);
            }

            > ul {
              margin-left: 5px;
            }
          }

          &:last-child {
            .border-radius(0 5px 5px 0);

            > a {
              .border-radius(0 5px 5px 0);
            }
          }

          &.active:not(.open) > a:not(:hover) {
            background-color: lighten(@uberGrayDarker, 7%);
          }

          > a {
            padding: 10px 20px 10px 10px;
            color: @gray-lighter;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
            .transition(@navbarTransition);

            &:before {
              display: none;
              .pos-abs(auto, auto, -15px, 25px);      // T, R, B, L
              .triangle_bottom(@uberGrayDarker, 8px);
              .transition(@navbarTransition);

              content: "";
            }

            &:hover {
              color: @gray-lighter;
              background-color: @uberBlue;
              filter: none;

              &:before {
                .triangle_bottom(@uberBlue, 8px);
              }
            }
          }

          &.dropdown {
            &.open {
              > a {
                color: @gray-lighter;
                background-color: @gray-dark;

                &:hover {
                  background-color: @uberBlue;
                  cursor: pointer;
                }
              }
            }

            > a {
              cursor: default;
            }
          }
        }

        .dropdown-menu {
          z-index: 1001;
          min-width: 220px;
          max-height: 450px;
          margin-top: 0;
          padding: 10px 0;
          background: @uberGrayLight;
          border: 0 none;
          .border-radius(0);
          .box-shadow(1px 1px 3px 1px rgba(0, 0, 0, 0.15));

          overflow: auto;

          > li {
            margin-bottom: 0;

            > a {
              max-width: 240px;
              color: @gray-lighter;
              padding: 10px 20px;
              font-weight: normal;
              .transition(@navbarTransition);
              .text-overflow();

              &:hover {
                .link-hover-mixin;
              }
            }

            &.active {
              > a {
                .link-hover-mixin;
              }
            }

            &.add-new {
              padding: 20px 0 10px;

              a {
                width: auto;
                padding: 5px;
                margin: 0 20px;
                border: 1px solid @gray-lighter;
                text-align: center;
                .border-radius(3px);

                &:hover {
                  color: @uberGrayDarker;
                  background-color: @gray-lighter;
                  filter: none;
                }
              }
            }
          }

          &:before,
          &:after { display: none; }
        }

        .open > a {
          background-color: transparent;
          .box-shadow(none);

          &:before { display: inline-block; }
        }

        .active > a {
          color: @uberBlueLight;
          background-color: @uberGrayDarker;
          .box-shadow(none);
        }
      }

      .dropdown-text {
        display: inline-block;
      }

      @media only screen and (max-width: 900px) {
        .dropdown-text {
          display: none;
        }
      }

      span.nb-icon {
        position: relative;
        display: block;
        float: left;
        .size(40px, 22px);

        margin-top: -3px;
        .transition(@sidemenuTransition);
        .transform(scale(0.8));

        i {
          .square(@sidemenuIconWidth);
          .absolute-center;

          background-color: transparent;
          background-repeat: no-repeat;
        }
      }

      span.service-icon {
        position: relative;
        display: inline-block;
        .square(22px);

        margin-right: 10px;
        margin-top: -3px;
        .transition(@sidemenuTransition);

        i {
          .square(22px);
          .absolute-center;

          background-color: transparent;
          background-repeat: no-repeat;
        }
      }

      &.light {
        .navbar-nav {
          > li {
            > a {
              &:before {
                .triangle_bottom(@uberGrayDark, 8px);
              }

              &:hover {
                &:before {
                  .triangle_bottom(@uberBlue, 8px);
                }
              }
            }
          }

          .dropdown-menu {
            > li {
              > a {
                &:hover {
                  color: darken(@uberGrayDark, 6.5%); // #555
                }
              }

              &.active {
                > a {
                  color: darken(@uberGrayDark, 6.5%); // #555
                }
              }

              &.add-new {
                a {
                  &:hover {
                    color: darken(@uberGrayDark, 6.5%); // #555
                  }
                }
              }
            }
          }
        }
      }

      &.small {
        .navbar-nav {
          > li {
            > a {
              padding: 5px 12px 4px;
              font-size: @font-size-base;

              &:before {
                left: 19px;
              }

              &.active {
                background: @uberPink;
                border-left: 5px solid #fff;
                border-right: 5px solid #fff;

                &:hover {
                  background: darken(@uberPink, 5%);
                }
              }
            }
          }
        }
      }
    }
  }
}

// ****************************************************************************
// Firefox Only
@-moz-document url-prefix() {
  .mgmt-container .content-container .navbar {
    span.nb-icon {
      margin-top: -8px;
    }

    span.service-icon {
      margin-top: -8px;
    }
  }
}
