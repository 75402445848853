// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.layout-bs3 {
  ul#side_menu {
    &.settings {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin: 0;
        list-style-type: none;

        a {
          display: block;
          padding: 4px 6px 4px 12px;
          font-weight: normal;
          color: darken(@gray, 6.5%);    // #444
          text-decoration: none;

          &.disabled {
            color: lighten(@gray-light, 13.2%);
            font-style: italic;

            &:hover {
              background-color: transparent;
            }
          }

          &.btn {
            text-align: left;

            &:hover {
              border-color: #05c #05c #003580;
              border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            }
          }

          &.non-btn {
            &:hover {
              color: @gray-darker;
              background-color: @gray-lighter;
            }
          }

          &.current,
          &.current:hover {
            color: inherit;
            font-weight: bold;
            background-color: #f9f9f9;
          }

          &.checkmark {
            background-image: url("/img/yes.png");
            background-position: right center;
            background-repeat: no-repeat;
          }

          &.img {
            padding-left: 24px;
          }
        }
      }
    }
  }

  .account_menu {
    ul#side_menu {
      &.settings {
        padding: 0;

        li {
          a:hover {
            background: #f5f5f5;
          }
        }
      }
    }
  }

  div.settings_menu_header {
    border-bottom: 1px solid darken(@gray-lighter, 13.5%);    // #ccc
    margin-bottom: 1px;

    a,
    .as-link {
      display: block;
      padding: 6px;
      color: darken(@gray, 6.5%);    // #444
      font-size: @font-size-base + 2;  // ~14px
      text-decoration: none;
      text-shadow: 1px 1px 1px @white;
      background: @gray-lighter;

      img {
        vertical-align: middle;
        padding-right: 6px;
      }

      .halflings {
        color: #888;
        font-size: 12px;
      }
    }

    a {
      background: @gray-lighter url("/img/arrow_down.png") no-repeat right;

      &:hover {
        color: @gray-dark;
        background-color: darken(@gray-lighter, 6.7%);    // #ddd
      }

      &.on {
        color: darken(@gray, 6.5%);    // #444
        background-color: darken(@gray-lighter, 6.7%);    // #ddd
        background-image: url("/img/arrow_up.png");
      }
    }
  }
}

.layout-bs3-uberflip {
  ul#side_menu {
    &.settings {
      li {
        a {
          &:hover {
            border-color: @gray-lighter;
          }
        }
      }
    }
  }

  div.settings_menu_header {
    border-color: @gray-lighter;

    &,
    a,
    .as-link {
      padding-top: 12px;
      font-weight: bold;
      background-color: transparent;
    }

    a {
      &:hover,
      &.on {
        background-color: transparent;
        color: darken(@gray-darker, 6.5%); // #111
        font-weight: bold;
      }
    }
  }
}
