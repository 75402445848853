// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.layout-bs3 {
  .settings-section-mixin {
    clear: both;
    margin-bottom: 20px;
    padding: 12px;
    background: @white;
    border: 1px solid darken(@gray-lighter, 13.5%);    // #ccc
  }

  .settings-header-mixin {
    padding: 10px;
    margin-bottom: 0;
    background: darken(@gray-lighter, 13.5%);    // #ccc
    color: darken(@gray, 6.5%);    // #444
    font-size: @font-size-base + 1;   // ~13px
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px @white;
  }

  div.settings_wrapper,
  div.title_settings_wrapper {
    padding: 4px;
  }

  div.title_settings_section {
    .settings-section-mixin;
  }

  h3.title_settings_section,
  h3.settings_section,
  div.settings_header {
    .settings-header-mixin;
  }

  div.settings_header {
    &.options {
      text-transform: lowercase;
      font-weight: normal;
      text-align: left;
    }

    .uploads-remaining {
      margin-right: 15px;
    }

    &.text-left {
      text-align: left;
    }

    &.selector {
      border-bottom: 1px solid lighten(@gray-lighter, 20%);    // #bbb
      cursor: pointer;

      &:hover {
        color: @gray-darker;
        background-color: #d9d9d9;
      }
    }

    div {
      float: left;
      clear: none;
      margin-right: 8px;
      line-height: 31px;

      span {
        font-weight: normal;
      }

      &.clr {
        float: none;
        clear: both;
        padding: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }

  div.settings_section {
    .settings-section-mixin;

    .search-group-container {
      .pos-abs(13px, auto, auto, 0);
    }

    .left,
    .right {
      .input-group-addon {
        padding: 6px;
      }
    }

    &.cap {
      margin-bottom: 0;

      .export-section {
        float: left;
        clear: none;
        padding: 12px;
        line-height: @line-height-computed;
      }

      .batch-section {
        float: right;
        clear: none;
        margin-left: 30px;
        border: 0 solid darken(@gray-lighter, 6.7%);    // #ddd

        .batch-heading {
          padding: 6px;
          background: @gray-lighter;
          border-bottom: 0 solid darken(@gray-lighter, 6.7%);    // #ddd
          font-weight: bold;
        }

        .batch-status {
          float: left;
          clear: none;
          padding: 12px;
        }

        .batch-values {
          float: right;
          clear: none;
          padding: 12px;
          margin-left: 15px;
        }
      }
    }

    &.tiered {
      padding: 0;
      margin-bottom: 0;
    }

    // Fix Pagination Links

    .pagination {
      float: right;
      margin: 10px 0 1px 0;

      ul {
        margin: 0;

        li {
          float: none;
          width: auto;
        }
      }
    }

    .pagination-limits-info {
      .pagination {
        margin: -5px 0 5px 0;
      }
    }

    .pagination-element-bottom {
      .pagination {
        margin: 5px 0 0 0;
      }
    }
  }

  .settings_box_blue,
  .settings_box_orange {
    padding: 12px;
    margin: 0.3em;
    border: 1px solid darken(@gray-lighter, 13.5%);    // #ccc
  }

  .settings_box_blue {
    background: #dceeff;
  }

  .settings_box_orange {
    background: #fff8e2;

    label {
      color: @gray;
    }
  }

  div.settings_section_tier {
    height: 300px;
    overflow-y: auto;

    .welcome {
      margin: 0 auto;
      text-align: center;
    }
  }

  div.settings_footer {
    padding: 12px;
    margin-top: -4px;
    background: darken(@gray-lighter, 13.5%);    // #ccc
  }

  // Adjustments for Large Forms

  .form-large {
    .settings_wrapper {
      .search-options {
        float: left;
        clear: none;
        width: 61%;
      }

      .search-fields {
        float: right;
        clear: none;
        width: 37%;

        .options {
          float: right;
        }

        .settings_section {
          margin-bottom: 12px;
        }
      }
    }
  }
}

.layout-bs3-uberflip {
  div.settings_header {
    &.tiered {
      .border-radius(0);
    }

    &.selector {
      border-bottom: 0;
    }

    &.aggregate {
      padding: 12px;
      color: @gray-lighter;
      font-size: @font-size-base + 8;   // ~20px

      span {
        color: lighten(@gray-light, 13.2%);
        font-weight: normal;
        font-size: 85%;
      }
    }
  }

  div.settings_section {
    border-color: @gray-lighter;

    div.interface_stats {
      float: right;
      clear: none;
      width: 70%;

      table {
        &,
        span.label {
          font-size: @font-size-base + 6;       // ~18px
          line-height: @line-height-computed + 6;   // ~26px
        }

        span.label {
          background-color: @gray-dark;
        }
      }
    }

    &.aggregate {
      padding: 30px 0;
      color: @uberPink;
      font-size: 46px;
      font-weight: bold;
      text-shadow: 1px 1px 3px darken(@gray-lighter, 13.5%);    // #ccc
      text-align: center;
      vertical-align: middle;

      &.aggregate-table {
        padding: 0;
        font-size: 26px;

        .table {
          margin-bottom: 0;

          tbody {
            td {
              padding: 14px 8px;
              color: @gray-dark;

              span {
                color: #454545;
              }
            }

            th {
              padding-top: 18px;
              background: @gray-lighter;
              text-align: center;

              span.flag {
                margin-left: 0;
              }
            }
          }
        }
      }

      span {
        margin-left: 20px;
        font-weight: 100;
        color: @gray-lighter;
      }
    }
  }

  h3.title_settings_section,
  h3.settings_section,
  div.settings_header {
    background: #f9f9f9;
  }

  h3.title_settings_section,
  div.title_settings_section,
  h3.settings_section,
  div.settings_header {
    border: 1px solid @gray-lighter;
    border-bottom: 0;
  }

  div.settings_footer {
    background: #f6f5ef;
    border: 1px solid #eae7e0;
  }
}
