// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

a {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: @linkColor;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
  }
  &:hover { color: @linkColorHover; }

  &.title-link {
    margin-bottom: 20px;
    font-size: @font-size-base + 4;
    font-weight: bold;
  }

  // Alternate Link Color

  &.alt {
    color: @linkAltColor;
    &:hover { color: @linkAltColorHover; }
  }

  &.btn-info,
  &.btn-primary {
    color: @white;
  }

  &.close {
    color: @black !important;
  }

  &.full {
    &:link,
    &:visited,
    &:active {
      padding-left: 3px;
      padding-right: 3px;
      text-decoration: none;
    }

    &:hover {
      color: @white;
      background-color: lighten(@gray-light, 6.5%); // #888
      text-decoration: none;
    }
  }

  &.blend,
  &.blend:link,
  &.blend:visited {
    color: inherit;
  }

  &.blend:hover {
    text-decoration: underline;
  }

  &.issue_thumb {
    img {
      width: 120px;
      border: 1px solid darken(@gray-lighter, 13.5%); //#ccc;
      .box-shadow(1px 2px 4px rgba(0, 0, 0, 0.2));

      overflow: hidden;
      background: transparent no-repeat top left;
      cursor: pointer;
    }
  }
}

input::-moz-focus-inner { border: 0; }

.align-left { text-align: left; }
.align-right { text-align: right; }
.align-center { text-align: center; }
.align-justify { text-align: justify; }

p,
li {
  color: @uberGrayDarker;
  font-size: @font-size-base + 1;
  font-family: @roboto;
  line-height: @line-height-computed + 2;    // ~20px
}

p,
li,
span {
  &.alt {
    font-family: @font-family-monospace;
  }

  &.large {
    font-size: @font-size-base + 10;
  }

  &.branded {
    color: @uberPink;
  }

  small {
    color: @uberGrayDark;
    font-size: @font-size-base;
  }
}

p {
  margin-bottom: 12px;

  &.no-margin { margin-bottom: 0; }
}

li {
  margin-bottom: 0.3em;
}

b,
strong { font-weight: 700; }

i,
em { font-style: italic; }

.underline { text-decoration: underline; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: @roboto;
  font-weight: @headingsFontWeight;
  color: @uberGrayDarker;
}

h1 {
  color: @uberGrayDarker;
  font-size: (@font-size-base + 8);         // ~20px

  small {
    font-size: (@font-size-base + 8);         // ~20px
  }
}

h2 {
  color: @uberGrayDarker;
  font-size: (@font-size-base + 6);         // ~18px
  margin-bottom: 20px;
}

h3 {
  font-size: (@font-size-base + 6);         // ~18px
  line-height: (@line-height-computed + 9);     // ~27px
}

h4 {
  font-size: @font-size-base;
}

h6 {
  font-size: (@font-size-base - 1);         // ~11px
  text-transform: uppercase;
}

ul {
  &.compact {
    margin: 8px;
    padding-left: 12px;
  }
}

ol {
  &.branded {
    > li {
      position: relative;
      list-style-type: none;
      counter-increment: uberlist;

      &:after {
        position: absolute;
        top: 0;
        left: -2.5em;
        width: 2em;
        text-align: right;
        color: @uberPink;
        content: counter(uberlist) ")";
      }

      ul {
        li {
          list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB90IDRIlLRLgzcEAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAV0lEQVQI132OsQ2AMAwEzxGTpGIMZ4vUMAiTxDVbhDEyDo0tohR883rfv2QBGNQGHHyynftMDjJQAHHPg9o2XxTg8VV4T8uBOQfUBWpAA66poJ5N/r59AYA9FAxgg/OvAAAAAElFTkSuQmCC");
        }
      }
    }
  }

  &.compact {
    margin: 0;
    padding-left: 20px;
  }
}

hr {
  height: 1px;
  margin: 6px;
  border: 0;
  border-top: 3px dotted @gray-lighter;
}

.example,
blockquote {
  background-color: #f6f6f6;
  border: 1px solid #e6e6e6;
  font-family: @roboto;
  margin-bottom: 12px;
  margin-top: 3px;
  padding: 10px 0 10px 20px;
}

.layout-bs3-uberflip {
  legend {
    padding-top: 12px;
    font-weight: bold;
    background-color: transparent;
  }
}
