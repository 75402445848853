// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

// Older Pagination Elements (Non-BS3)

.pagination {
  height: 36px;
  margin: 0;

  ul {
    margin: 0;

    > li {
      width: auto;
      float: none;

      > a {
        padding: 0px 14px;
        line-height: 34px;
        color: @uberGrayDark;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);

        &:hover {
          color: @uberGrayDarker;
          background-color: @whiteSmoke;
        }

        &.active {
          a {
            color: @uberGrayDarker;

            &:hover {
              background-color: @whiteSmoke;
            }
          }
        }

        &.disabled {
          a {
            &a:hover {
              color: #bfbfbf;
            }
          }
        }
      }
    }
  }
}

.pagination-container {
  font-size: 90%;
  color: @uberGrayDark;
  clear: both;
}

.pagination-element {
  padding: 6px;
}

.pagination-element-top {
  margin-bottom: 18px;
}

.pagination-element-bottom {
  margin-bottom: 0;
}

.pagination-page-info {
  float: right;
  font-weight: bold;

  span.links { font-size: 125%; }
}

.pagination-limits-info {
  float: right;
  margin-right: 20px;

  .pagination {
    .disabled {
      a {
        &,
        &:hover {
          color: @uberGrayDark;
          cursor: default;
        }
      }
    }
  }
}

.pagination-separator {
  display: none;
}

form.pagination-form {
  margin: 0;
}

.pagination-label {
  font-size: @font-size-base - 1;   // ~11px
  line-height: 32px;
  color: lighten(@gray-light, 6.5%);    // #888
}

// ***************************************************************************************
// BS3 Pagination

ul.pagination {
  height: auto;

  > li {
    margin: 0;

    > a {
      color: @gray;    // #555

      &:hover {
        color: @gray-dark;    // #333
      }
    }

    &.active {
      > a {
        &,
        &:hover {
          color: @gray-dark;    // #333
          background: @whiteSmoke;
          border-color: darken(@gray-lighter, 6.7%);    // #ddd
        }
      }
    }
  }
}
