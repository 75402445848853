// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

.layout-v2,
.layout-bs3 {
  .modal {
    font-family: @roboto;

    .close {
      border: 0 none;
      background: transparent;
      opacity: 0.4;

      &:hover {
        opacity: 0.75;
      }
    }

    .alt-close-icon {
      .pos-abs(20px, 20px);   // T, R, B, L

      float: none;
    }

    .modal-header {
      h4 {
        font-size: @font-size-base + 4;    // ~16px
        .text-overflow();
      }

      .close {
        background: transparent;
      }
    }

    .modal-body {
      position: relative;
      max-height: 600px;
      background-color: @whiteMist;
      overflow: auto;
      border-radius: inherit;
    }

    .modal-footer-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .modal-footer {
      p {
        label { line-height: 28px; }

        input[type="checkbox"],
        input[type="radio"] { display: inline-block; }
      }

      background-color: @white;
      border-radius: inherit;

      .checkbox-confirm {
        margin-top: 7px;
        font-size: @font-size-base - 2;
      }
    }

    .modal-loading {
      min-height: 300px;
      background: transparent url("/img/loading.gif") no-repeat center;
    }

    .progress {
      padding: 30px;
    }

    &.abs-pos {
      position: absolute;
    }
  }
}

.layout-bs3 {
  .modal.modal-v2 {
    .close {
      font-size: (@font-size-base + 10);
    }

    .alt-close-icon {
      .pos-abs(10px, 15px);   // T, R, B, L

      float: none;
    }

    .modal-body {
      .clearfix;
    }

    .modal-header {
      .modal-title { // H4 tag
      }

      .modal-image,
      .modal-sprite { // DIV tag
        display: none;
        margin: 0 auto 10px auto;
        max-height: 120px;
      }

      .modal-desc { // P tag
        display: none;
      }

      .modal-nav {
        display: none;
        border: 0 none;
        text-align: center;

        > .nav.nav-pills {
          display: inline-block;
          margin-bottom: -6px;

          > li {
            margin-bottom: 0;

            > a {
              padding: 6px 22px;
              margin: 0;
              color: darken(@gray-light, 6.5%);
              border-radius: 0;
              cursor: pointer;

              &:hover {
                background: transparent;
                border-bottom: 5px solid @gray-lighter;
              }
            }

            &.active {
              > a,
              > a:hover {
                color: @uberGrayDark;
                font-weight: bold;
                background: transparent;
              }
            }
          }
        }
      }

      &.large-header {
        text-align: center;
        padding-bottom: 0;
      }
    }

    .modal-footer {
      p {
        float: left;
        margin: 0 10px;
        line-height: 34px;

        a:not(.btn) {
          color: @gray;

          &:hover {
            color: @gray-dark;
          }
        }

        label {
          font-weight: normal;
          line-height: 34px;
        }

        input[type="checkbox"],
        input[type="radio"] {
          display: inline-block;
          vertical-align: text-bottom;
        }
      }

      .btn {
        &.btn-link {
          color: @gray-light;
        }

        &.pull-right {
          margin-left: 5px;
        }

        &.pull-left {
          margin-right: 5px;
        }
      }
    }

    .modal-loading {
      min-height: 300px;
      background-color: transparent;
      background-image: url("/img/loading.gif");
      background-repeat: no-repeat;
      background-position: center 20%;

      .loading-text,
      .loading-subtext {
        display: block;
        color: @gray-light;
        font-size: @font-size-base;
        text-align: center;
      }

      .loading-text {
        padding-top: 180px;
        font-size: (@font-size-base + 2);
        font-weight: bold;
      }
    }

    &.abs-pos {
      position: absolute;
    }

    // --------------------------------------------------------------------
    // Modal with Steps Nav bar

    &.has-step-nav {
      .modal-header {
        overflow: hidden;

        .modal-nav {
          margin: 10px -15px -16px -15px;

          > .nav.nav-pills {
            > li {
              > a {
                border: 0 none;

                &:hover {
                  border: 0 none;
                }

                &.disabled {
                  color: lighten(@gray-light, 13.5%);     // #bbb
                  cursor: not-allowed;

                  &:after {
                    border-color: transparent transparent transparent @whiteSmoke;
                    cursor: not-allowed;
                  }
                }
              }

              &.active {
                &:after {
                  border-color: transparent transparent transparent @uberBlueLight;
                }

                > a,
                > a:hover {
                  &:after {
                    border-color: transparent transparent transparent @uberBlueLight;
                  }
                }
              }
            }
          }
        }

        &.large-header {
          .modal-nav {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.layout-bs3 {
  .modal.modal-v2.filters-modal {
    .modal-sprite {
      margin: 20px 0 10px 0;
      color: @uberGrayLighter;
      font-size: (@font-size-base + 24);
    }

    .modal-title {
      margin-top: 25px;
      font-size: (@font-size-base + 11);
      font-weight: 400;
    }

    .modal-desc {
      margin-top: 3px;
      color: @uberGrayLight;
      font-size: (@font-size-base + 1);
    }

    .modal-nav {
      .nav-pills {
        li {
          a {
            .filter-step-icon {
              display: inline-block;
              width: 20px;
              margin-right: 5px;
              .border-radius(50%);

              background-color: @uberGreen;
              color: @white;

              &.disabled {
                background-color: @uberGrayLighter;
              }
            }
          }
        }
      }
    }

    .modal-body {
      padding: 10px 20px 20px;
      overflow: initial;

      form {
        min-height: 400px;

        .step-search,
        .step-action {
          padding: 10px 0 0 0;

          h4 {
            margin: 0 0 5px 15px;
            font-size: (@font-size-base + 1);
            font-weight: 700;
          }
        }

        .step-search,
        .step-action {
          .add-new-link {
            display: none;
          }

          .filter-search,
          .filter-actions {
            .container-fluid {
              &.highlight-section {
                padding: 0px 0 0 3px;
              }

              .edit-fields {
                .row {
                  &.highlight-section {
                    padding: 10px 10px 0 10px;
                    .border-radius(5px);

                    background-color: #ececec;
                  }
                }
              }
            }
          }

          select.search-criteria,
          select.actions-select {
            width: auto;
          }

          .selected-search-criteria,
          .selected-actions {
            margin: 0 0 10px 0;

            .autocompleter-list {
              max-height: 300px;
              min-width: 70px;
              overflow-x: hidden;
              overflow-y: auto;
            }

            .add-new-search {
              padding-left: 5px;
            }

            .row {
              margin-bottom: 10px;

              &.highlight-section {
                padding: 10px 0 0 0;
                .border-radius(5px);

                background-color: #ececec;
              }

              .search-criteria-label {
                white-space: nowrap;
              }

              .search-criteria-label {
                text-align: left;
              }

              .action-label {
                text-align: right;
              }

              .search-criteria-label,
              .action-label {
                color: @uberGrayLight;

                .action-order-num {
                  float: left;
                  .clearfix;
                }
              }

              .search-criteria-value,
              .action-value {
                .clearfix;

                margin-left: 10px;
                font-weight: bold;

                .edit-link {
                  color: @black;

                  &:hover {
                    color: @uberBlueLight;

                    .tag-box {
                      border: 1px solid @uberBlueLight;
                      color: @white;
                      background-color: @uberBlueLight;
                    }
                  }

                  .tag-box {
                    margin: 0 5px 0 0;
                    padding: 3px 5px;
                    border: 1px solid @uberGrayLight;
                    background-color: @white;
                  }
                }

                .edit-actions-container {
                  float: right;

                  .halflings-remove,
                  .halflings-pencil {
                    padding: 0px 0 0 5px;
                    cursor: pointer;
                    color: @uberGrayLight;

                    &:hover {
                      color: @uberGrayDark;
                    }
                  }
                }
              }

              &.includes-streams,
              &.includes-text,
              &.includes-tags,
              &.item-status,
              &.excludes-streams,
              &.excludes-text,
              &.excludes-tags,
              &.remove-from-custom-streams,
              &.add-to-custom-streams,
              &.feature-in,
              &.unfeature-in,
              &.remove-tags,
              &.add-tags,
              &.item-status-action,
              &.remove-items {
                display: none;
              }
            }

            .divider {
              position: relative;
              display: none;
              margin-top: 10px;
              padding-top: 10px;
              border-top: 1px solid @uberGrayLighter;

              .and {
                position: absolute;
                top: -10px;
                display: inline-block;
                padding: 0 5px 0 0;
                color: @uberGrayLight;
                background-color: @whiteMist;
              }
            }
          }
        }

        .edit-fields,
        .edit-mode {
          .form-group {
            .and-or-container {
              text-align: right;

              label {
                display: inline-block;
                font-size: @font-size-base;
                font-weight: normal;
              }

              select {
                display: inline-block;
                width: auto;
              }
            }

            .and-or,
            .edit-mode-link {
              margin: 5px 0 0 0;
            }

            &.criteria {
              vertical-align: top;
            }

            &.search-tags,
            &.search-exclude-tags {
              display: none;
            }

            &.text-contains,
            &.text-not-contains {
              display: none;

              .text-entries {
                display: none;
              }

              .single-text-input {
                input {
                  font-size: @font-size-base;
                }
              }
            }

            &.text-not-contains {
              .single-text-input {
                margin: 0 0 0 10px;

                input {
                  width: 97%;
                }
              }
            }

            &.item-status {
              display: none;

              .item-status-select {
                width: 338px;
              }
            }

            &.select-streams,
            &.select-exclude-streams,
            &.select-custom-streams,
            &.select-custom-exclude-streams,
            &.select-feature-streams,
            &.select-feature-exclude-streams,
            &.text-contains,
            &.text-not-contains {
              display: none;
              width: 343px;

              .hub-selections,
              .stream-selections,
              .stream-exclude-selections,
              .custom-stream-selections,
              .custom-stream-exclude-selections,
              .feature-stream-selections,
              .feature-stream-exclude-selections {
                width: 343px;
              }

              .add-btn-streams,
              .add-btn-custom-streams,
              .add-btn-feature-streams {
                margin: 0 0 0 5px;
              }

              .selected-items,
              .selected-custom-streams,
              .selected-feature-streams,
              .selected-text {
                width: 343px;
                height: auto;
                margin: 5px 0 0 0;
                padding: 0;
                font-size: (@font-size-base - 1);
                overflow: auto;
                border: none;
                box-shadow: none;
                background-color: @lightBorder;

                .editable-item {
                  margin: 3px 0;
                  padding: 4px 6px 4px 7px;
                  background-color: #f9f9f9;
                  .border-radius(3px);

                  a {
                    &,
                    &:hover {
                      color: @uberGrayDark;
                    }
                  }
                }
              }

              .hub-selections {
                margin-bottom: 10px;
              }

              .selected-items-container,
              .selected-text {
                display: none;
              }
            }

            &.text-not-contains {
              .single-text-input {
                input {
                  width: 100%;
                }
              }

              .selected-text {
                margin-left: 10px;

                .editable-item {
                  width: 97%;
                }
              }
            }

            &.remove-items {
              div {
                margin: 5px 0 0 0;
                font-weight: bold;
              }
            }

            label.checkbox-options {
              .clearfix;

              display: block;
              padding: 5px 0 0 0;
              margin: 0;

              .checkbox-text {
                position: relative;
                top: -3px;
                font-size: (@font-size-base - 1);
              }

              a {
                padding: 7px 0 0 0;
                font-size: (@font-size-base - 1);
                font-weight: 400;
                color: @link-color;
              }
            }
          }

          .edit-btns {
            display: none;
            .clearfix;

            padding: 10px 22px 10px 10px;
            .border-radius(0 0 5px 5px);

            background-color: #ececec;

            .btn-link {
              &,
              &:hover {
                color: @uberGrayDark;
              }
            }

            &.actions,
            &.search {
              display: none;
            }
          }
        }

        .step-settings {
          padding-top: 10px;

          .form-group {
            margin-bottom: 5px;

            .select-trigger-type {
              padding: 0;
            }

            .trigger-text {
              margin: 3px 0 0 3px;
              font-size: (@font-size-base - 1);
              color: @uberGrayLight;

              .note {
                display: none;
              }
            }

            label,
            input {
              font-size: @font-size-base;
            }
          }

          .checkbox-container {
            margin: 15px 0 0 5px;

            .apply-now {
              margin-bottom: 0;

              .checkbox-text {
                position: relative;
                top: -3px;
                font-size: (@font-size-base - 1);
              }
            }

            .checkbox.preview {
              display: none;
            }
          }
        }
      }
    }

    .modal-footer {
      .btn-next-step {
        background-color: @uberBlueLight;
        border-color: @uberBlueLight;
        color: @white;

        &:hover {
          background-color: @uberTurqoise;
          border-color: @uberTurqoise;
        }
      }
    }
  }

  .move-folder-modal .modal-body {
    height: 300px;
  }
}

// ****************************************************************************
// Locked feature modal

.locked-feature-modal {
  .lock-container {
    padding: 30px;
    text-align: center;

    p {
      line-height: 20px;
      font-size: 12px;
      color: @gray-dark;
    }
  }
}

// ****************************************************************************
// Upgrade Account Modal - Flipbook upload order request

.modal {
  .upgrade-account-modal-content {
    text-align: center;
  }

  form {
    &.uploads-order-form {
      .buy-upload {
        padding: 0px 12px;
        margin: 10px 0 25px 0;
        font-size: 16px;

        label {
          margin-top: 0;
        }

        .per-upload {
          margin: 0 0 0 10px;
        }

        p.form-control-static {
          font-size: (@font-size-base + 4);
        }
      }

      .buy-upload-total {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid @gray-lighter;
      }

      .buy-upload-label {
        display: inline-block;
        width: 85px;
      }
    }
  }
}

// ****************************************************************************
// Modal with Nav bar (i.e. filter, wizard, etc.)

.modal.has-nav {
  .modal-header {
    border-bottom: 0;
  }

  .modal-nav {
    padding: 20px 0 0 0;
  }
}

// Stream dependencies error modal
#stream-dependencies-error .stream-dependency {
  margin-bottom: 10px;

  ul li {
    list-style: disc;
    margin-left: 2em;
  }
}

// Language Links conflict error modal
#hub-locale-conflict-error .hub-locale-conflicts {
  ul li {
    list-style: disc;
    margin-left: 2em;
    margin-bottom: 0;
  }
}

/* ~~~ Modal Dialog - Note: all modals should be changed to use BootStrap Html/CSS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#modal-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: darken(@gray, 7%); //#444;

  span {
    color: @uberGrayDark;
  }
}

.collection-share-modal .modal-footer {
  padding: 0;
  border-top: none;
}
