// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.integration-setup-sprite {
  background: transparent url("/img/hubs/cta-setup-spritesheet.png") no-repeat 0 0;

  &.mailchimp-icon {
    .size(31px, 34px);

    background-position: 0px 0;
  }

  &.hubspot-icon {
    .size(31px, 36px);

    background-position: -36px 0;
  }

  &.eloqua-icon {
    .size(54px, 33px);

    background-position: -70px 0;
  }

  &.marketo-icon {
    .size(44px, 34px);

    background-position: -125px 0;
  }

  &.pardot-icon {
    .size(41px, 34px);

    background-position: -170px 0;
  }

  &.salesforce-icon {
    .size(51px, 34px);

    background-position: -295px 0;
  }

  &.mailchimp-text {
    .size(160px, 39px);

    background-position: 0px -36px;
  }

  &.hubspot-text {
    .size(150px, 55px);

    background-position: 0px -75px;
  }

  &.form_cta_type-text {
    .size(71px, 62px);

    background-position: 0px -377px;
  }

  &.eloqua-text {
    .size(155px, 65px);

    background-position: 0px -130px;
  }

  &.marketo-text {
    .size(116px, 54px);

    background-position: 0px -192px;
  }

  &.pardot-text {
    .size(150px, 66px);

    background-position: 0px -252px;
  }

  &.salesforce-text {
    .size(93px, 63px);

    background-position: 0px -451px;
  }
}

.api-link {
  display: block;
  padding-top: 10px;
}

#connect-service-modal {
  font-family: @roboto;

  .integration-sprite-container {
    text-align: center;
    margin: 30px 0 12px 0;

    .integration-setup-sprite {
      display: inline-block;
    }
  }

  .connect-title {
    color: darken(@gray-light, 10%);     // #808080
    font-weight: 600;
    text-align: center;
    font-size: (@font-size-base + 6);    // 12 + 6
    padding: 10px;
  }

  .service-body {
    padding: 0px 60px 40px 60px;
  }

  .service-label {
    color: darken(@gray, 6.5%);         // #444
    font-size: @font-size-base;
    font-weight: 600;
  }

  .get-api-text {
    color: @gray-light;       //  #777
    font-size: @font-size-base;
  }

  .api-link {
    color: #2f8aa8;
    font-size: (@font-size-base + 4);     // 12 + 4
    text-decoration: underline;
  }

  .connect-button-container {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  #service-error {
    color: @red;
    font-size: (@font-size-base + 1);    // 12 + 1
  }

  .eloqua,
  .hubspot,
  .mailchimp,
  .marketo,
  .pardot,
  .salesforce {
    display: none;
  }

  &[data-servicetype="Marketo"] .marketo,
  &[data-servicetype="HubSpot"] .hubspot,
  &[data-servicetype="Eloqua"] .eloqua,
  &[data-servicetype="MailChimp"] .mailchimp,
  &[data-servicetype="Pardot"] .pardot,
  &[data-servicetype="Salesforce"] .salesforce {
    display: block;
  }

  &[data-servicetype="Marketo"] input.marketo,
  &[data-servicetype="HubSpot"] input.hubspot,
  &[data-servicetype="Eloqua"] input.eloqua,
  &[data-servicetype="MailChimp"] input.mailchimp,
  &[data-servicetype="Pardot"] input.pardot,
  &[data-servicetype="Salesforce"] input.salesforce {
    margin-bottom: 15px;
  }
}

.google-services-modal {
  .modal-header {
    .modal-sprite {
      span {
        display: block;
        height: 110px;
        margin: 0 auto;
        background: transparent url("/img/integrations/spritesheet.png") no-repeat;

        &.google-analytics-logo {
          background-position: -387px -586px;
          width: 200px;
        }

        &.google-tag-manager-logo {
          background-position: -586px -586px;
          width: 245px;
        }
      }
    }
  }

  .modal-desc {
    color: @uberGrayLight;
  }

  .service-body {
    padding: 60px;

    div:not(.note) {
      a {
        color: @uberGrayLight;
        text-transform: uppercase;
      }
    }

    input[type="text"] {
      font-size: @font-size-base;
    }
  }

  .alert {
    margin-bottom: 30px;
  }

  .service-label {
    font-size: @font-size-base;
    font-weight: 600;
  }

  .get-api-text {
    color: #777;
    font-size: @font-size-base;
  }

  .api-link {
    font-size: (@font-size-base + 4);
    text-decoration: underline;
    color: @uberBlue;
  }

  .service-key {
    width: 100%;
  }

  .connect-button-container {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .connect-button {
    border: 9px;
    border-radius: 30px;
    font-size: (@font-size-base + 8);
    padding: 10px 18px;
  }
}

.pardot-sso-modal.modal,
.eloqua-sso-modal.modal {
  display: block;

  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 15px;

        .modal-sprite {
          margin: 30px 0 12px;
        }

        .integration-setup-sprite {
          margin: 0 auto;
        }

        h4 {
          font-size: 18px;
          padding: 10px;
          color: #5e5e5e;
        }
      }

      .modal-body {
        padding: 0;
        overflow-y: hidden;
      }

      .modal-footer {
        border: none;
        padding: 0;
      }
    }
  }

  &#connect-service-modal {
    .service-dialog {
      margin: 0;
      height: 100%;

      .service-content {
        border: none;
        box-shadow: none;
        height: 100%;

        .modal-body {
          border: 0;
          height: 100%;
          max-height: none;
          padding: 15px;

          a:not(.btn):hover {
            color: @uberRubineDark;
          }

          .field-row {
            margin-bottom: 15px;
          }

          .form-control {
            margin-bottom: 5px;
          }

          .input-error,
          .api-error {
            color: @red;
          }
        }
      }
    }
  }
}

#connect-service-modal,
#no-lists-modal {
  .connect-button,
  .no-lists-button {
    font-size: (@font-size-base + 8);    // 12 + 8
    padding: 10px 18px;
  }

  .move-chevron-down {
    position: relative;
    top: 2px;
  }
}

#enterprise-upgrade-modal,
#over-limit-modal {
  .lock-container {
    padding: 30px;
    text-align: center;
  }
}

.cancel-step {
  .pos-abs(auto, auto, 3px, 3px);             // T, R, B, L

  padding: 8px 12px;
  .border-radius(5px);

  a {
    color: lighten(@gray, 6.5%);            // #666

    &:hover {
      color: darken(@gray-darker, 0.5%);    // #212121
      text-decoration: underline;
    }
  }
}

#generate-username,
#generate-password,
#generate-company {
  margin-bottom: 15px;
}

.step-to-generate-container {
  text-align: center;

  p {
    margin-top: 20px;

    a {
      padding: 10px 18px;
    }
  }
}

.generate-api-key {
  > div {
    text-align: center;

    > div {
      display: inline-block;
      text-align: left;
      padding-top: 10px;

      > p {
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}

#edit-service-modal,
.edit-modal {
  .integration-sprite-container {
    text-align: center;

    .integration-setup-sprite {
      display: inline-block;
    }
  }

  .step-to-generate-container {
    margin-top: 15px;

    .generate-eloqua-key-cancel {
      margin-right: 15px;
    }
  }

  .note {
    display: inline-block;
    margin: 0;
  }

  .feature-lock {
    background: url("/img/feature_lock.png") no-repeat 5px 4px;
  }

  .marketo-note {
    display: inline-block;
    margin-top: 18px;
    margin-right: 60px;
    margin-bottom: 18px;
    margin-left: 60px;
  }

  .pardot-note {
    margin: 0 32px;
  }

  .field-row {
    .field-name {
      display: inline-block;
      width: 150px;
      text-align: right;
      margin-right: 20px;
      margin-top: 18px;

      &.oauth-edit-column {
        width: auto;
        max-width: 150px;
      }
    }

    .user-name {
      text-align: inherit;
    }

    .btn {
      margin-top: -5px;
    }

    input {
      display: inline-block;
    }

    input[type=text],
    input[type=password] {
      width: 50%;
    }

    &.field-header {
      font-weight: bold;
    }
  }

  .soap-info {
    background-color: @gray-lighter;
    margin-right: 60px;
    margin-left: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
    .border-radius(5px);

    .field-row-info {
      margin: 0;

      .field-name-info {
        font-weight: bolder;
        display: inline-block;
        width: 90px;
        text-align: right;
        margin-right: 22px;
        margin-top: 0;
      }

      .field-value-info {
        display: inline-block;
        width: 320px;
        text-align: left;
        margin-right: 0;
        margin-top: 0;
      }
    }
  }
}

.service-fields-modal {
  font-family: @roboto;

  .note {
    width: 330px;
    margin: 0 auto;
  }

  .select2-container {
    text-align: left;
  }

  .integration-setup-sprite {
    margin: 0 auto;
  }

  .modal-body h3 {
    text-align: center;
    margin: 30px 0 15px 0;
  }

  table.service-fields-display {
    font-family: @roboto;
    width: 100%;
    text-align: center;
    margin: 15px auto 0 auto;

    tr {
      padding: 7px;

      td,
      th {
        padding: 7px;
      }
    }

    tr:first-of-type {
      border-bottom: 1px solid #ccc;
    }

    tr:nth-child(odd) {
      background-color: #eee;
    }

    tr.existing {
      opacity: 0.5;
    }

    tr.missing {
      color: #b94a48;
    }

    tr:not(:first-child):hover {
      background: #aaa;
      color: #fff;
    }
  }

  .sf-note {
    text-align: center;
    padding: 20px 0 10px 0;
    font-weight: bold;

    .warning {
      text-transform: uppercase;
    }
  }

  .mismatched-type {
    color: #b94a48;
  }

  h3.uberflip-fields {
    margin-bottom: 0px;
  }
}

.no-line-break {
  white-space: nowrap;
}
