// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.layout-bs3 {
  .autoLinksManage {
    .content {
      border: 1px solid darken(@gray-lighter, 13.5%);   // #ccc

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          padding: 0;
          margin: 0;
          border-bottom: 1px solid @white;

          a {
            padding: 3px;
            text-decoration: none;
          }

          &:hover {
            background-color: @gray-lighter;
          }

          &.progress {
            a {
              margin-left: 24px;
            }
          }
        }
      }

      .content_scroll {
        padding: 5px;
        height: 100px;
        overflow-y: auto;
      }

      .content_footer {
        padding: 4px;
        background: #f9f9f9;
        border-top: 1px solid darken(@gray-lighter, 13.5%);   // #ccc
        font-size: @font-size-base - 1.5;  // ~10.5px
      }

      .content_links {
        border: 1px solid darken(@gray-lighter, 13.5%);   // #ccc
        height: auto;
      }
    }
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Only used on Auto Links pages:

  .or {
    font-weight: bold;
    font-size: 150%;
    margin: 45px 20px 0 20px;
  }
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  #autoLinksExistingJobs {
    float: left;
    clear: none;
    width: 48%;
  }

  #autoLinksJobTasks {
    display: none;
    float: right;
    clear: none;
    width: 48%;
  }

  #autoLinksDuplicateJobs {
    display: none;
    float: left;
    clear: none;
  }

  #autoLinksTaskLinks {
    display: none;
  }

  #autoLinksBulkUpdateLinks {
    padding: 8px;
    margin-top: 0;
    background: @gray-lighter;
    border-top: 1px solid darken(@gray-lighter, 13.5%);   // #ccc
    color: lighten(@gray-light, 13.2%);
    font-size: @font-size-base - 1;   // ~11px
    text-align: right;

    strong {
      color: lighten(@gray, 6.5%);   // #666
      margin-right: 12px;
    }
  }

  #ftpSchedulerAutoLinksSettings,
  #issueAutoLinkSettings {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}
