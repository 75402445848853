// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.user-avatar {
  position: relative;
  .square(130px);

  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e6e7e8;
  .border-radius(50%);

  .avatar-text {
    position: relative;
    .square(130px);

    line-height: 130px;
    color: @white;
    font-size: 32px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
  }

  .add-user-text {
    font-family: Arial;
    font-weight: 700;
    font-size: 60px;

    &.avatar-add-member {
      font-weight: 500;
      font-size: 70px;
    }

    &.avatar-remove-member {
      font-weight: 500;
      font-size: 70px;
    }
  }

  .avatar-badge {
    .pos-abs(8px, 8px);     // T, R, B, L
  }

  .avatar-progress {
    display: none;
    .pos-abs(0, auto, auto, 0);
    .square(130px);

    background: transparent url("/img/loading.gif") no-repeat center;
    background-size: 40% auto;
  }

  .avatar-button-container {
    .square(130px);
    .border-radius(50%);

    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    .transition(all 200ms ease-in-out);

    &:hover,
    &.no-avatar {
      opacity: 1;
    }

    .avatar-buttons {
      .pos-abs(50%);

      width: 100%;
      margin-top: -15px;
      text-align: center;

      > a {
        display: inline-block;
        width: 35px;
        line-height: 30px;
        background: @uberBlueLight;
        border: 2px solid transparent;
        .border-radius(50%);
        .transition(all 200ms ease-in-out);

        &:hover {
          color: @white;
          background: @uberBlue;
          border-color: @white;
        }

        &.remove-avatar-button {
          margin-left: 20px;
        }
      }

      .file-upload-input-wrapper {
        display: none;
      }
    }

    &.no-avatar {
      background: transparent;
    }
  }

  &.user-avatar-small {
    .square(50px);

    margin: 0;
    background-size: cover;

    .avatar-text {
      .square(50px);

      line-height: 50px;
      font-size: 14px;
    }
  }

  &.user-avatar-medium {
    .square(75px);

    margin: auto;

    .avatar-text {
      .square(75px);

      line-height: 75px;
      font-size: 22px;
    }
  }

  &.user-avatar-smaller,
  &.user-avatar-default {
    .square(30px);

    margin: 0;
    background-size: 30px 30px;

    .avatar-text {
      .square(30px);

      line-height: 30px;
      font-size: 14px;
    }
  }

  &.user-avatar-tiny {
    position: absolute;
    display: block;
    .square(20px);

    background-size: 20px 20px;

    .avatar-text {
      .square(20px);

      line-height: 21px;
      font-size: 8px;
    }
  }

  &:hover {
    background-color: #d1d2d4;
  }

  &.hover {
    box-shadow: 0 0 8px 1px @uberBlueLight;
  }

  &.inactive-hover {
    box-shadow: 0 0 8px 1px @uberGrayLight;
  }
}

// master page account avatar

.nav {
  .dropdown {
    span.user-avatar-wrapper {
      > span {
        margin-left: 25px;
      }
    }
  }
}
