// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.order-modal {
  label {
    cursor: pointer;
  }

  input[type='checkbox'] {
    margin: 0;
    top: -1px;
    position: relative;
    vertical-align: text-bottom;
  }

  .form-group-domain-name {
    .form-group-row {
      margin-left: 3px;
      margin-right: 3px;
    }

    .domain-protocol-label {
      float: left;
      background: @grayLighter;
      border: 1px solid @uberGrayLighter;
      border-right: 0;
      display: inline-block;
      vertical-align: middle;
      line-height: @line-height-base;
      margin: 0;
      padding: 6px 8px;
      height: 32px;
      border-radius: @border-radius-base 0 0 @border-radius-base;
      color: @fontColorMedium;
      font-size: @font-size-medium;
      font-weight: normal;
    }

    input[type='text'] {
      float: left;
      border-radius: 0 @border-radius-base @border-radius-base 0;
    }

    .secondary-domain-group {
      float: left;
      display: inline-block;
      margin: 10px 0 0 8px;
      color: @fontColorMedium;
      font-size: @font-size-base;
      font-weight: normal;

      input {
        margin-right: 4px;
      }
    }

    .primary-domain-group {
      margin-top: 0.8em;
      margin-bottom: 0;
      font-weight: normal;

      select {
        margin-left: 6px;
        min-width: 320px;
        max-width: 440px;
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
}

.order-modal,
.change-modal {
  .terms-label {
    font-size: 12px;
    font-weight: normal;

    a {
      text-decoration: underline;
    }
  }

  .cname-info,
  .ssl-info {
    margin: 0 -15px 0 -15px;
    font-size: 14px;
    padding: 16px 18px;

    div {
      display: inline-block;
      width: 48%;

      input {
        display: inline;
      }

      label {
        display: inline;
        font-weight: normal;
      }
    }
  }

  .ssl-info {
    padding-top: 0;
    padding-bottom: 0;
  }

  form label {
    margin-top: 1.4em;
  }

  *[data-ssl-info="true"] {
    display: none;
  }

  .note {
    margin: 1em 0;
  }

  .ssl-step-4 {
    padding-left: 0;
    font-weight: inherit;
    letter-spacing: inherit;
    color: inherit;
  }

  .cname-value {
    font-weight: bold;
  }

  .domain-url {
    font-size: 18px;
    color: #999;
  }
}

.account_body {
  .domain-manage-buttons {
    display: inline-block;
    width: 343px;
  }

  .domain-manage-buttons-reseller {
    display: inline-block;
    width: 343px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .account_body {
    .domain-manage-buttons-reseller,
    .domain-manage-buttons {
      a span {
        &:not(.halflings) {
          display: none;
        }
      }

      a.toggle-ssl > span {
        &:not(.halflings) {
          display: block;
        }
      }
    }

    .domain-manage-buttons-reseller {
      &:not(.ssl-toggle) {
        width: 151px;
      }

      width: 227px;
    }

    .domain-manage-buttons {
      &:not(.ssl-toggle) {
        width: 151px;
      }

      width: 227px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .account_body {
    .domain-manage-buttons-reseller,
    .domain-manage-buttons {
      a span {
        &:not(.halflings) {
          display: none;
        }
      }

      a.toggle-ssl > span {
        &:not(.halflings) {
          display: block;
        }
      }
    }

    .domain-manage-buttons-reseller {
      &:not(.ssl-toggle) {
        display: table-cell;
        width: 60px;
      }

      width: 113px;
    }

    .domain-manage-buttons {
      &:not(.ssl-toggle) {
        display: table-cell;
        width: 60px;
      }

      width: 113px;
    }
  }
}

.layout-bs3-uberflip {
  /* custom domains */

  .custom-domains-ad {
    margin-bottom: 20px;

    h2 {
      margin: 8px 0;
      font-size: @font-size-base + 4;   // ~16px
      color: @uberPink;
    }

    h3 {
      font-size: @font-size-base + 12;   // ~24px
      margin: 30px 0;
    }

    span {
      margin: 0 8px;
    }

    > .screens {
      display: block;
      .size(100%, 306px);

      overflow: hidden;

      > .screen-flipbooks {
        display: inline-block;
        position: relative;
        .size(432px, 306px);

        background: transparent url("/img/domains/customdomains-flipbooks.png") no-repeat bottom left;
        z-index: 1;
      }

      > .screen-hubs {
        display: inline-block;
        position: relative;
        .size(496px, 306px);

        margin-left: -34px;
        background: transparent url("/img/domains/customdomains-hubs.png") no-repeat bottom left;
        z-index: 0;
      }
    }
  }
}
