// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

// ----------------------------------------------------------------------------
// Old layout v1 Style Overrides when Page contains Shared Toolbar
// These need to be merged with has-sidemenu elsewhere
// ----------------------------------------------------------------------------

.has-sidemenu {
  #content-container {
    width: 100%;
    min-height: 100%;
  }

  #content {
    padding: 0;
    margin: 0;
    min-height: 100%;
  }

  #footer { margin-top: 0; }

  #footer {
    position: relative;
    margin-left: @sidemenuWidthOpened;

    .container.full-page {
      width: 100%;
      min-width: 830px;
    }

    small,
    .nav-pills > li { font-size: @font-size-base; }
  }

  #flash_message,
  #flash_message_ajax,
  #flash_mini,
  .flash_message {
    margin: 0;
    margin-left: @sidemenuWidthOpened;
    border-width: 0 0 1px 0;
    .border-radius(0);
  }
}

// ----------------------------------------------------------------------------
// Layout v2 Styles
// ----------------------------------------------------------------------------

/* TILE COMPONENT */

.tile p {
  line-height: 1em;
}

#content-title,
#content-subtitle {
  font-weight: bold;
}

.squished-and-centered {
  .account_body {
    float: none;
    width: auto;
    max-width: 900px;
    margin: 0 auto;
  }

  .page-header .container.full-page h1 {
    text-align: center;
    font-size: 25px;
  }
}

.alert {
  p,
  li {
    color: inherit;
  }
}

// Common Styles
.left {
  float: left;
  clear: none;
}

.right {
  float: right;
  clear: none;
}

.rounded-tl { .border-radius(10px 0 0 0); }
.rounded-tr { .border-radius(0 10px 0 0); }
.rounded-bl { .border-radius(0 0 0 10px); }
.rounded-br { .border-radius(0 0 10px 0); }
.rounded-top { .border-radius(10px 10px 0 0); }
.rounded-btm { .border-radius(0 0 10px 10px); }
.rounded-left { .border-radius(10px 0 0 10px); }
.rounded-right { .border-radius(0 10px 10px 0); }

.hilite {
  background: #fff8ce;
  padding: 0px 4px;
}

// Standardized Box Element

.box {
  display: block;
  padding: 5px 10px;
  margin: 0 0 30px 0;
  color: @gray-dark;
  background-color: @white;
  .box-sizing(border-box);
  .clearfix;

  &.bordered {
    border: 1px solid darken(@gray-lighter, 13.5%);
  }

  &.shaded {
    background-color: darken(@gray-lighter, 6.7%);
  }

  &.rounded {
    .border-radius(3px);
  }

  &.no-margin {
    margin: 0;
  }

  &.tuck-over {
    margin-bottom: -2px;
  }

  &.tuck-under {
    margin-top: -2px;
  }

  &.lighter {
    color: @gray-light;

    &.bordered {
      border: 1px solid @gray-lighter;
    }

    &.shaded {
      background-color: #f9f9f9;
    }
  }

  &.light {
    color: @gray;

    &.bordered {
      border: 1px solid darken(@gray-lighter, 6.7%);
    }

    &.shaded {
      background-color: @gray-lighter;
    }
  }

  &.dark {
    color: @white;

    &.bordered {
      border: 1px solid lighten(@gray-light, 6.5%);
    }

    &.shaded {
      background-color: lighten(@gray-lighter, 20%);
    }
  }

  &.darker {
    color: @white;

    &.bordered {
      border: 1px solid @gray-light;
    }

    &.shaded {
      background-color: lighten(@gray-light, 13.2%);
    }
  }
}

.accordion-group {
  > .add-placement {
    padding: 8px 15px;
    border-top: 1px solid #e5e5e5;
    background: darken(@white, 2.5%);    // #f9f9f9

    > .pull-left {
      font-size: (@font-size-base + 3);  // 12 + 3
      color: @gray;
      line-height: (@line-height-computed + 8);     // 20 + 8
      font-weight: bold;
    }

    > .pull-right {
      > select {
        max-width: 300px;
      }
    }
  }
}

.accordion-toggle {
  .btn-close {
    opacity: 0.5;
  }

  &:hover {
    .btn-close {
      opacity: 1;
    }
  }
}

// ----------------------------------------------------------------------------
// Multi Selectbox

.select-container {
  div[data-id] {
    > .halflings {
      color: #999;
    }
  }
}

.no-search .select2-search {
  display: none;
}

// ----------------------------------------------------------------------------
// Fancy Selectbox

.hubs_font_select_wrapper {
  position: relative;
  .clearfix;

  .hubs_font_select {
    position: absolute;
    width: 360px;
    padding: 0;
    background: @input-bg url("/img/hubs_pageheader_selectarrow.png") no-repeat right 12px;
    border: 1px solid @input-border;
    .box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
    .transition(~"border linear .2s, box-shadow linear .2s");

    cursor: pointer;

    .hubs_font_option {
      display: none;
      font-size: 1.4em;
      padding: 6px 8px;
      font-weight: normal;

      &.active {
        display: block;
        font-weight: bold;
      }
    }

    .hubs_font_close {
      display: none;
      position: relative;
      float: right;
      .square(16px);

      margin-top: -10px;
      margin-right: -10px;
      color: @white;
      font-size: @font-size-base;
      line-height: (@line-height-computed - 4); // 20 - 4
      text-align: center;
      background: lighten(@gray-light, 13.2%);
      border: 1px solid @white;
      .border-radius(50%);

      &:hover {
        background: lighten(@gray, 6.5%);  // #666
      }
    }

    &.active {
      background: @white;
      border-color: rgba(82, 168, 236, 0.8);
      .box-shadow(~"inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)");

      outline: 0;
      outline: thin dotted \9; /* IE6-9 */

      .hubs_font_option {
        display: block;
        padding-left: 26px;

        &.active {
          background: transparent url("/img/accept.png") no-repeat 4px center;
        }

        &:hover {
          color: @white;
          background-color: @uberBlue;
        }
      }

      .hubs_font_close { display: block; }
    }
  }
}

// ----------------------------------------------------------------------------
// Block-Icon Navigation

.nav-block-icon {
  .nav-block-icon-width(1);

  margin: 0 auto 20px auto;
  .clearfix;

  > li {
    position: relative;
    float: left;
    margin-right: @navBlockIconMargin;

    &:last-child { margin-right: 0; }

    a {
      display: block;
      .square(@navBlockIconSize);

      background-color: @uberGrayLighter;

      span {
        display: none;
      }

      &:hover {
        background-color: @uberGrayLight;
      }
    }

    &.active {
      a {
        background-color: @uberGrayLight;
      }

      &:after {
        display: block;
        .pos-abs(auto, auto, -24px, 28px);      // T, R, B, L
        .triangle_bottom(@uberGrayLight, 12px);

        content: "";
      }
    }
  }
}

// ----------------------------------------------------------------------------
// Common Elements

.upload-button-container {
  position: relative;
}

.file-uploader {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
}

.file-uploader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.uploaded-image {
  max-width: 340px;
}

.loading-sprite {
  .square(126px);

  margin: auto;
  background: url("/img/loading.gif");
}

.loading-text {
  margin: 0 auto;
  color: darken(@gray-light, 10%);  // #808080
  font-size: 1.4em;
  text-align: center;
  padding: 20px;
}

.badge {
  padding: 1px 8px;
  font-size: @font-size-base;
  line-height: @line-height-computed;
  text-shadow: none;
  background-color: lighten(@gray-light, 13.2%);    // #999

  &.badge-warning {
    &:hover {
      background-color: @uberOrange;   // was: #c67605;
    }
  }

  // badge counters

  &.mini-counter {
    float: none;
    padding: 0px 5px;
    margin-top: -4px;
    margin-left: 5px;
    font-size: 9px;
    font-weight: normal;
    color: lighten(@gray, 6.5%);  // #666
    background: rgba(0, 0, 0, 0.05);
    .border-radius(1px);
  }
}

.page-header {
  h1 {
    .badge {
      .pos-rel(-3px);     // T, R, B, L
    }
  }
}

// used with block modal preview for
// account edit when managing a user

.block-modal-ad-left {
  width: 50%;
  float: left;
}

.block-modal-ad-right {
  width: 50%;
  float: right;
}

// ----------------------------------------------------------------------------
// Admins Layout Sections
//  - All admin/* pages use the following layout elements
//

.command-section {
  .clearfix;

  form {
    width: 100%;
  }
}

.display-section {
  .clearfix;
}

.command-section,
.display-section {
  .visible-section {
    margin-bottom: 20px;
    .clearfix;

    input[name="q"] {
      width: 300px;
    }
  }
}

.content-box-wrapper {
  margin-bottom: 20px;
  .clearfix;
}

.content-box {
  padding: 12px;
  background: @white;
  border: 1px solid #eee;
  .clearfix;

  table {
    width: 100%;
  }
}

.content-box-header {
  padding: 10px;
  border: 1px solid #eee;
  border-bottom: 0;
  background: #f9f9f9;
  color: #454545;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px @white;
  .clearfix;

  .lightweight {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }
}

// ----------------------------------------------------------------------------
// Management Container
//   - used in all hubs/* pages
//

.mgmt-container {
  position: relative;
  height: 100%;

  .content-container {
    height: 100%;
    min-height: 75vh;
    padding: 38px 20px;

    // When using the new React pageHeader, over-ride the .content-container padding
    &.ufr-header {
      padding: 0 20px 50px 20px;
    }

    // --------------------------------------------------------------------
    // Page Header
    // TODO: Move to header.less

    .page-header {
      h1 {
        > span.color-box-service-icon {
          margin: -4px 0 0 0;
        }
      }
    }

    // --------------------------------------------------------------------
    // Inline-Edit Inputs

    .inline_edit {
      line-height: 30px;

      .inline_edit_input,
      .inline_edit_save,
      .inline_edit_count,
      > span.note {
        display: none;
      }

      .inline_edit_edit {
        padding: 0px 8px;

        a {
          color: lighten(@gray-light, 6.5%);          // #888
          font-size: @font-size-base;                 // ~13px
          line-height: @line-height-computed + 12;    // ~30px
          vertical-align: middle;

          &:hover {
            color: @gray-dark;    // #333
          }
        }
      }

      .inline_edit_count {
        padding-top: 2px;
      }

      .inline_edit_save {
        a {
          color: darken(@gray-light, 10%);  // #808080
          font-weight: bold;
        }
      }

      > span.note {
        position: relative;
        top: -3px;
        margin: 0;
        font-size: @font-size-base;
        text-transform: none;

        &:hover {
          background-color: transparent;
        }
      }

      .inline_edit_input {
        width: 300px;
      }
    }

    .hub-authors-container {
      max-width: 1000px;
      margin: auto;
    }

    .collection-author-controls {
      margin: 25px auto;
      max-width: 1000px;
    }
  }
}

.panel-container {
  margin-top: 25px;
  border: 1px solid darken(@gray-lighter, 13.5%);    // #ccc

  .panel-header {
    background-color: darken(@gray-lighter, 13.5%);    // #ccc
    padding: 5px;

    p {
      color: @white;
      font-size: @font-size-base + 2;     // ~14px
      margin-bottom: 0;
    }
  }

  .panel-body {
    padding: 10px;
    min-height: 300px;
    .clearfix;

    &.checkered {
      background: @white url("/img/page_editor/checkered.png") repeat;
    }
  }

  table.table.table-graytop {
    margin-bottom: 0;

    thead tr {
      th {
        background-color: darken(@gray-lighter, 13.5%);    // #ccc
      }
    }
  }
}

img {
  display: inline;
  border: medium none;
  margin: 0;
}

/**
 * Page Content
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

#content {
  padding: 20px;
  position: relative;
  clear: both;
  font-size: @font-size-base + 1; // ~13
  background-color: transparent;
}

.layout-iframe {
  font-family: @roboto;

  #content {
    margin: 0;
  }

  #content-container {
    min-height: 0;
    padding-top: 0;
  }
}

/* Content Titles */

#content-title,
#content-subtitle,
.content-title,
.content-subtitle {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: @font-size-base * 2; // ~24px
  line-height: 30px;
}

#content-subtitle,
.content-subtitle {
  font-size: @font-size-base + 8; // ~20px
  line-height: 24px;
  color: @uberGrayDarker;
}

#content-title,
#content-subtitle {
  span {
    color: @uberGrayLight;

    span {
      color: @uberGrayLight;
      font-weight: normal;
    }
  }
}

.content-heading {
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: @font-size-base + 4;   // 16px
  color: darken(@gray, 6.5%);     // #444

  span {
    color: @uberGrayDark;
    font-weight: normal;
  }
}

/* 2 column content */

#content-2column {
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  margin-bottom: 12px;
}

#content-2column-left {
  float: left;
  margin-right: 20px;
  clear: none;
  overflow: hidden;
}

div#content-2column-right {
  float: left;
  clear: none;
}

.content-border-left {
  padding-left: 20px;
  border-left: 1px solid @uberGrayLighter;
}

.content-border-right {
  padding-right: 20px;
  border-right: 1px solid @uberGrayLighter;
}

.content-section {
  padding: 20px;
}

/* Title Add/Edit */

#sub_required_fields_wrapper {
  float: left;
  clear: none;

  div {
    float: left;
    clear: none;
    padding: 5px 16px 5px 5px;
  }
}

#preview_issue {
  width: 50px;
  height: 30px;
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: -3px;
  border: 1px solid darken(@gray-lighter, 6.7%); // #ddd
  cursor: pointer;
  text-align: center;
  background: @whiteSmoke; // #f9f9f9
  .border-radius(3px);

  &:hover {
    border-color: darken(@gray-lighter, 13.5%); //#ccc;
    background: @white;
  }
}

div {
  &.hint {
    &.help {
      padding-left: 28px;
      margin-left: 0;
      background-color: #fffee9;
      background-position: 4px center;

      span {
        &.help {
          padding-left: 0;
          background: none;
        }
      }
    }
  }

  &.alert {
    span {
      &.help {
        margin-left: 0 !important;
        padding: 0px 15px !important;
        background-position: 4px center;

        a {
          margin-left: 10px;
        }
      }
    }

    .close {
      right: 0;
    }

    p {
      margin: 0;
    }
  }

  &.help_annotation_image {
    width: 334px;
    height: 271px;
    overflow: hidden;
    background: transparent url("/img/help/annotations_bookmarks.png") no-repeat top left;

    &.notes {
      background-image: url("/img/help/annotations_notes.png");
    }

    div {
      &.caption {
        height: 56px;
        padding: 8px;
        margin-top: 215px;
        background: transparent url("/img/black_80.png") repeat;
        color: @white; //#fff;
        font-size: @font-size-base -1; //11px;
        font-weight: normal;
        line-height: 14px;
      }
    }
  }
}

span {
  &.blog {
    margin-right: 12px;
    padding: 3px 6px;
    background: darken(@gray-lighter, 6.7%); // #ddd;
    color: darken(@gray-darker, 7%); // #111;
    font-size: 95%;
  }

  &.grey {
    padding-left: 12px;
    color: lighten(@gray-light, 6.5%); // #888;
    font-style: italic;
  }

  &.strikeout {
    color: lighten(@gray-light, 6.5%); //#888;
    text-decoration: line-through;
  }
}

/* ~~~ FAQ ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.faq-title {
  font-size: @font-size-base + 7; //19px;
  font-weight: bold;
  color: #7ba920;
}

.note {
  padding: 5px 6px 5px 24px;
  margin: 0.3em 0;
  display: block;
  .border-radius(3px);

  color: @uberGrayLight;
  font-size: 1em;
  line-height: 1.4em;
  background: transparent url("/img/note.gif") no-repeat scroll 5px 8px;

  .cs-help {
    color: @uberPink;
  }

  &:hover {
    color: @uberGrayDark;
    background-color: @white;

    .cs-help {
      color: @uberPinkDark;
    }
  }

  &.help-block {
    margin-bottom: 10px;
  }
}

.hint {
  padding: 3px 5px;
  margin-bottom: 12px;
  border: 1px solid #fff5c1;
  background-color: lightyellow;
  color: #454545;
}

.help {
  margin-left: 5px;
  padding-left: 20px;
  padding-bottom: 2px;
  background: url("/img/light_bulb.png") no-repeat left center;

  a {
    &:link {
      color: @uberGrayDarker;
      text-transform: uppercase;
      font-size: 90%;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.icon {
  float: left;

  a {
    display: block;
    overflow: hidden;
    border: 2px solid darken(@gray-lighter, 13.5%); //#ccc;

    &:hover {
      border-color: @uberBlue;
    }
  }
}

#page_thumbs {
  a {
    img {
      border: 2px solid @gray-lighter;
      margin-bottom: 4px;
    }

    &:hover {
      img {
        border: 2px solid #d62627;
      }
    }
  }
}

/* Issue Settings/Options */

.cover_wrapper {
  .cover {
    width: 120px;
    border: 1px solid darken(@gray-lighter, 13.5%); //#ccc;
    overflow: hidden;
    background: transparent no-repeat top left;
    .box-shadow(1px 2px 4px rgba(0, 0, 0, 0.2));
    .transition(opacity 0.2s linear);

    cursor: pointer;

    &.current {
      border-color: @uberGrayDarker;
      .box-shadow(0px 0px 12px @uberGrayDarker);

      .marker,
      .bottom_marker {
        background-color: @gray-darker;
      }
    }

    &.blur {
      opacity: 0.6;
    }

    div.info {
      text-align: center;

      a {
        &:link,
        &:visited {
          padding: 6px 12px;
          border: 1px solid @white;
          font-size: @font-size-base + 1; //13px;
          color: @white;
          background: @uberGrayDark;
          background-color: rgba(0, 0, 0, 0.6);
        }

        &:hover {
          background: @black;
        }
      }
    }

    &.status_PU,
    &.status_PN,
    &.status_PS,
    &.status_G,
    &.status_GF,
    &.status_P {
      height: 160px;
    }

    &.status_PU,
    &.status_GF,
    &.status_PS,
    &.status_PN {
      div.info {
        padding-top: 60px;
        background: transparent url("/img/warning.png") no-repeat center center;
        font-size: @font-size-base - 1; //11px
      }
    }

    &.status_P,
    &.status_G {
      div.info {
        padding-top: 20px;
        font-size: @font-size-base - 1; //11px;
      }
    }

    &.default {
      .marker {
        padding-left: 24px;
        background-image: url("/img/accept.png");
      }
    }

    &.status_I {
      .marker {
        padding-left: 24px;
        background-image: url("/img/inactive.png");
      }
    }

    &.status_PP {
      .marker {
        padding-left: 24px;
        background-image: url("/img/clock.png");
      }
    }
  }

  .marker {
    float: right;
    margin: 6px;
    padding: 2px 5px;
    background: rgba(0, 0, 0, 0.4) no-repeat 3px 3px;
    color: @white;
    font-size: @font-size-base - 2; //10px
  }

  .bottom_marker {
    float: left;
    margin-left: 6px;
    padding: 3px 3px;
    background: transparent;

    img {
      &.img {
        margin-right: 0;
      }
    }
  }

  .progress {
    width: 90px;
    margin-left: 12px;
    margin-bottom: 8px;
    margin-top: 18px;
    text-align: left;
  }
}

/* Shared element, used within account section, may need refactoring to figure where to actually put this styling */

ul {
  &.issue_table_links {
    margin: 0 !important;
    min-width: 270px;
    float: left;
    clear: none;
    list-style: none;

    li {
      margin: 0 !important;
      float: left;
      clear: none;

      a {
        height: 16px;
        margin-right: 3px;
        padding: 2px 4px 3px 4px;
        border: 1px solid darken(@gray-lighter, 6.7%); //#ddd;
        float: left;
        clear: none;
        background: @whiteSmoke;
        text-decoration: none;
        color: @uberGrayDark; //#454545;
        font-size: @font-size-base - 1; //11px;
        line-height: 16px;
        overflow: hidden;

        &:hover,
        &.current {
          border-color: lighten(@gray-light, 6.5%); //#888;
          background-color: lighten(@gray-light, 6.5%); //#888;
          color: @white;
          text-decoration: none;
        }

        &.arrow_down {
          padding-right: 16px;
          background: url("/img/arrow_down.png") no-repeat right;
        }

        img {
          margin: 0;
          border: 0;
          vertical-align: middle;
        }
      }
    }
  }
}

/* Shared among titles & docs */

.listOptions {
  padding: 6px 0;
  margin: 4px 0 0 -1px;
  max-width: 320px;
  max-height: 240px;
  min-width: 160px;
  border-color: lighten(@gray-light, 13.2%);
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  background-color: @white;
  .box-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  .background-clip(padding-box);

  font-size: @font-size-base - 1; //11px;
  overflow-x: hidden;
  overflow-y: auto;

  h4 {
    position: fixed;
    width: 233px;
    margin: -6px 0 0 0;
    border-bottom: 1px solid darken(@gray-lighter, 13.5%); //#ccc;
    text-align: center;
    line-height: 25px;
    background: @white;
  }

  ul {
    padding: 0;
    margin: 30px 0 0 0;
    list-style: none;

    li {
      display: block;
      margin: 0 0 0.3em 0;
      float: none;
      line-height: 18px;
      color: @gray;

      a {
        display: block;
        padding: 4px 10px;
        color: @gray; //#565656;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
        float: none;
        clear: both;
        font-weight: normal;
        line-height: 18px;
        text-decoration: none;

        &:hover {
          color: @white;
          background-color: @linkColor; //#08c;
          text-shadow: none;
        }

        img {
          margin-right: 4px;
          vertical-align: bottom;
        }
      }
    }
  }
}

.listOptions {
  &.large {
    min-width: 90px;
    font-size: 13px;
  }
}

.listOptions-h4-relative-override {
  h4 {
    position: relative;
  }
}

.guide-link-list {
  font-size: @font-size-base + 2; //14px;

  ul {
    margin: 0;
    list-style: none;

    li {
      margin: 0 0 0.3em 0;
      float: none;
      display: block;
      line-height: 18px;
      color: @gray;

      a {
        display: block;
        padding: 7px 10px;
        color: @gray; //#565656;
        background-color: transparent;
        float: none;
        clear: both;
        font-weight: normal;
        line-height: 18px;
        text-decoration: none;

        &:hover {
          color: @white;
          background-color: @uberBlueLight;
        }
      }
    }
  }
}

/* ~~~ Styles for PHP Elements ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.title_logo_preview_wrapper {
  padding: 0px 8px 8px;
  border: 1px dashed darken(@gray-lighter, 6.7%); //#ddd;
  float: left;
  clear: none;
  background-color: @fadeToHover;
}

.title_logo_preview {
  height: 30px;
  .border-radius(0 0 8px 8px);

  img {
    padding-top: 4px;
    padding-left: 8px;
    max-height: 22px;
    max-width: 140px;
  }
}

#preview_banner {
  &.title_logo_preview {
    height: 44px;

    img {
      padding-top: 8px;
    }
  }
}
