// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ***************************************************************************************

.table {
  td {
    border-top: none;
    text-align: left;
  }

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 0 none;
      }
    }
  }

  > thead {
    > tr {
      > th,
      > td {
        border-bottom: 1px solid @table-border-color;
      }
    }
  }

  thead {
    .no-sort,
    .sorting {
      &:after {
        display: none;
      }
    }

    .sorting_asc,
    .sorting_desc {
      &:after {
        color: lighten(@gray-light, 13.2%);    // #999
        margin-left: 5px;
        font-size: 10px;
      }
    }

    .sorting_asc {
      &:after {
        content: '\e253' !important;
      }
    }

    .sorting_desc {
      &:after {
        content: '\e252' !important;
      }
    }
  }

  &.table-borderless {
    th {
      border-top: none;
    }
  }

  &.clean {
    margin: 0 !important;

    tr,
    tr td,
    tr th {
      border: 0 none;
      text-align: left;
    }
  }

  &.draggable {
    th {
      cursor: move;
    }
  }

  &.connected-services {
    width: 100%;

    tr {
      td {
        width: 41%;
        vertical-align: middle;

        &:first-child {
          width: 30px;
        }

        &:last-child {
          a {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.title_issue_table {
  tr.current {
    td {
      background: lightyellow !important;
    }
  }
}

/* ~~~ Admin Tables ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

table {
  &.admin_table {
    width: 100%;
    font-size: @font-size-base + 1; //13px;

    td {
      input {
        padding: 2px;
        font-size: @font-size-base - 1.5; //10.5px;
      }

      .mini {
        padding: 4px 6px;
        font-size: 9.5px;
        background: @gray-lighter;
        color: @uberGrayDark;
      }
    }
  }

  &.list_table {
    font-size: @font-size-base - 1; //11px;

    &.vertical_table {
      border: 1px solid darken(@gray-lighter, 6.7%); //#ddd;
      border-bottom: 0;

      td {
        border-bottom-color: darken(@gray-lighter, 6.7%); //#ddd;
      }

      th {
        padding-right: 12px;
        border-right: 1px solid darken(@gray-lighter, 13.5%); //#ccc;
        text-align: right;
      }
    }
  }

  &.vertical {
    td,
    th {
      padding: 5px;
    }
  }

  &.admin_table,
  &.list_table,
  &.vertical {
    tr {
      &:hover {
        background-color: @fadeToHover;
      }

      a {
        cursor: pointer;

        &:hover {
          color: @uberBlueDark;
          background-color: @fadeToHover;
        }
      }
    }
  }

  &.admin_table,
  &.list_table {
    th {
      padding: 6px;
      border-bottom: 1px solid darken(@gray-lighter, 13.5%); //#ccc;
      background-color: @gray-lighter;
      font-weight: bold;
    }

    td {
      padding: 6px;
      border-bottom: 1px solid @gray-lighter;
    }
  }

  tr.totals {
    td {
      border-top: 1px solid darken(@gray-lighter, 13.5%); //#ccc;
      background: @whiteSmoke;
      font-weight: bold;
    }
  }

  &.title_options {
    td {
      padding: 2px 20px 4px 2px;
      text-align: left;
    }

    th {
      padding: 2px;
      background-color: @white;
    }
  }

  &.table {
    &.table-graytop {
      thead tr {
        th {
          background-color: @uberGrayLight;
          color: @white;
          font-size: @font-size-base + 2; //14px;
          font-weight: normal;
          padding: 12px;
        }
      }

      tbody tr {
        td {
          padding: 12px;
        }
      }
    }

    .table-striped {
      label {
        padding: 2px 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        font-size: 1px;
        line-height: 1px;
        cursor: pointer;

        input[type="checkbox"],
        input[type="radio"] {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &.clean {
    tr {
      th {
        text-align: left;
      }
    }
  }
}

tbody {
  tr {
    &.hilite_row,
    &.hilite_row td {
      background-color: #f1ffe4 !important;
    }
  }
}

/* found in FE subscribers which uses minimal layout */
table.tableSubscriptionOptions { font-size: 12px; }

table.tableSubscriptionOptions th {
  background: #eee;
  font-weight: bold;
  padding: 4px;
  text-align: left;
}

table.tableSubscriptionOptions td {
  border-bottom: 1px solid #eee;
  font-weight: normal;
  padding: 4px;
  text-align: left;
}

table.tableSubscriptionOptions td .mini {
  font-size: 9.5px;
  color: #666;
  padding: 2px 4px;
  background: #f5f5f5;
}

.layout-bs3-uberflip {
  table {
    &.admin_table,
    &.list_table,
    &.vertical {
      tr {
        &:hover {
          background-color: #f9f9f9;
        }
      }
    }
  }

  .title_issue_table {
    tr.current {
      td {
        color: @uberPink;
      }
    }
  }
}
