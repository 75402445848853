
.password-strength-container {

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Redefine mixin (Bootstrap 3 reverses order of parameters)
  .size(@height, @width) {
    width: @width;
    height: @height;
  }
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  width: 300px;
  margin-bottom: 20px;
  .clearfix;

  .password-strength-text {
    font-size: @baseFontSize;
    text-transform: uppercase;
  }

  .password-strength-meter {
    .size(10px, 100%);    // height, width

    background: @grayLighter;

    > div {
      .size(100%, 1%);    // height, width

      background: @grayLight;
      .border-radius(3px);
      .transition(~"width 250ms ease-in-out");
    }
  }
}
