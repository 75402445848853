// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) '../../../libs/bootstrap-3.4.1/less/variables.less';
@import (reference) '../../../libs/bootstrap-3.4.1/less/mixins.less';

// Shared Variables and Mixins
@import (reference) '../shared/_variables.less';
@import (reference) '../shared/_mixins.less';

// Backend-Specific Variables and Mixins
@import (reference) '_variables.less'; // Modify this for custom colors, font-sizes, etc
@import (reference) '_mixins.less';

// ***************************************************************************************

form {
  font-family: @roboto;
  padding: 0;
  margin: 0;
  clear: left;

  label {
    display: block;
    float: none;
    width: auto;
    margin: 1.2em 0 0.5em 0;
    color: @gray-light; //#777
    font-size: 15px;
    font-weight: bold;
    text-align: left;

    span {
      font-weight: normal;

      &.help {
        padding-left: 20px;
      }
    }

    &.form-checkbox {
      display: inline;
      vertical-align: middle;
      position: relative;
      font-size: 85%;
      color: @gray-dark;
    }

    &.inline {
      width: 120px;
      padding: 0;
      margin: 0;
      float: left;
      clear: none;
    }

    &.checkbox {
      padding-left: 18px;
    }
  }

  .input-box {
    width: 100%;
    margin-bottom: 20px;
    .clearfix;

    .input-box-header {
      .clearfix;

      label {
        float: left;
        margin: 0.5em 0;
      }

      .badge {
        float: right;
        padding: 0px 9px;
        margin-top: 8px;
      }
    }

    .input-box-body {
      input,
      textarea,
      select,
      .uneditable-input {
        width: 99%;
      }

      textarea {
        height: 4.5em;

        &.textarea-short {
          height: 2.3em;
        }

        &.textarea-long {
          height: 12em;
        }
      }
    }
  }

  input,
  textarea {
    &.input {
      margin: 0;
    }
  }

  .row-column {
    display: flex;

    div:nth-child(2) {
      margin-left: 20px;
    }
  }

  .row-centered {
    align-items: center;

    input {
      margin: 10px 10px 0;
    }

    label {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

input[readonly],
select[readonly],
textarea[readonly] {
  cursor: default;
}

label {
  font-size: 1.2em;
  line-height: 1;
  font-weight: normal;

  &.pull-left {
    float: left;
  }

  &.pull-right {
    float: right;
  }

  &.std-label {
    display: inline;
    width: auto;
    margin: -3px 8px 0 0;
    padding: 0;
    float: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;

    input[type='checkbox'],
    input[type='radio'] {
      display: inline;
      margin: 0;
      padding: 0;
    }

    span {
      color: inherit;
    }
  }

  small {
    font-size: 0.8em;
    font-weight: normal;
    font-style: italic;
  }

  &.small {
    font-size: @font-size-base;
  }

  &.unstyled {
    font-size: inherit;
    display: inline;
    width: auto;
    margin: auto;
    padding: initial;
  }
}

.label {
  font-size: @font-size-base - 1; // ~11px
  line-height: @line-height-computed - 5; // ~13px

  &.big {
    font-size: @font-size-base;
  }
}

img {
  max-width: none;
}

//---------------------------------------------------------------------------
// Input Overlay (similar to append/prepend but overlays icon overtop input)
// * usage: *
// <div class='input-overlay'>
//    <i class='icon-x icon-grey'></i><input type='text' ... >
// </div>
//---------------------------------------------------------------------------

.input-overlay {
  .icon-grey {
    margin-top: 3px;
    opacity: 0.3;
  }

  input[type='text'] {
    padding-left: 25px;
    margin-left: -20px;
  }
}

input[type='image'],
input[type='checkbox'],
input[type='radio'] {
  .box-shadow(none);
}

input[type='file'] {
  cursor: pointer;
}

.form-horizontal {
  .control-group {
    .control-group {
      margin-bottom: 5px;
    }
  }
}

.input-append {
  .add-on,
  .btn {
    margin-left: -4px;
  }
}

.form-large .label_prepend {
  padding: 7px 9px 9px 9px;
}

.input-append,
.input-prepend {
  .add-on {
    color: @gray-light; // #777
  }
}

.input-append input,
.input-append select,
.uneditable-input {
  .border-radius(3px 0 0 3px);
}

.input-append select {
  height: 28px;
  margin-right: 0;
}

.input-prepend {
  input {
    margin-left: 4px;
  }
}

.flat-ui {
  &.input-append input,
  &.input-append select,
  &.uneditable-input {
    border-right: 0;
    .border-radius(0);
    .box-shadow(none);
  }

  &.input-append .add-on,
  &.input-append .btn {
    border-left: 0;
    background: transparent;
    .border-radius(0);
  }

  &.input-prepend .add-on,
  &.input-prepend .btn {
    border-right: 0;
    background: transparent;
    .border-radius(0);
  }

  &.input-append,
  &.input-prepend {
    input:focus,
    textarea:focus {
      border-color: darken(@gray-lighter, 13.5%); // #ccc
    }
  }
}

.input-spacer {
  line-height: 1;
  padding: 0px 10px;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
}

.form-note {
  display: block;
  margin: 0.3em;
  padding: 3px;
  background: @whiteSmoke;
  color: @gray-light; //#777
  font-size: @font-size-base - 1;
}

.form-error,
.form-error-basic {
  margin-bottom: 1em;
  padding: 2px 0 4px 18px;
  color: @red;
  font-size: 85%;
  font-weight: bold;
  background: url('/img/flash_error.gif') no-repeat;
}

.input_error {
  border-color: @red;
}

.control-group-submit {
  margin-top: 20px;
}

/*
 * Large Forms
 */

.form-large {
  .inline {
    float: left;
    clear: none;
    padding-right: 5px;
  }

  input {
    &.field_error {
      .field_error {
        border: 1px solid #c00;
      }
    }
  }
}

select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  padding: 4px;
  margin-bottom: 0;
  font-size: @font-size-base + 4; // ~16px;

  &.cke_dialog_ui_input_text {
    height: auto;
  }

  // Re-hide hidden elements due to specificity

  &.hide {
    display: none;
  }
}

textarea.form-control {
  font-size: @font-size-base + 4; // ~16px;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 3px 0;
}

.checkbox,
.checkbox-inline {
  input[type='checkbox'] {
    margin-top: 1px;
  }

  .help-block {
    margin-left: 14px;
    margin-top: 0;
  }
}

input.datefield {
  width: 90px;
  font-size: @font-size-base - 1; // ~11px
}

select {
  width: auto;
  height: 28px;
  margin-right: 3px;
  font-size: @font-size-base + 2; // ~14px;
  line-height: 28px;
}

option {
  &.child-level {
    padding-left: 15px;
  }

  &.top-level {
    font-weight: bold;
  }

  &.plus {
    padding-left: 18px;
    background: #cf9 url('/img/plus_12.png') no-repeat 3px 3px;
    color: darkgreen;
    font-weight: bold;
  }
}

fieldset {
  margin: 1em 0pt;
  padding: 15px;
  border: 3px solid @whiteSmoke;
  clear: left;

  fieldset {
    legend {
      width: auto;
      font-size: 105%;
      font-weight: bold;
    }

    input {
      font-size: 100% !important;
    }
  }

  dl {
    margin: 0;
    padding: 0;
  }

  dt {
    width: 18em;
    padding: 0px 1em 0.5em 0;
    float: left;
    clear: left;
    text-align: right;
  }

  dd {
    padding: 0px 0 0.5em 0;
    font-weight: bold;
  }

  &.labels {
    label {
      display: block;
      width: 15em;
      padding: 0px 0.5em 0.2em 0;
      float: left;
      text-align: right;
    }
  }
}

legend {
  padding: 0pt 0.5em;
  color: @gray;
  font-size: 150%;
  font-weight: normal;
  line-height: 1;

  span {
    color: lighten(@gray-light, 13.2%);
    letter-spacing: 0pt;
  }
}

.label_append,
.label_prepend {
  margin-left: -4px;
  margin-top: 1px;
  padding: 5px 5px 7px 5px;
  font-weight: normal;
  font-size: @font-size-base; // 12px
  color: @uberGrayLight;
  text-align: center;
  text-shadow: 0 1px 0 @white;
  background-color: @whiteSmoke;
  border: 1px solid darken(@gray-lighter, 13.5%); // #ccc
  .border-radius(0 3px 3px 0);
}

.label_prepend {
  margin-left: 0;
  margin-right: -2px;
  margin-top: 1px;
  .border-radius(3px 0 0 3px);
}

.no-user-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;

  input[type='text'],
  textarea {
    -webkit-touch-callout: inherit;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

span.label.label-filter {
  margin-left: 14px;
  margin-right: -7px;
  padding: 2px 10px 2px 6px;
  border: 1px solid darken(@gray-lighter, 6.7%); // #ddd
  background: darken(@white, 2.5%); // #f9f9f9
  color: lighten(@gray-light, 6.5%); // #888
  font-weight: normal;
  text-shadow: none;
}

.select-btn {
  display: inline-block;
  height: 22px;
  vertical-align: middle;

  .active-state-mixin {
    border-color: #aaa;
    background-color: @white;
    color: @gray-darker;

    .elliptic {
      color: @gray-darker;
    }
  }

  .btn.btn-mini.action-dropdown {
    width: 200px;
    padding: 2px 5px;
    text-align: left;
    line-height: 12px;
    vertical-align: middle;

    .elliptic {
      display: inline-block;
      .size(185px, 14px);

      line-height: @line-height-computed - 3; // ~15px
      padding: 0;
      color: @gray-dark;
      .text-overflow();
    }

    &.short {
      width: 132px;

      .elliptic {
        width: 120px;
      }
    }

    &:hover {
      .active-state-mixin;
    }
  }

  &.open {
    .btn.dropdown-toggle {
      .active-state-mixin;
    }
  }
}
