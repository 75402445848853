// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

// Page Header

.page-header {
  padding: 10px 0 20px 0;
  margin: 0 auto;
  background-color: transparent;
  border: none;

  h1 {
    padding: 15px 0 10px 0;
    margin: 0;
    font-size: @font-size-base + 14;   // 26px
    font-weight: 400;
    line-height: 45px;

    a {
      color: @uberGrayDarker;

      &:link,
      &:visited {
        color: @uberGrayDarker;
        text-decoration: none;
      }

      &:hover {
        color: @uberGrayDarker;
        text-decoration: underline;
      }
    }

    .inline_edit {
      .inline_edit_edit {
        i { margin-top: 10px; }
      }
    }
  }

  p {
    margin: 0;
    color: lighten(@gray-light, 6.5%);  // #888
    font-size: @font-size-medium;       // ~13px
    line-height: 22px;
  }

  .pagination {
    height: 32px;
    margin: 8px 0;

    a {
      padding: 0px 14px;
      color: @uberGrayDark;
      line-height: 34px;
      background: @white;
      background: rgba(255, 255, 255, 0.8);

      &:hover {
        color: @gray-darker;
        background: @white;
        .box-shadow(inset 0 5px 10px rgba(220, 220, 220, 0.2));

        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5dede', endColorstr='#ffffff', GradientType=0);
      }
    }

    .active {
      a {
        color: @gray-darker;
        background: @white;
        .box-shadow(inset 0 5px 10px rgba(220, 220, 220, 0.2));

        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5dede', endColorstr='#ffffff', GradientType=0);
      }
    }

    .disabled {
      a,
      a:hover {
        background-color: transparent;
        color: @brandingBackground; // original -> #bfbfbf
      }
    }
  }
}
