// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ***************************************************************************************

/**
 * Get rid of hideous outlines
 */
* :focus { outline: 0; }
:-moz-any-link:focus { outline: none; }
input::-moz-focus-inner { border: 0; }

a:hover,
a:active,
a:focus {
  outline: 0;
}

object,
embed {
  outline: 0;
}

/**
 * Common Utility Styles
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

.nobold {
  font-weight: normal;
}

/* for any editing preview - a checkered background a la photoshop */
.checkered {
  background: transparent url("/img/page_editor/checkered.png") repeat;
  border: 1px solid #ddd;
}

.expired { background: #c11b17; }

.utilDelim {
  padding-left: 5px;
  padding-right: 5px;
}

/* rounded corners */

.rounded4 {
  .border-radius(4px);
}

.rounded6 {
  .border-radius(6px);
}

.rounded8 {
  .border-radius(8px);
}

/* transition helpers */

.transition-all {
  .transition(all .4s ease-in-out);
}

.highlight {
  background-color: #fff5c1;
  font-weight: bolder;
  font-size: 105%;
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 999999;
}

.breadcrumb {
  height: 25px;
  margin-top: 10px;
  border-bottom: 1px solid darken(@gray-lighter, 6.7%); // #ddd
  font-size: 105%;
}

.geoEntryEdit {
  display: none;
}

td {
  &.geoEntryEdit {
    img {
      width: 0;
    }
  }

  &:hover {
    .geoNameEdit {
      img {
        width: auto;
      }
    }
  }
}

td:hover.geoNameEdit {
  img {
    width: auto;
  }
}

.ajaxLoadingEmbed {
  width: 100px;
  margin: 0 auto;
  font-weight: bold;
}

.report_footer {
  padding: 4px;
  border: 1px solid darken(@gray-lighter, 6.7%); // #ddd
  background: @whiteSmoke;

  div {
    padding: 4px;
    font-size: @font-size-base + 3; // 15px
  }
}

.report_header {
  margin-top: 20px;
  padding: 6px;
  border-bottom: 1px solid lighten(@gray-light, 20%); // #aaa
  background: @fadeToGray;
  color: @uberGrayDarker;
  font-weight: bold;
}

div#faq {
  .question {
    font-size: @font-size-base + 5; // 17px
    font-weight: bold;
    color: @uberBlue; // #3698b5
  }

  .answer {
    margin-bottom: 25px;
    font-size: @font-size-base + 3; // 15px

    a {
      color: #212121;
    }
  }
}

div {
  &.more-info {
    text-align: center;

    a {
      display: block;
      padding: 3px 12px;
      height: 20px;
      color: @white;
      cursor: pointer;
      line-height: 20px;
      text-decoration: none;
      font-size: @font-size-base - 0.5; // 11.5
      font-weight: bold;

      &:hover {
        text-decoration: none;
        color: @white;
      }
    }
  }
}

.numOfMonths {
  width: 40px !important;
}

a {
  &.selected,
  &.selected:hover {
    background: #666;
    color: #fff;
  }

  &.info {
    cursor: help;
    border-bottom: 1px dashed lighten(@gray-light, 6.5%); //#888
  }
}

.text-nowrap {
  .text-overflow();
}

.checkboxes {
  font-size: @font-size-base - 1; //11px;
  line-height: 40px;

  span {
    &.spacer {
      padding: 0px 12px;
      color: lighten(@gray-light, 13.2%); // #999
    }
  }

  input,
  select {
    font-size: @font-size-base - 1; //11px;
    vertical-align: middle;
  }

  input[type="text"] {
    max-width: 120px;
  }
}

.georgia {
  font-weight: normal;
  font-style: italic;
  font-family: georgia;
  text-transform: lowercase;
}

.mini_edit {
  &:hover {
    img {
      width: auto;
    }
  }

  img {
    width: 0;
  }

  input {
    &.btn-save,
    &.btn-cancel {
      display: inline-block;
      width: auto;
      height: auto;
      margin: 0;
      padding: 2px 5px;
      border: 0 none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    &.input {
      font-size: @font-size-base; // 12px;
      margin: 0;
    }
  }
}

/**
 * Custom Control Styles / Page-Specific Styles
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

/* ~~~ File Input Display ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.file-input-bg {
  margin: 0;
  position: relative;
  overflow: hidden;

  .add-on {
    z-index: 20;
  }
}

.file-input-text {
  width: 90%;
  margin: 0;
  z-index: 10;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 99%;
  height: 28px;
  margin: 0;
  line-height: 28px;
  cursor: default;
  opacity: 0;
}

.ie9 {
  .file-input {
    font-size: 0;
    line-height: 0;
  }
}

.oldie {
  .file-input {
    filter: alpha(opacity=0);
  }
}

.ie-placeholder {
  font-size: @font-size-base - 1; //11px;
  color: lighten(@gray-light, 6.5%); //#888;
}

/* ~~~ Flash/Error Messages ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#flash_message,
#flash_mini,
.flash_message {
  padding: 14px;
  font-weight: normal;
  overflow: hidden;
}

#flash_message span#flash_message_content,
.flash_message span.flash_message_content {
  color: lighten(@gray, 6.5%); //#666;
  font-weight: normal;
}

#flash_message {
  p {
    padding: 0;
    margin: 0;
    line-height: 16px;
    font-size: @font-size-base + 4; // 16px;
    flex-grow: 1;
  }

  span.icon {
    padding: 8px;
    margin-right: 12px;
  }

  &.alert-error,
  &.alert-warning {
    span {
      &.icon {
        background: transparent url("/img/flash_error.gif") no-repeat center;
      }
    }
  }

  &.alert-success {
    span {
      &.icon {
        background: transparent url("/img/flash_success.gif") no-repeat center;
      }
    }
  }

  &.alert-info {
    span {
      &.icon {
        background: transparent url("/img/flash_info.gif") no-repeat center;
      }
    }
  }

  a {
    color: darken(@gray, 7%); // #444;

    &:hover {
      text-decoration: underline;
    }
  }
}

#flash_mini {
  height: auto;
  z-index: 10000;
}

#flash_mini_content {
  padding-right: 6px;
  padding-bottom: 6px;
  color: lighten(@gray, 6.5%); // #666;
  font-weight: normal;
}

a {
  &.closeFlashMini {
    padding-left: 3px;
    padding-right: 3px;
    color: lighten(@gray, 6.5%); //#666;
    text-decoration: none;

    &:hover {
      background-color: lighten(@gray, 6.5%); //#666;
      color: @white; //#fff;
      text-decoration: none;
    }
  }
}

.alert {
  &.save-progress {
    padding: 9px 18px 9px 12px;
    margin: 0;
    text-align: center;
    font-weight: bold;
    font-size: @font-size-base + 4;  // ~16px;
    box-shadow: 0 0 4px;

    .halflings {
      margin: 3px 6px 0 0;
    }
  }
}

/**
 *error messages below fields and generallly
 *all error messages that appear on the website in a div
 *with these ids will have these styles
 */

#error,
.error_message,
.success_message {
  margin-top: 4px;
  padding-left: 20px;
  font-weight: normal;
  color: #c00;
  background: url("/img/deny.png") 3px 3px no-repeat;
  line-height: 20px;
}

.success_message {
  color: green;
  background-image: url("/img/accept.png");
}

/**
 * styles for duplicate records list on import view (visible just below flash-message)
 */

.error-listing {
  padding: 10px;
  margin: 0 0 20px 0;
  border: 1px solid #c00;
  line-height: 20px;
  font-weight: normal;
  color: #c00;

  .scroller {
    height: 100px;
    overflow: auto;
  }

  .title {
    padding-left: 20px;
    background: url("/img/deny.png") 3px 3px no-repeat;
    font-weight: bold;

    .closeIcon {
      width: 16px;
      height: 16px;
      float: right;
      background: url("/img/arrow_up.png") 0 0 no-repeat;
      cursor: pointer;
    }
  }

  .list {
    margin: 0 !important;
  }

  table {
    color: @gray-dark; //#333;
  }
}

#alertBottom {
  position: fixed;
  margin: 0;
  min-width: 400px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  overflow: hidden;

  &.alert_error {
    #alertBottomContent {
      background: transparent url("/img/warning.png") no-repeat 8px 8px;
      color: #c00;
    }
  }

  &.alert_success {
    #alertBottomContent {
      background: transparent url("/img/ok.png") no-repeat 8px 8px;
      color: #693;
    }
  }

  &.alert_info {
    #alertBottomContent {
      background: transparent url("/img/info.png") no-repeat 8px 8px;
      color: #369;
    }
  }
}

#alertBottomContentWrapper {
  margin: 6px;
  height: 48px;
  background: #fff url("/img/tab_bg.gif") bottom left repeat-x;
}

#alertBottomContent {
  padding: 16px 16px 16px 48px;
  float: left;
  clear: none;
  color: lighten(@gray, 6.5%); //#666;
  font-size: @font-size-base + 1; //13px;
}

#alertBottomClose {
  padding: 12px 12px 0;
  float: right;
  clear: none;
}

/* ~~~ Ajax Loading Indicator ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#progress {
  position: relative;
  top: 6px;
  right: 5px;
  z-index: 12000;
  display: none;
  width: 26px;
  height: 26px;
  padding: 0;
  margin: 0;
  background: rgba(68, 68, 68, 0.7);
  .border-radius(13px);

  span {
    display: block;
    width: 26px;
    height: 26px;
    background: transparent url("/img/ajax-loader2.gif") no-repeat center;
  }
}

.ajax_progress {
  padding: 6px 6px 6px 24px;
  margin-left: 12px;
  background: transparent url("/img/ajax-loader.gif") no-repeat left center;
  text-align: left;
}

/* ~~~ Drag & Resize (interface.js) ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#resizeMe {
  display: none;
  position: absolute;
  width: 200px;
  height: 150px;
  cursor: move;
  background: url("/img/black_80.png") repeat;
  border: 1px dashed darken(@gray-lighter, 6.7%); //#ddd;
}

#resizeSE,
#resizeE,
#resizeNE,
#resizeN,
#resizeNW,
#resizeW,
#resizeSW,
#resizeS {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: @uberGrayDarker;
  border: 1px solid @white;
  overflow: hidden;
}

#resizeSE {
  bottom: -10px;
  right: -10px;
  cursor: se-resize;
}

#resizeE {
  top: 50%;
  right: -10px;
  margin-top: -5px;
  cursor: e-resize;
}

#resizeNE {
  top: -10px;
  right: -10px;
  cursor: ne-resize;
}

#resizeN {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  cursor: n-resize;
}

#resizeNW {
  top: -10px;
  left: -10px;
  cursor: nw-resize;
}

#resizeW {
  top: 50%;
  left: -10px;
  margin-top: -5px;
  cursor: w-resize;
}

#resizeSW {
  left: -10px;
  bottom: -10px;
  cursor: sw-resize;
}

#resizeS {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  cursor: s-resize;
}

#container {
  display: none;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 30px;
  height: 40px;
  border: 1px solid darken(@gray-lighter, 6.7%); //#ddd;
}

#existingLinks,
#existingClippings,
#existingZones {
  position: absolute;
  display: none;
  overflow: hidden;
}

.existingLink,
.existingClipping,
.existingVideoPlacement,
.existingWidget {
  position: absolute;
  overflow: hidden;
  border: 1px dashed @uberBlueLight;
  color: @uberGrayDark;
  cursor: pointer;
  background: transparent url("/img/white_10.png") repeat;
  .box-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

/**
 * Hide Element Styles
 */

.offscreen {
  position: absolute;
  top: -1000em;
  left: -1000em;
}

.ucase {
  text-transform: uppercase;
}

.clear,
.clr {
  height: 0;
  clear: both;
  overflow: hidden;
}

.nobr,
.nowrap {
  white-space: nowrap;
}

.small {
  font-size: @font-size-base - 1;
}

/* shortcuts */

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.italic {
  font-style: italic;
}

/* ~~~ Issue Processing ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* processing statuses */

.status_step_1,
.status_step_2,
.status_step_3,
.status_step_4,
.status_step_5,
.status_unknown {
  margin: 0 8px;
  padding-bottom: 8px;
  font-size: @font-size-base - 1.5; //10.5px;
  font-weight: normal;
  background: transparent url("/img/statuses.png") no-repeat 0 0;
  color: #212121;
}
.status_step_2 { background-position: 0px -20px; }
.status_step_3 { background-position: 0px -40px; }
.status_step_4 { background-position: 0px -60px; }
.status_step_5 { background-position: 0px -80px; }
.status_unknown { background-position: 0px -120px; }

.price {
  font-weight: bold;
  color: green;
}

.layout-bs3-uberflip {
  .arrow {
    position: relative;
  }

  .progress-uf .bar {
    background-color: @uberPink;
    background-image: -moz-linear-gradient(top, @uberPinkLight, @uberPink);
    background-image: -ms-linear-gradient(top, @uberPinkLight, @uberPink);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@uberPinkLight), to(@uberPink));
    background-image: -webkit-linear-gradient(top, @uberPinkLight, @uberPink);
    background-image: -o-linear-gradient(top, @uberPinkLight, @uberPink);
    background-image: linear-gradient(top, @uberPinkLight, @uberPink);
    background-repeat: repeat-x;
  }

  .well {
    background-color: @whiteSmoke;
  }
}
