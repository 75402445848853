// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ***************************************************************************************

.highlight-fade {
  -webkit-animation: target-fade 3s 1;
  -moz-animation: target-fade 3s 1;
}

@-webkit-keyframes target-fade {
  0% { background-color: rgba(238, 238, 238, 1); }
  40% { background-color: rgba(255, 255, 224, 1); }
  100% { background-color: rgba(238, 238, 238, 1); }
}

@-moz-keyframes target-fade {
  0% { background-color: rgba(238, 238, 238, 1); }
  40% { background-color: rgba(255, 255, 224, 1); }
  100% { background-color: rgba(238, 238, 238, 1); }
}
