// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

.btn,
a.btn,
input.btn,
button.btn {
  font-family: @roboto;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;

  .caret {
    padding: 0;
    opacity: 0.3;
  }

  &:not(.btn-link) {
    &:hover {
      .caret {
        opacity: 0.9;
      }
    }
  }

  &.active,
  &:active {
    color: lighten(@gray-light, 13.2%);   // #999
    background-color: #e6e6e6;
  }

  &:focus {
    color: @white;
  }

  &.btn-default {
    color: @btn-default-color;
  }

  &.btn-white {
    // same as btn-default
    .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
  }

  &.btn-pink,
  &.btn-primary {
    background: @btn-primary-bg;
    border-color: @btn-primary-bg;
    color: @white;

    &:hover {
      background: @uberPinkDark;
      border-color: @uberPinkDark;
    }
  }

  &.btn-primary {
    &.disabled,
    &[disabled] {
      &:hover {
        background-color: @uberPinkLight;
      }
    }
  }

  &.btn-mini {
    padding: 2px 6px;
    font-size: @font-size-base - 1;       // ~11px
    line-height: @line-height-computed - 4;   // ~14px

    .caret {
      margin-top: 5px;
    }
  }

  &.large {
    width: auto;
    padding: 9px 14px;
  }

  &.btn-large {
    padding: 9px 14px;
    font-size: @font-size-base + 3;   // ~15px
    line-height: normal;

    [class^="icon-"],
    [class*=" icon-"] {
      margin-top: 1px;
    }
  }

  &.disabled,
  &[disabled] {
    background: lighten(@gray-light, 13.2%);      // #999;
    border-color: lighten(@gray-light, 13.2%);    // #999;
    text-decoration: none !important;
    color: @white;
  }

  &.btn-info,
  &.btn-warning,
  &.btn-inverse {
    color: @white !important;

    &:active {
      color: @white !important;
    }
  }

  &.btn-info {
    background: @btn-info-bg;
    border-color: @btn-info-bg;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);

    &:hover {
      background: @uberBlue;
      border-color: @uberBlue;
    }
  }

  &.btn-inverse {
    background: @btnInverseBackground;
    border-color: @btnInverseBackground;
    border-bottom-color: @btnInverseBackgroundHighlight;

    &:hover {
      background: @btnInverseBackgroundHighlight;
      border-color: @btnInverseBackgroundHighlight;
    }

    &.active,
    &:active {
      background: @btnInverseBackgroundActive;
      border-color: @btnInverseBackgroundActive;
      color: @white;
    }

    &.btn-preview {
      background: @btnInverseBackgroundActive;
      border-color: @btnInverseBackgroundActive;

      &:hover {
        background: @uberGrayDark;
        border-color: @uberGrayDark;
      }

      &.active,
      &:active {
        background: @btnInverseBackground;
        border-color: @btnInverseBackground;
        color: @white;
      }
    }
  }
}

.disabled-btn-wrapper {
  padding-top: 4px;
  padding-bottom: 8px;
}

// ****************************************************************************

.btn-group {
  font-size: inherit;
  .clearfix;

  .btn {
    float: left;
  }

  &.open {
    .dropdown-toggle.btn-primary {
      background: @uberPinkDark;
      border-color: @uberPinkDark;
      box-shadow: none;
      filter: none;
    }
  }

  .dropdown-toggle {
    box-shadow: none;
  }

  .dropdown-menu {
    li {
      a,
      a:link,
      a:visited {
        color: @gray;
        padding: 7px 9px;

        &:hover {
          color: @white !important;
          background: @uberPink;
          filter: none;
        }
      }
    }
  }
}

// ****************************************************************************

.btn-link {
  cursor: pointer;
  border-color: transparent;
  border-radius: 0;

  &,
  &:active,
  &[disabled] {
    background-color: transparent;
    background-image: none;
    box-shadow: none;
  }
}

// ****************************************************************************

.ie9 {
  .btn-pink.btn-primary {
    background-image: none;
    background-color: @uberPink;
    filter: none;

    &:hover {
      background-color: @uberPinkLight;
    }
  }
}

.button-parent {
  display: inline;

  &.disabled {
    cursor: not-allowed;

    .btn.disabled {
      pointer-events: none;
    }
  }
}
