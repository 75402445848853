// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

//---------------------------------------------------------------------------
// Pseudo-Selectbox Controls
// Related Target Element (Trigger)
//---------------------------------------------------------------------------

.pseudo-selectbox {
  display: block;
  width: 25%;
  height: 2.75em;
  padding: 0px 0 0 18px;
  margin: 0;
  font-weight: bold;
  color: #555;
  font-size: 1.2em;
  text-align: left;
  border: 1px solid lightGrey;
  background: #f9f9f9 url("/img/arrow_down.png")  no-repeat right 12px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  .border-radius(3px);

  span {
    display: block;
    float: left;
    width: 92%;
    padding: 0px 4%;
    margin: 0 0 0 -18px;
    line-height: 2.75em;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fff;
    box-sizing: content-box;
  }

  &:hover,
  &.hover,
  &.active {
    border: 1px solid #bbb;
    color: #212121;
    text-decoration: none;
  }

  &.active {
    background: #eee url("/img/arrow_up.png")  no-repeat right 12px;
    .border-radius(3px 3px 0 0);
  }

  &.disabled,
  &.disabled.active,
  &.disabled.hover,
  &.disabled:hover {
    color: #999;
    border: 1px solid lightGrey;
    cursor: not-allowed;
  }
}

// Adjustments for Webkit with Button Tag
button.pseudo-selectbox span {
  margin: 0 0 0 -2px;
  width: 260px;
}

// Related Content Element (Contents)

.pseudo-selectbox-container {
  display: none;
  position: absolute;
  z-index: 999999;
  zoom: 1;
  background-color: #fff;
  border: #bbb solid 1px;
  .border-radius(0 0 6px 6px);
  .box-shadow(0 2px 8px rgba(0, 0, 0, 0.2));
  .background-clip(padding-box);
}

.pseudo-selectbox-wrapper {
  .command-line {
    padding: 10px 0 1px 0;
    margin: 10px;
    border-top: 1px solid #efefef;

    .icheck {
      float: left;
      width: auto;
      margin: 0;
      padding: 0;
    }

    #errorMsg {
      display: none;
      float: left;
      margin: 3px 15px 0 0;
      font-weight: normal;
      padding: 3px 7px 3px 20px;
      line-height: 140%;
      border: 1px solid #bd4d4d;
      color: #c00;
      background: url("/img/deny.png") 5px 5px no-repeat;
    }

    label {
      float: left;
      width: auto;
      padding: 0px 0 0 10px;
      cursor: pointer;
      margin: 0;
      font-size: 12px;
      line-height: 1;
    }
    button { float: left; }

    button.btn-basic-primary {
      cursor: pointer;
      text-align: center;
      padding: 5px 10px;
      color: #555;
      font-weight: bold;
      background: #f8f8f8;
      border: 1px solid #d9d9d9;
      text-decoration: none !important;
      .border-radius(4px);

      zoom: 1;

      &.hover {
        color: #000;
        background: #f0f0f0;
        border: 1px solid #d9d9d9;
      }

      &.disabled,
      &.disabled.hover {
        color: #999;
        background: #f9f9f9;
        border: 1px solid #d3d3d3;
        text-shadow: 1px 1px 2px #fff;
      }
    }

    a,
    a:link,
    a:visited,
    a:hover {
      float: left;
      margin-left: 10px;
    }

    a.btn-basic,
    a.btn-basic:link,
    a.btn-basic:visited,
    a.btn-basic:hover {
      display: block;
      line-height: 28px;
      color: #999;
      margin-left: 10px;
      font-weight: bold;
      text-decoration: none !important;
      vertical-align: middle;
    }

    a.btn-basic:hover {
      color: #000;
    }
  }

  .command-line-left { float: left; }
  .command-line-right { float: right; }
}

/* For Pseudo-Lists contained within Pseudo-Selectbox */
.pseudo-list {
  width: 250px;
  height: 204px;
  padding: 0;
  background-color: #fff;
  margin: 9px;
}

.pseudo-list fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;
  background: #fff;
}

.pseudo-list fieldset .legend {
  display: block;
  height: 30px;
  font-size: 1.2em;
  line-height: 30px;
  font-weight: bold;
  text-align: left;
  margin: 0;
  padding: 0;
  background: #bbb;
  border-bottom: 1px solid #999;
}

.pseudo-list fieldset .legend {
  span {
    float: left;
    color: #fff;
    padding: 0px 10px;
  }

  span.selection-text {
    float: right;
    font-size: 0.75em;
  }

  .close-compare {
    float: right;
    color: #999;
    padding: 0px 10px;
    cursor: pointer;
    text-decoration: none;
    text-shadow: none;
  }

  .close-compare:hover { color: #222; }
}

.pseudo-list fieldset .fieldset-content {
  height: 176px;
  text-align: left;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #ccc;
  border-top: none;
}

.pseudo-list fieldset .fieldset-content input {
  position: absolute;
  top: 0;
  left: -9999px;
}

.pseudo-list fieldset .fieldset-content label {
  position: relative;
  display: block;
  background: #fff;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #f9f9f9;
}

.pseudo-list fieldset .fieldset-content label.hover {
  background-color: #f5f5f5;
  text-decoration: none;
}

.pseudo-list fieldset .fieldset-content label.active,
.pseudo-list fieldset .fieldset-content label.active.hover {
  font-weight: bold;
  background-color: #f9f9f9;
  border-bottom-color: #eee;
}

.pseudo-list fieldset .fieldset-content label.active.hover {
  background-color: #f5f5f5;
}

.pseudo-list fieldset .fieldset-content label.disabled {
  background: #fff;
  color: #aaa;
}

.pseudo-list fieldset .fieldset-content.multiple input {
  position: relative;
  top: auto;
  left: auto;
  float: left;
  width: auto;
  margin: 5px 5px 0 0;
}

/* For common elements found within Pseudo-Selectbox Content */

.layout-bs3 {
  .pseudo-selectbox-content {
    ul {
      list-style: none;

      li {
        a {
          float: left;
          width: 94%;
          padding: 0.3em 3%;
          cursor: pointer !important;
          text-decoration: none;
        }
      }
    }

    .date-select {
      width: 290px;
      padding: 5px;
      font-size: 1.1em;
    }

    .field-column {
      float: left;
      width: 130px;
      margin: 0 5px;
    }

    label {
      display: block;
      float: none;
      width: auto;
      color: #444;
      text-align: left;
      margin: 0;
      padding: 0;
      line-height: 2em;
      font-size: inherit;
    }

    input {
      width: 125px;
      font-size: 1em;
    }

    .date-select label { font-weight: bold; }

    .named-ranges {
      display: block;
      margin: 0;
      padding: 5px;
      color: #444;
      font-size: 1.1em;
    }

    .named-ranges a,
    .named-ranges a:link,
    .named-ranges a:visited {
      color: #444;
      text-decoration: none;
      cursor: pointer;
      background: #eee;
      padding: 3px 5px;
      margin: 0 0 0 5px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      -khtml-border-radius: 4px;
      border-radius: 4px;
      zoom: 1;
    }

    .named-ranges a:hover {
      background: #ddd;
      color: #000;
    }
  }
}

.layout-bs3-uberflip {
  .pseudo-selectbox-content {
    .command-line {
      button {
        background: #f6f5ef;
        border: 1px solid #dad8d1;

        &.hover {
          background: #eae7e0;
          border: 1px solid #dad8d1;
        }
      }
    }
  }

  .pseudo-list {
    fieldset {
      .fieldset-content {
        label {
          &.active {
            &,
            &.hover {
              color: @uberBlue;
            }
          }
        }
      }
    }
  }
}
