// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

.dataTables_wrapper {
  &.form-inline {
    float: none;
  }
}

// ----------------------------------------------------------------------------
// Select2 - Fancy Selectbox
// ----------------------------------------------------------------------------

html.layout-bs3 ul.select2-results {
  padding: 0px 0 0 4px;
  margin: 4px 4px 4px 0;
}

/* this can be moved eventually to a specific less file for /account/details */

.settings_section {
  &.account-details {
    min-height: 170px;

    > .avatar-wrapper {
      float: left;
      width: 180px;
      margin-top: 20px;
      margin-right: 10px;
    }

    > table {
      margin-top: 40px !important;

      th {
        width: 80px;
      }

      td {
        width: 300px;
      }
    }
  }

  &.account-profile {
    > table {
      &.with-map {
        width: 50%;
        margin-right: 2%;
        float: left;
        clear: none;
      }

      th {
        width: 180px;
      }
    }

    > .account-login-map {
      float: left;
      clear: none;
      width: 48%;
    }
  }
}

// ----------------------------------------------------------------------------
// CKEditor styling
// ----------------------------------------------------------------------------

.cke {
  &.cke_chrome {
    border-color: #ccc;
    .box-shadow(none);
  }

  .cke_top,
  .cke_bottom {
    background: #eee;
    background-image: none;
    .box-shadow(none);
  }

  a.cke_button {
    background: #f9f9f9;

    &:hover {
      background: @white;
    }
  }
}

.cke_dialog_body {
  a.cke_dialog_ui_button_ok {
    background: @uberBlueLight;
    border-color: @uberBlue;

    &:hover {
      background: @uberBlue;
      color: #fff;
    }
  }
}

// ----------------------------------------------------------------------------
// Spectrum Color Picker Component
// ----------------------------------------------------------------------------

.sp-container {
  border-color: rgba(82, 168, 236, 0.8);
}

.sp-replacer {
  border-color: #ccc;
  background: @white;
  .transition(~"border linear .2s, box-shadow linear .2s");

  > .sp-preview {
    width: 50px;
    border-color: #ccc;
  }

  .sp-dd { padding: 2px 5px; }

  &:hover {
    border-color: rgba(82, 168, 236, 0.8);
  }

  &.sp-active {
    border-color: rgba(82, 168, 236, 0.8);
    .box-shadow(~"inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)");

    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
  }
}

// ----------------------------------------------------------------------------
// Slider Component

.slider {
  .slider-track {
    background-color: darken(@gray-lighter, 13.5%); // #ccc
    .box-shadow(none);

    > .slider-handle {
      background: @gray-dark;
      .opacity(100) ;

      &:hover {
        background: @uberBlue;
      }

      &:active {
        background: @uberBlueLight;
      }
    }
  }

  .slider-selection {
    background-color: @gray-lighter;
    .box-shadow(none);
  }
}

/* recent activity container */

.recent-activity {
  margin: 0 30px;
  > span { margin: 0 3px; }

  &.editor-fullscreen {
    position: fixed;
    bottom: 90px;
    right: 0px;
    z-index: 99999;
  }
}

// ----------------------------------------------------------------------------
// DatePicker Component

.layout-bs3 .ui-datepicker {
  width: 19em;
}

select.ui-datepicker-month,
select.ui-datepicker-year { padding: 0px 0.5px !important; }

// ----------------------------------------------------------------------------
// Bootstrap Badges

.badge-warning {
  background-color: @label-warning-bg;
}

.badge-warning:hover {
  background-color: darken(@label-warning-bg, 10%);
}

.badge-info {
  background-color: @label-info-bg;
}

.badge-info:hover {
  background-color: darken(@label-info-bg, 10%);
}

// ----------------------------------------------------------------------------
// Bootstrap Tabs Links
.tabs > li > a { text-decoration: none; }

// ----------------------------------------------------------------------------
// Bootstrap Alert Message
.alert {
  color: #785a28;
  text-shadow: none;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}

.alert-danger,
.alert-error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}

.alert-info {
  background-color: #f0f9fd;
  border-color: #bce8f1;
  color: #2f96b4;
}

// ----------------------------------------------------------------------------
// Bootstrap Tooltips
.tooltip { z-index: 9999999999; }
.tooltip-inner { max-width: 300px; }

// ----------------------------------------------------------------------------
// Bootstrap Popover

.popover {
  font-family: @roboto;

  .arrow {
    position: absolute;
  }

  h3 {
    &.popover-title {
      font-size: (@baseFontSize + 6); // ~18px
      font-weight: bold;
    }
  }
}

/* ~~~ Clippings ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.clipping_title {
  font-weight: bold;
  font-size: @font-size-base + 2; //14px
  color: darken(@gray, 7%);
}

.clipping_caption {
  width: 140px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: @font-size-base - 1; //11px;
  color: @uberGrayDark;
}

.clipping_links {
  border-top: 2px solid @gray-lighter;
  padding-top: 8px;

  img {
    width: 16px;
    margin-left: 8px;
    vertical-align: middle;
  }
}

/* ~~~ Auto Suggest ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.autosuggest {
  display: none;
  position: absolute;
  min-width: 120px;
  padding: 2px;
  border: 1px solid darken(@gray-lighter, 13.5%); //#ccc;
  background-color: @white;

  div {
    height: 21px;
    margin: 0;
    padding: 3px;
    cursor: pointer;
    display: block;
    border-bottom: 1px solid @gray-lighter;

    &.selected {
      background-color: @uberGrayDark;
      color: @white;
    }
  }
}

/* ~~~ Datepicker ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.layout-bs3 #ui-datepicker-div {
  font-family: @roboto;
  display: none;
  z-index: 999999;
}

#datepicker {
  position: absolute;
}

#command-line-left {
  float: left;
  margin: 10px 0 0 0;
}

#command-line-right {
  float: right;
  margin: 10px 0 0 0;
}

.confirmation_box {
  padding: 8px;
  border: 1px solid darken(@gray-lighter, 6.7%); //#ddd;
  background: @gray-lighter;

  div.note {
    margin-top: 6px;
  }
}

/* ~~~ jQuery UI ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.ui-widget-header {
  background: #faf9f6 !important;
  border-color: #ddd !important;
}

.widget {
  &,
  &.hover {
    &,
    .ui-widget-header {
      background: #f9f9f9;
      border-color: @gray-lighter !important;
    }
  }
}

#unlock_ratio,
#lock_ratio {
  display: none;
  float: left;
  clear: none;
  margin-left: 12px;
  padding: 7px 7px 7px 17px;
  text-align: center;
  color: @gray-light; //#777;
  background: transparent url("/img/lock_closed.gif") 0 6px no-repeat;
}

#lock_ratio {
  display: none;
  background-image: url("/img/lock_open.gif");
}

/* ~~~ URL slugs ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#url-slugs {
  margin-bottom: 5px;

  .primary-url-slug-container {
    margin-bottom: 3px;
  }

  .toggle-secondary-url-slugs {
    display: inline-block;
    width: 100%;
    clear: both;
    text-align: right;
  }

  .secondary-url-slugs-container {
    clear: both;

    .secondary-url-slug {
      &:first-child {
        margin-top: 5px;
      }

      margin: 0 0 5px 0;

      .input-group {
        .input-group-addon {
          visibility: hidden;
        }

        input {
          .border-radius(3px);
        }
      }

      .delete-secondary-url-slug {
        float: right;
        height: 32px;
        margin-left: 3px;

        .halflings-remove {
          line-height: 20px;
        }
      }
    }
  }
}

/* ~~~ Page Overlay Loader ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
#page-ajax-overlay {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  overflow: hidden;
  z-index: 10;

  .page-ajax-overlay-container {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}
