// ****************************************************************************
// Twitter Bootstrap 3
@import "../../../libs/bootstrap-3.4.1/less/bootstrap.less";

// Shared Variables and Mixins
@import "../shared/_variables.less";
@import "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import "_mixins.less";

// ****************************************************************************
// Backend Includes

// Layout Overrides
@import "_layout.less";

// Typography
@import "_typography.less";

// Shared Icons/Sprites
@import "../shared/_sprites.less";

// Uberflip Sprites
@import "_sprites.less";

// Navbar Element
@import "_navbar.less";

// Layout Header Elements
@import "_header.less";

// Content Elements
@import "_content.less";

// Layout Footer Elements
@import "_footer.less";

// Form Elements
@import "_forms.less";

// Table Elements
@import "_tables.less";

// Button Elements
@import "_buttons.less";

// Modal Elements
@import "_modal.less";

// Custom Transitions & Animations
@import "_transitions.less";

// Common Utility Classes for HTML
@import "_util.less";

// ****************************************************************************
// Viewport Control (ex: IE10 in Snap-Mode)
@-webkit-viewport { width: device-width; }

@-moz-viewport { width: device-width; }

@-ms-viewport { width: device-width; }

@-o-viewport { width: device-width; }

@viewport { width: device-width; }

// ****************************************************************************
// Component Z-Indexes
//  **note: all z-indexes are grouped here for easy management

.hubs_font_select { z-index: 10; }

.navbar .nav > li > a:before { z-index: @zindex-popover; }

.modal .alt-close-icon { z-index: 10; }

.modal-open .dropdown-menu { z-index: @zindex-dropdown; }
.modal-open .modal .dropdown-menu { z-index: @zindex-dropdown + @zindex-modal; }

#footer { z-index: 100; }

.listOptions { z-index: 100001; }

.layout-v2 {
  .modal { z-index: 120000; }
  .modal-backdrop { z-index: 119000; }
}

// ****************************************************************************
// Media Queries

.thick-box-like-modal .modal-body {
  padding: 25px;
  width: 100%;
}

.thick-box-like-modal.modal-iframe .modal-body {
  padding: 0;
}

.thick-box-like-modal.modal-no-overflow .modal-body {
  overflow: initial; // fallback for IE
  overflow: unset;
}
