//
// Glyphicons for Bootstrap
//
// Since icons are fonts, they can be placed anywhere text is placed and are
// thus automatically sized to match the surrounding child. To use, create an
// inline element with the appropriate classes, like so:
//
// <a href="#"><span class="halflings halflings-star"></span> Star</a>

// Import the fonts
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('@{icon-font-path}glyphicons-halflings-regular.eot');
  src: url('@{icon-font-path}glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
       url('@{icon-font-path}glyphicons-halflings-regular.woff2') format('woff2'),
       url('@{icon-font-path}glyphicons-halflings-regular.woff') format('woff'),
       url('@{icon-font-path}glyphicons-halflings-regular.ttf') format('truetype'),
       url('@{icon-font-path}glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

// Catchall baseclass
.halflings {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  //vertical-align:top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.halflings.x05{
  font-size:6px;
}
.halflings.x2{
  font-size:24px;
}
.halflings.x3{
  font-size:36px;
}
.halflings.x4{
  font-size:48px;
}
.halflings.x5{
  font-size:60px;
}
.halflings.x6{
  font-size:72px;
}
.halflings.x7{
  font-size:84px;
}
.halflings.x8{
  font-size:96px;
}


// Light
.halflings.light{
  &:before{
    color:#f2f2f2
  }
}

// Drop shadow
.halflings.drop{
  &:before{
    text-shadow: -1px 1px 3px rgba(0,0,0,0.3);
  }  
}

// Horizontal Flip
.halflings.flip{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.halflings.flipv{
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

// Rotate
.halflings.rotate90{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.halflings.rotate180{
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.halflings.rotate270{
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}




// Individual icons
.halflings-glass{ &:before{ content:"\E001";} }
.halflings-music{ &:before{ content:"\E002";} }
.halflings-search{ &:before{ content:"\E003";} }
.halflings-envelope{ &:before{ content:"\E004";} }
.halflings-heart{ &:before{ content:"\E005";} }
.halflings-star{ &:before{ content:"\E006";} }
.halflings-star-empty{ &:before{ content:"\E007";} }
.halflings-user{ &:before{ content:"\E008";} }
.halflings-film{ &:before{ content:"\E009";} }
.halflings-th-large{ &:before{ content:"\E010";} }
.halflings-th{ &:before{ content:"\E011";} }
.halflings-th-list{ &:before{ content:"\E012";} }
.halflings-ok{ &:before{ content:"\E013";} }
.halflings-remove{ &:before{ content:"\E014";} }
.halflings-zoom-in{ &:before{ content:"\E015";} }
.halflings-zoom-out{ &:before{ content:"\E016";} }
.halflings-off{ &:before{ content:"\E017";} }
.halflings-signal{ &:before{ content:"\E018";} }
.halflings-cog{ &:before{ content:"\E019";} }
.halflings-trash{ &:before{ content:"\E020";} }
.halflings-home{ &:before{ content:"\E021";} }
.halflings-file{ &:before{ content:"\E022";} }
.halflings-time{ &:before{ content:"\E023";} }
.halflings-road{ &:before{ content:"\E024";} }
.halflings-download-alt{ &:before{ content:"\E025";} }
.halflings-download{ &:before{ content:"\E026";} }
.halflings-upload{ &:before{ content:"\E027";} }
.halflings-inbox{ &:before{ content:"\E028";} }
.halflings-play-circle{ &:before{ content:"\E029";} }
.halflings-repeat{ &:before{ content:"\E030";} }
.halflings-refresh{ &:before{ content:"\E031";} }
.halflings-list-alt{ &:before{ content:"\E032";} }
.halflings-lock{ &:before{ content:"\E033";} }
.halflings-flag{ &:before{ content:"\E034";} }
.halflings-headphones{ &:before{ content:"\E035";} }
.halflings-volume-off{ &:before{ content:"\E036";} }
.halflings-volume-down{ &:before{ content:"\E037";} }
.halflings-volume-up{ &:before{ content:"\E038";} }
.halflings-qrcode{ &:before{ content:"\E039";} }
.halflings-barcode{ &:before{ content:"\E040";} }
.halflings-tag{ &:before{ content:"\E041";} }
.halflings-tags{ &:before{ content:"\E042";} }
.halflings-book{ &:before{ content:"\E043";} }
.halflings-bookmark{ &:before{ content:"\E044";} }
.halflings-print{ &:before{ content:"\E045";} }
.halflings-camera{ &:before{ content:"\E046";} }
.halflings-font{ &:before{ content:"\E047";} }
.halflings-bold{ &:before{ content:"\E048";} }
.halflings-italic{ &:before{ content:"\E049";} }
.halflings-text-height{ &:before{ content:"\E050";} }
.halflings-text-width{ &:before{ content:"\E051";} }
.halflings-align-left{ &:before{ content:"\E052";} }
.halflings-align-center{ &:before{ content:"\E053";} }
.halflings-align-right{ &:before{ content:"\E054";} }
.halflings-align-justify{ &:before{ content:"\E055";} }
.halflings-list{ &:before{ content:"\E056";} }
.halflings-indent-left{ &:before{ content:"\E057";} }
.halflings-indent-right{ &:before{ content:"\E058";} }
.halflings-facetime-video{ &:before{ content:"\E059";} }
.halflings-picture{ &:before{ content:"\E060";} }
.halflings-pencil{ &:before{ content:"\E061";} }
.halflings-map-marker{ &:before{ content:"\E062";} }
.halflings-adjust{ &:before{ content:"\E063";} }
.halflings-tint{ &:before{ content:"\E064";} }
.halflings-edit{ &:before{ content:"\E065";} }
.halflings-share{ &:before{ content:"\E066";} }
.halflings-check{ &:before{ content:"\E067";} }
.halflings-move{ &:before{ content:"\E068";} }
.halflings-step-backward{ &:before{ content:"\E069";} }
.halflings-fast-backward{ &:before{ content:"\E070";} }
.halflings-backward{ &:before{ content:"\E071";} }
.halflings-play{ &:before{ content:"\E072";} }
.halflings-pause{ &:before{ content:"\E073";} }
.halflings-stop{ &:before{ content:"\E074";} }
.halflings-forward{ &:before{ content:"\E075";} }
.halflings-fast-forward{ &:before{ content:"\E076";} }
.halflings-step-forward{ &:before{ content:"\E077";} }
.halflings-eject{ &:before{ content:"\E078";} }
.halflings-chevron-left{ &:before{ content:"\E079";} }
.halflings-chevron-right{ &:before{ content:"\E080";} }
.halflings-plus-sign{ &:before{ content:"\E081";} }
.halflings-minus-sign{ &:before{ content:"\E082";} }
.halflings-remove-sign{ &:before{ content:"\E083";} }
.halflings-ok-sign{ &:before{ content:"\E084";} }
.halflings-question-sign{ &:before{ content:"\E085";} }
.halflings-info-sign{ &:before{ content:"\E086";} }
.halflings-screenshot{ &:before{ content:"\E087";} }
.halflings-remove-circle{ &:before{ content:"\E088";} }
.halflings-ok-circle{ &:before{ content:"\E089";} }
.halflings-ban-circle{ &:before{ content:"\E090";} }
.halflings-arrow-left{ &:before{ content:"\E091";} }
.halflings-arrow-right{ &:before{ content:"\E092";} }
.halflings-arrow-up{ &:before{ content:"\E093";} }
.halflings-arrow-down{ &:before{ content:"\E094";} }
.halflings-share-alt{ &:before{ content:"\E095";} }
.halflings-resize-full{ &:before{ content:"\E096";} }
.halflings-resize-small{ &:before{ content:"\E097";} }
.halflings-plus{ &:before{ content:"\E098";} }
.halflings-minus{ &:before{ content:"\E099";} }
.halflings-asterisk{ &:before{ content:"\E100";} }
.halflings-exclamation-sign{ &:before{ content:"\E101";} }
.halflings-gift{ &:before{ content:"\E102";} }
.halflings-leaf{ &:before{ content:"\E103";} }
.halflings-fire{ &:before{ content:"\E104";} }
.halflings-eye-open{ &:before{ content:"\E105";} }
.halflings-eye-close{ &:before{ content:"\E106";} }
.halflings-warning-sign{ &:before{ content:"\E107";} }
.halflings-plane{ &:before{ content:"\E108";} }
.halflings-calendar{ &:before{ content:"\E109";} }
.halflings-random{ &:before{ content:"\E110";} }
.halflings-comments{ &:before{ content:"\E111";} }
.halflings-magnet{ &:before{ content:"\E112";} }
.halflings-chevron-up{ &:before{ content:"\E113";} }
.halflings-chevron-down{ &:before{ content:"\E114";} }
.halflings-retweet{ &:before{ content:"\E115";} }
.halflings-shopping-cart{ &:before{ content:"\E116";} }
.halflings-folder-close{ &:before{ content:"\E117";} }
.halflings-folder-open{ &:before{ content:"\E118";} }
.halflings-resize-vertical{ &:before{ content:"\E119";} }
.halflings-resize-horizontal{ &:before{ content:"\E120";} }
.halflings-hdd{ &:before{ content:"\E121";} }
.halflings-bullhorn{ &:before{ content:"\E122";} }
.halflings-bell{ &:before{ content:"\E123";} }
.halflings-certificate{ &:before{ content:"\E124";} }
.halflings-thumbs-up{ &:before{ content:"\E125";} }
.halflings-thumbs-down{ &:before{ content:"\E126";} }
.halflings-hand-right{ &:before{ content:"\E127";} }
.halflings-hand-left{ &:before{ content:"\E128";} }
.halflings-hand-top{ &:before{ content:"\E129";} }
.halflings-hand-down{ &:before{ content:"\E130";} }
.halflings-circle-arrow-right{ &:before{ content:"\E131";} }
.halflings-circle-arrow-left{ &:before{ content:"\E132";} }
.halflings-circle-arrow-top{ &:before{ content:"\E133";} }
.halflings-circle-arrow-down{ &:before{ content:"\E134";} }
.halflings-globe{ &:before{ content:"\E135";} }
.halflings-wrench{ &:before{ content:"\E136";} }
.halflings-tasks{ &:before{ content:"\E137";} }
.halflings-filter{ &:before{ content:"\E138";} }
.halflings-briefcase{ &:before{ content:"\E139";} }
.halflings-fullscreen{ &:before{ content:"\E140";} }
.halflings-dashboard{ &:before{ content:"\E141";} }
.halflings-paperclip{ &:before{ content:"\E142";} }
.halflings-heart-empty{ &:before{ content:"\E143";} }
.halflings-link{ &:before{ content:"\E144";} }
.halflings-phone{ &:before{ content:"\E145";} }
.halflings-pushpin{ &:before{ content:"\E146";} }
.halflings-euro{ &:before{ content:"\E147";} }
.halflings-usd{ &:before{ content:"\E148";} }
.halflings-gbp{ &:before{ content:"\E149";} }
.halflings-sort{ &:before{ content:"\E150";} }
.halflings-sort-by-alphabet{ &:before{ content:"\E151";} }
.halflings-sort-by-alphabet-alt{ &:before{ content:"\E152";} }
.halflings-sort-by-order{ &:before{ content:"\E153";} }
.halflings-sort-by-order-alt{ &:before{ content:"\E154";} }
.halflings-sort-by-attributes{ &:before{ content:"\E155";} }
.halflings-sort-by-attributes-alt{ &:before{ content:"\E156";} }
.halflings-unchecked{ &:before{ content:"\E157";} }
.halflings-expand{ &:before{ content:"\E158";} }
.halflings-collapse{ &:before{ content:"\E159";} }
.halflings-collapse-top{ &:before{ content:"\E160";} }
.halflings-log-in{ &:before{ content:"\E161";} }
.halflings-flash{ &:before{ content:"\E162";} }
.halflings-log-out{ &:before{ content:"\E163";} }
.halflings-new-window{ &:before{ content:"\E164";} }
.halflings-record{ &:before{ content:"\E165";} }
.halflings-save{ &:before{ content:"\E166";} }
.halflings-open{ &:before{ content:"\E167";} }
.halflings-saved{ &:before{ content:"\E168";} }
.halflings-import{ &:before{ content:"\E169";} }
.halflings-export{ &:before{ content:"\E170";} }
.halflings-send{ &:before{ content:"\E171";} }
.halflings-floppy-disk{ &:before{ content:"\E172";} }
.halflings-floppy-saved{ &:before{ content:"\E173";} }
.halflings-floppy-remove{ &:before{ content:"\E174";} }
.halflings-floppy-save{ &:before{ content:"\E175";} }
.halflings-floppy-open{ &:before{ content:"\E176";} }
.halflings-credit-card{ &:before{ content:"\E177";} }
.halflings-transfer{ &:before{ content:"\E178";} }
.halflings-cutlery{ &:before{ content:"\E179";} }
.halflings-header{ &:before{ content:"\E180";} }
.halflings-compressed{ &:before{ content:"\E181";} }
.halflings-earphone{ &:before{ content:"\E182";} }
.halflings-phone-alt{ &:before{ content:"\E183";} }
.halflings-tower{ &:before{ content:"\E184";} }
.halflings-stats{ &:before{ content:"\E185";} }
.halflings-sd-video{ &:before{ content:"\E186";} }
.halflings-hd-video{ &:before{ content:"\E187";} }
.halflings-subtitles{ &:before{ content:"\E188";} }
.halflings-sound-stereo{ &:before{ content:"\E189";} }
.halflings-sound-dolby{ &:before{ content:"\E190";} }
.halflings-sound-5-1{ &:before{ content:"\E191";} }
.halflings-sound-6-1{ &:before{ content:"\E192";} }
.halflings-sound-7-1{ &:before{ content:"\E193";} }
.halflings-copyright-mark{ &:before{ content:"\E194";} }
.halflings-registration-mark{ &:before{ content:"\E195";} }
.halflings-cloud{ &:before{ content:"\E196";} }
.halflings-cloud-download{ &:before{ content:"\E197";} }
.halflings-cloud-upload{ &:before{ content:"\E198";} }
.halflings-tree-conifer{ &:before{ content:"\E199";} }
.halflings-tree-deciduous{ &:before{ content:"\E200";} }
.halflings-cd{ &:before{ content:"\E201";} }
.halflings-save-file{ &:before{ content:"\E202";} }
.halflings-open-file{ &:before{ content:"\E203";} }
.halflings-level-up{ &:before{ content:"\E204";} }
.halflings-copy{ &:before{ content:"\E205";} }
.halflings-paste{ &:before{ content:"\E206";} }
.halflings-door{ &:before{ content:"\E207";} }
.halflings-key{ &:before{ content:"\E208";} }
.halflings-alert{ &:before{ content:"\E209";} }
.halflings-equalizer{ &:before{ content:"\E210";} }
.halflings-king{ &:before{ content:"\E211";} }
.halflings-queen{ &:before{ content:"\E212";} }
.halflings-pawn{ &:before{ content:"\E213";} }
.halflings-bishop{ &:before{ content:"\E214";} }
.halflings-knight{ &:before{ content:"\E215";} }
.halflings-baby-formula{ &:before{ content:"\E216";} }
.halflings-tent{ &:before{ content:"\E217";} }
.halflings-blackboard{ &:before{ content:"\E218";} }
.halflings-bed{ &:before{ content:"\E219";} }
.halflings-apple{ &:before{ content:"\E220";} }
.halflings-erase{ &:before{ content:"\E221";} }
.halflings-hourglass{ &:before{ content:"\E222";} }
.halflings-lamp{ &:before{ content:"\E223";} }
.halflings-duplicate{ &:before{ content:"\E224";} }
.halflings-piggy-bank{ &:before{ content:"\E225";} }
.halflings-scissors{ &:before{ content:"\E226";} }
.halflings-bitcoin{ &:before{ content:"\E227";} }
.halflings-yen{ &:before{ content:"\E228";} }
.halflings-ruble{ &:before{ content:"\E229";} }
.halflings-scale{ &:before{ content:"\E230";} }
.halflings-ice-lolly{ &:before{ content:"\E231";} }
.halflings-ice-lolly-tasted{ &:before{ content:"\E232";} }
.halflings-education{ &:before{ content:"\E233";} }
.halflings-option-horizontal{ &:before{ content:"\E234";} }
.halflings-option-vertical{ &:before{ content:"\E235";} }
.halflings-menu-hamburger{ &:before{ content:"\E236";} }
.halflings-modal-window{ &:before{ content:"\E237";} }
.halflings-oil{ &:before{ content:"\E238";} }
.halflings-grain{ &:before{ content:"\E239";} }
.halflings-sunglasses{ &:before{ content:"\E240";} }
.halflings-text-size{ &:before{ content:"\E241";} }
.halflings-text-color{ &:before{ content:"\E242";} }
.halflings-text-background{ &:before{ content:"\E243";} }
.halflings-object-align-top{ &:before{ content:"\E244";} }
.halflings-object-align-bottom{ &:before{ content:"\E245";} }
.halflings-object-align-horizontal{ &:before{ content:"\E246";} }
.halflings-object-align-left{ &:before{ content:"\E247";} }
.halflings-object-align-vertical{ &:before{ content:"\E248";} }
.halflings-object-align-right{ &:before{ content:"\E249";} }
.halflings-triangle-right{ &:before{ content:"\E250";} }
.halflings-triangle-left{ &:before{ content:"\E251";} }
.halflings-triangle-bottom{ &:before{ content:"\E252";} }
.halflings-triangle-top{ &:before{ content:"\E253";} }
.halflings-terminal{ &:before{ content:"\E254";} }
.halflings-superscript{ &:before{ content:"\E255";} }
.halflings-subscript{ &:before{ content:"\E256";} }
.halflings-menu-left{ &:before{ content:"\E257";} }
.halflings-menu-right{ &:before{ content:"\E258";} }
.halflings-menu-down{ &:before{ content:"\E259";} }
.halflings-menu-up{ &:before{ content:"\E260";} }
.halflings-building{ &:before{ content:"\E261";} }
.halflings-tick{ &:before{ content:"\E262";} }
.halflings-star-half{ &:before{ content:"\E263";} }
.halflings-hash{ &:before{ content:"\E264";} }
.halflings-directions{ &:before{ content:"\E265";} }
.halflings-gas{ &:before{ content:"\E266";} }
.halflings-snowflake{ &:before{ content:"\E267";} }
.halflings-sunlight{ &:before{ content:"\E268";} }
.halflings-selectbox{ &:before{ content:"\E269";} }
.halflings-sortable{ &:before{ content:"\E270";} }
.halflings-note-empty{ &:before{ content:"\E271";} }
.halflings-note{ &:before{ content:"\E272";} }
.halflings-direction-right{ &:before{ content:"\E273";} }
.halflings-direction-left{ &:before{ content:"\E274";} }
.halflings-direction-down{ &:before{ content:"\E275";} }
.halflings-direction-up{ &:before{ content:"\E276";} }
.halflings-parking{ &:before{ content:"\E277";} }
.halflings-coffee-cup{ &:before{ content:"\E278";} }
.halflings-record-empty{ &:before{ content:"\E279";} }
.halflings-move-square{ &:before{ content:"\E280";} }
.halflings-bug{ &:before{ content:"\E281";} }
.halflings-display{ &:before{ content:"\E282";} }
.halflings-direction{ &:before{ content:"\E283";} }
.halflings-group{ &:before{ content:"\E284";} }
.halflings-reflect-y{ &:before{ content:"\E285";} }
.halflings-reflect-x{ &:before{ content:"\E286";} }
.halflings-battery-charging{ &:before{ content:"\E287";} }
.halflings-battery-full{ &:before{ content:"\E288";} }
.halflings-battery-75{ &:before{ content:"\E289";} }
.halflings-battery-50{ &:before{ content:"\E290";} }
.halflings-battery-25{ &:before{ content:"\E291";} }
.halflings-battery-10{ &:before{ content:"\E292";} }
.halflings-paired{ &:before{ content:"\E293";} }
.halflings-rotate-right{ &:before{ content:"\E294";} }
.halflings-rotate-left{ &:before{ content:"\E295";} }
.halflings-list-numbered{ &:before{ content:"\E296";} }
.halflings-paragraph{ &:before{ content:"\E297";} }
.halflings-list-plus{ &:before{ content:"\E298";} }
.halflings-synchronization{ &:before{ content:"\E299";} }
.halflings-cube-black{ &:before{ content:"\E300";} }
