
@toggleSwitchSize: 24px;
@toggleSwitchMargin: 3px;

.uf-toggle-switch-container {

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Redefine mixin (Bootstrap 3 reverses order of parameters)
  .size(@height, @width) {
    width: @width;
    height: @height;
  }
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  display: inline-block;
  .size((@toggleSwitchSize + (@toggleSwitchMargin * 2)), ((@toggleSwitchSize * 2) + (@toggleSwitchMargin * 2)));   // height, width

  background-color: darken(@grayLighter, 13.5%);
  .border-radius(6px);
  .transition(~"background-color 0.2s ease-in-out");

  cursor: pointer;

  input {
    display: none;
  }

  .uf-toggle-switch {
    .pos-rel(0, auto, auto, 0);
    .square(@toggleSwitchSize);

    margin: @toggleSwitchMargin;
    background-color: @white;
    .border-radius(4px);
    .transition(~"left 0.2s ease-in-out");
  }

  &.active {
    background-color: @green;

    .uf-toggle-switch {
      left: @toggleSwitchSize;
    }
  }
}

.uf-toggle-switch-readonly {
  opacity: 0.5;
  cursor: not-allowed;
}
