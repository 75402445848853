// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.layout-bs3 {
  .carousel {
    position: relative;
    margin-bottom: 20px;
    line-height: 1;

    .carousel-inner {
      position: relative;
      width: 100%;
      overflow: hidden;

      > .item {
        position: relative;
        display: none;
        .transition(0.6s ease-in-out left);

        > img,
        > a > img {
          display: block;
          line-height: 1;
        }
      }

      > .active,
      > .next,
      > .prev {
        display: block;
      }

      > .active {
        left: 0;

        &.left {
          left: -100%;
        }

        &.right {
          left: 100%;
        }
      }

      > .next,
      > .prev {
        position: absolute;
        top: 0;
        width: 100%;
      }

      > .next {
        left: 100%;

        &.left {
          left: 0;
        }
      }

      > .prev {
        left: -100%;

        &.right {
          left: 0;
        }
      }
    }
  }

  .carousel-control {
    .pos-abs(40%, auto, auto, 15px); // T, R, B, L
    .square(40px);

    margin-top: -20px;
    font-size: 60px;
    font-weight: 100;
    line-height: 30px;
    color: @gray-lighter;
    text-align: center;
    background: @gray-darker; // #222
    border: 3px solid @white;
    .border-radius(23px);

    opacity: 0.5;

    &.right {
      right: 15px;
      left: auto;
    }

    &:hover,
    &:focus {
      color: @white;
      text-decoration: none;
      opacity: 0.9;
      filter: alpha(opacity=90);
    }
  }

  .carousel-indicators {
    .pos-abs(15px, 15px); // T, R, B, L

    margin: 0;
    list-style: none;
    z-index: 5;

    li {
      display: block;
      float: left;
      .square(10px);

      margin-left: 5px;
      text-indent: -999px;
      background-color: darken(@gray-lighter, 13.5%); // #ccc
      background-color: rgba(255, 255, 255, 0.25);
      .border-radius(5px);
    }

    .active {
      background-color: @white;
    }
  }

  .carousel-caption {
    .pos-abs(auto, 0, 0, 0); // T, R, B, L

    padding: 15px;
    background: @gray-dark; // #333
    background: rgba(0, 0, 0, 0.75);

    h4,
    p {
      line-height: 20px;
      color: @white;
    }

    h4 {
      margin: 0 0 5px;
    }

    p {
      margin-bottom: 0;
    }
  }
}
