// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ***************************************************************************************

// Mixins

.nav-block-icon-width(@itemCount) {
  width: ((@navBlockIconSize * @itemCount) + (@navBlockIconMargin * (@itemCount - 1)));
}

// -------------------------
// For backwards compatibility;
//   Auto-prefixer during Build will handle the specific browser prefixes
// -------------------------

// Border Radius
.border-radius(@radius) {
  border-radius: @radius;
}

// Box Shadow
.box-shadow(@shadow) {
  box-shadow: @shadow;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
  background-clip: @clip;
}

// Background sizing
.background-size(@size) {
  background-size: @size;
}

// Elliding text (must be display block, inline-block, or table-cell)
.ellipsis-at(@max-width) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: @max-width;
}

// truncate utilities

.truncate-500 {
  .ellipsis-at(500px);
}

.truncate-350 {
  .ellipsis-at(350px);
}

.truncate-300 {
  .ellipsis-at(300px);
}

.truncate-250 {
  .ellipsis-at(250px);
}

.truncate-200 {
  .ellipsis-at(200px);
}

.truncate-150 {
  .ellipsis-at(150px);
}
