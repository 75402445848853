// ****************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../shared/_variables.less";
@import (reference) "../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "_variables.less"; // Modify this for custom colors, font-sizes, etc
@import (reference) "_mixins.less";

// ****************************************************************************

.layout-bs3 {
  #footer {
    padding: 15px;
    border-top: 1px solid @gray-lighter; // #eee
    line-height: 28px;
    color: lighten(@gray-light, 6.5%); // #888
    background: @whiteSmoke;
    clear: both;
    text-align: right;
  }
}

.layout-bs3-uberflip {
  #footer {
    color: #454545;
    background: #f9f9f9;
    border-color: @gray-lighter;
  }
}
