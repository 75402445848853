//
// Mixins
// --------------------------------------------------

.coords(@top: auto, @right: auto, @bottom: auto, @left: auto) {
  top: @top;
  left: @left;
  right: @right;
  bottom: @bottom;
}

.pos-rel(@top: auto, @right: auto, @bottom: auto, @left: auto) {
  position: relative;
  .coords(@top, @right, @bottom, @left);
}
.pos-abs(@top: auto, @right: auto, @bottom: auto, @left: auto) {
  position: absolute;
  .coords(@top, @right, @bottom, @left);
}
.pos-fix(@top: auto, @right: auto, @bottom: auto, @left: auto) {
  position: fixed;
  .coords(@top, @right, @bottom, @left);
}

.absolute-center {
  .pos-abs(0, 0, 0, 0);

  margin: auto;
}

.fixed-center {
  .pos-fix(0, 0, 0, 0);

  margin: auto;
}

.off-screen {
  .pos-abs(-1000px, auto, auto, -1000px);
}

.reset-box-sizing {
  &,
  & *,
  & *:before,
  & *:after,
  &:before,
  &:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

// Fix BS 2.3.2 errors with LessCompiler > 2.x

#grid {
  .core {
    .span(@gridColumns) {
      width: (@gridColumnWidth * @gridColumns) + (@gridGutterWidth * (@gridColumns - 1));
    }
  }
}

// Prepare Body Element for No-Scroll Behaviour
//  - Useful when you want to display an overlay at some point and stop the Body of the page from Scrolling,
//    while still allowing the Overlay to Scroll independently.
//  - This works for Desktop & Mobile Environments.
//  - Use the Companion Mixin below (".prepare-overlay") for the Overlay Element you plan to use.
//
.prepare-body-noscroll() {
  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    position: static;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  html.noscroll,
  body.noscroll {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: none;
  }

  body.noscroll {
    position: fixed;
  }

  // Fixes for Kindle Browser
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~

  html.silk,
  html.silk body {
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  html.silk body {
    position: static !important;
  }

  html.silk.noscroll,
  html.silk body.noscroll {
    overflow: visible !important;
  }

  html.silk body.noscroll {
    position: static !important;
  }

  html.silk .overlay-scroller {
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }
}

// Prepare Overlay Element for Positioning and Independent Scrolling
//  - Requires the Companion Mixin above (".prepare-body-noscroll") to first be applied.
//  - Overlay Element MUST have a "Scroller" DIV with Class: "overlay-scroller"
//
//  Usage:
//
//  index.html
//  ~~~~~~~~~~~
//    <div class="my-overlay">
//      .. Overlay Content ..
//      <div class="overlay-scroller">
//        .. Overlay Scrollable Content ..
//      </div>
//      .. Overlay Content ..
//    </div>
//
//  myfile.less
//  ~~~~~~~~~~~~
//    .prepare-body-noscroll();
//    body {
//        .my-overlay {
//            display: none;
//            .prepare-overlay({top: 10px; });                  // Overlay-Styles, Does NOT include Nested Code for Scroller
//            .prepare-overlay({top: 10px; }, { left: 50px; });    // Overlay-Styles, Scroller-Styles
//     }
// }
//
.prepare-overlay(@overlaystyle) {
  .pos-fix(0, 0, 0, 0);

  @overlaystyle();

  overflow: hidden;
  .backface-visibility(hidden);
}
.prepare-overlay(@overlaystyle, @scrollerstyle) {
  .prepare-overlay(@overlaystyle);

  .overlay-scroller {
    .pos-abs(0, 0, 0, 0);

    @scrollerstyle();

    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .backface-visibility(hidden);

    * {
      -webkit-transform: translateZ(0);
    }
  }
}
// Safari/Chrome - iOS9 Only:
//  fixes Bug #8284
@supports (overflow:-webkit-marquee) and (justify-content: inherit) {
  .overlay-scroller {
    -webkit-overflow-scrolling: auto !important;
  }
}

.triangle_top(@color, @width, @style: solid) {
  .square(0);

  border-color: transparent transparent @color transparent;
  border-style: @style;
  border-width: @width;
  -moz-transform: scale(0.9999);

  // IE Height Hackery
  font-size: 0;
  line-height: 0;

  // IE Transparent Borders Hackery
  _border-top-color: cyan;
  _border-right-color: cyan;
  _border-left-color: cyan;
  _filter: chroma(color=cyan);
}
.triangle_bottom(@color, @width, @style: solid) {
  .square(0);

  border-color: @color transparent transparent transparent;
  border-style: @style;
  border-width: @width;
  -moz-transform: scale(0.9999);

  // IE Height Hackery
  font-size: 0;
  line-height: 0;

  // IE Transparent Borders Hackery
  _border-left-color: cyan;
  _border-right-color: cyan;
  _border-bottom-color: cyan;
  _filter: chroma(color=cyan);
}
.triangle_left(@color, @width, @style: solid) {
  .square(0);

  border-color: transparent @color transparent transparent;
  border-style: @style;
  border-width: @width;
  -moz-transform: scale(0.9999);

  // IE Height Hackery
  font-size: 0;
  line-height: 0;

  // IE Transparent Borders Hackery
  _border-top-color: cyan;
  _border-left-color: cyan;
  _border-bottom-color: cyan;
  _filter: chroma(color=cyan);
}
.triangle_right(@color, @width, @style: solid) {
  .square(0);

  border-color: transparent transparent transparent @color;
  border-style: @style;
  border-width: @width;
  -moz-transform: scale(0.9999);

  // IE Height Hackery
  font-size: 0;
  line-height: 0;

  // IE Transparent Borders Hackery
  _border-top-color: cyan;
  _border-right-color: cyan;
  _border-bottom-color: cyan;
  _filter: chroma(color=cyan);
}

.perspective(@val) {
  -webkit-perspective: @val;
  -moz-perspective: @val;
  -o-perspective: @val;
  -ms-perspective: @val;
  perspective: @val;
}
.perspective-origin(@origin) {
  -webkit-perspective-origin: @origin;
  -moz-perspective-origin: @origin;
  -o-perspective-origin: @origin;
  -ms-perspective-origin: @origin;
  perspective-origin: @origin;
}
.animation(@val) {
  -webkit-animation: @val;
  -moz-animation: @val;
  -o-animation: @val;
  animation: @val;
}
.animation-name(@val) {
  -webkit-animation-name: @val;
  -moz-animation-name: @val;
  -ms-animation-name: @val;
  -o-animation-name: @val;
  animation-name: @val;
}
.animation-duration(@val) {
  -webkit-animation-duration: @val;
  -moz-animation-duration: @val;
  -ms-animation-duration: @val;
  -o-animation-duration: @val;
  animation-duration: @val;
}
.animation-iteration-count(@val) {
  -webkit-animation-iteration-count: @val;
  -moz-animation-iteration-count: @val;
  -ms-animation-iteration-count: @val;
  -o-animation-iteration-count: @val;
  animation-iteration-count: @val;
}
.animation-direction(@val) {
  -webkit-animation-direction: @val;
  -moz-animation-direction: @val;
  -ms-animation-direction: @val;
  -o-animation-direction: @val;
  animation-direction: @val;
}
.animation-delay(@val) {
  -webkit-animation-delay: @val;
  -moz-animation-delay: @val;
  -ms-animation-delay: @val;
  -o-animation-delay: @val;
  animation-delay: @val;
}
.transform(@val) {
  -webkit-transform: @val;
  -moz-transform: @val;
  -o-transform: @val;
  -ms-transform: @val;
  transform: @val;
}
.transform-style(@style) {
  -webkit-transform-style: @style;
  -moz-transform-style: @style;
  -o-transform-style: @style;
  -ms-transform-style: @style;
  transform-style: @style;
}
.transform-origin(@origin) {
  -webkit-transform-origin: @origin;
  -moz-transform-origin: @origin;
  -o-transform-origin: @origin;
  -ms-transform-origin: @origin;
  transform-origin: @origin;
}
.transform-duration(@duration) {
  -webkit-transform-duration: @duration;
  -moz-transform-duration: @duration;
  -o-transform-duration: @duration;
  -ms-transform-duration: @duration;
  transform-duration: @duration;
}
.transition-timing-function(@func) {
  -webkit-transition-timing-function: @func;
  -moz-transition-timing-function: @func;
  -o-transition-timing-function: @func;
  -ms-transition-timing-function: @func;
  transition-timing-function: @func;
}
.transition-transform(@val) {
  -webkit-transition: -webkit-transform @val;
  -moz-transition: -moz-transform @val;
  -o-transition: -o-transform @val;
  -ms-transition: -ms-transform @val;
  transition: transform @val;
}
.backface-visibility(@vis) {
  -webkit-backface-visibility: @vis;
  -moz-backface-visibility: @vis;
  -o-backface-visibility: @vis;
  -ms-backface-visibility: @vis;
  backface-visibility: @vis;
}

.hardware-accelerate {
  .transform(~"translate3d(0, 0, 0)");
  .backface-visibility(hidden);
  .perspective(1000);
}
.text-size-adjust(@val) {
  -webkit-text-size-adjust: @val;
  -moz-text-size-adjust: @val;
  -ms-text-size-adjust: @val;
  -o-text-size-adjust: @val;
  text-size-adjust: @val;
}
.filter-blur(@val) {
  filter: blur(@val);
  -webkit-filter: blur(@val);
  -moz-filter: blur(@val);
  -o-filter: blur(@val);
  -ms-filter: blur(@val);
  filter: progid:DXImageTransform.Microsoft.blur(pixelradius=3);
}
.filter(@val) {
  -webkit-filter: @val;
  -moz-filter: @val;
  -o-filter: @val;
  -ms-filter: @val;
  filter: @val;
}

.touch-action(@val) {
  -ms-touch-action: @val;
  touch-action: @val;
}
