// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Backend-Specific Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.layout-bs3 {

  // Menu Selector Control (for Permissions UI)

  .menu-selector {
    width: 100%;
    margin: 0 0 10px 0;

    div {
      clear: none !important;
    }

    .menu-item {
      position: relative;
      height: 1.5em;
      padding: 2px 0;

      .menu-item-expander {
        float: left;
        width: 20px;
        height: 1.5em;
        margin: 0 2px 0 0;
        cursor: default;
        background: transparent url("/img/menuexpand.gif") no-repeat 0 center;

        &.expanded {
          cursor: pointer;
          background: transparent url("/img/menuexpand.gif") no-repeat -40px center;
        }

        &.collapsed {
          cursor: pointer;
          background: transparent url("/img/menuexpand.gif") no-repeat -20px center;
        }

        span {
          display: none;
        }
      }

      .menu-item-selection {
        float: left;
        width: 20px;
        height: 1.5em;
        margin: 0 5px 0 0;
        cursor: pointer;
        background: transparent url("/img/menudepth.gif") no-repeat 0 center;

        &.partial {
          background: transparent url("/img/menudepth.gif") no-repeat -40px center;
        }

        &.full {
          background: transparent url("/img/menudepth.gif") no-repeat -20px center;
        }

        span {
          display: none;
        }
      }

      .menu-item-text {
        float: left;
        width: auto;
        height: 1.5em;
        cursor: default;

        span {
          height: 1.5em;
          padding-left: 0;
          line-height: 1.5em;
          vertical-align: middle;
          color: darken(@gray, 6.5%); // #444
        }
      }

      .menu-item-text-extended {
        width: auto;
        min-width: 100px;
        margin-left: 400px;
        overflow: hidden;
        height: 1.5em;
        cursor: default;

        span {
          height: 1.5em;
          max-width: 430px;
          display: inline-block;
          padding-left: 0;
          line-height: 1.5em;
          vertical-align: middle;
          color: darken(@gray, 6.5%); // #444
          .text-overflow();
        }
      }

      .disabled-item {
        opacity: 0.6;
        cursor: default;
      }

      .menu-item-input {
        position: absolute;
        top: 0;
        left: -9999px;
      }
    }

    .menu-item-contents {
      padding: 0px 0 0 25px;
    }

    // HACK: For adjusting the nested level left margin of additional text

    .menu-item-contents .menu-item-text-extended {
      margin-left: 375px;
    }

    .menu-item-contents .menu-item-contents .menu-item-text-extended {
      margin-left: 350px;
    }

    .menu-item-contents .menu-item-contents .menu-item-contents .menu-item-text-extended {
      margin-left: 325px;
    }

    .menu-item-contents .menu-item-contents .menu-item-contents .menu-item-contents .menu-item-text-extended {
      margin-left: 300px;
    }
  }
}
