.filter-tabs {
  text-align: center;

  > .nav.nav-tabs {
    margin-bottom: -1px;
  }

  > .nav.nav-pills {
    display: inline-block;
    margin-bottom: -6px;

    > li {
      margin-bottom: 0;

      > a {
        padding: 6px 22px 10px;
        margin: 0;
        color: darken(@grayLight, 6.5%);
        .border-radius(0);

        &:hover,
        &:focus {
          background: transparent;
          border-bottom: 5px solid @grayLighter;
        }
      }
    }

    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      color: @uberBlueLight;
      border-bottom: 5px solid @uberBlueLight;
      background: transparent;
    }
  }
}
